import { Component, Inject } from '@angular/core';
import { token } from './token';
import { SettingInputData } from '../node-setting.component';
import {
  AvailableFilter,
  ExtendedFilterCriterium,
} from 'advoprocess/lib/types/filter';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

/**
 * This component acts an input option for settings of type "filter".
 *
 * It allows the user to configure an array of filter criteriums according
 * to a set of available filters, dictated by the `possibleValues` field
 * of the logic setting. These filter criteriums can then be used to filter
 * database entries, events, etc.
 */
@Component({
  selector: 'app-filter-setting-input',
  template: `
    <div class="rich-label">{{ data.logicRef.name | translate }}</div>
    <app-search-breadcrumbs
      [filters]="filters"
      [availableFilters]="availableFilters"
      [initialJoinString]="'common.label.and'"
      (filtersChanged)="filtersChanged($event)"
    ></app-search-breadcrumbs>
  `,
  styles: [],
})
export class FilterSettingInputComponent {
  filters: ExtendedFilterCriterium[] = [];

  availableFilters: AvailableFilter[] = [];

  constructor(
    @Inject(token) public data: SettingInputData,
    private translator: TranslateService
  ) {
    this.availableFilters = this.translateFilterLabels(
      data.logicRef.possibleValues as AvailableFilter[]
    );
    if (_.isArray(this.data?.logicRef?.value?.value)) {
      this.filters = this.data.logicRef.value.value;
    }
  }

  private translateFilterLabels(v: AvailableFilter[]): AvailableFilter[] {
    v.forEach((el) => {
      el.label = _.isFunction(el.label)
        ? this.translator.instant(el.label({}))
        : this.translator.instant(el.label);
      if (el.children?.length) {
        this.translateFilterLabels(el.children);
      }
    });
    return v;
  }

  filtersChanged(event: ExtendedFilterCriterium[]) {
    this.data.logicRef.value.value = event;
  }
}
