import { animate, style, transition, trigger } from '@angular/animations';
import { Component, HostBinding, Input, TemplateRef } from '@angular/core';

export interface ContextMenuItem {
  name: string;
  icon?: string;
  hideIf?: (event: any) => boolean;
  action: (event: any) => void;
}

@Component({
  selector: 'app-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.scss'],
  animations: [
    trigger('animate', [
      transition(':enter', [
        style({ transform: 'scale(0)' }),
        animate('150ms ease-out', style({ transform: 'scale(1)' })),
      ]),
      transition(':leave', [
        style({ transform: 'scale(1)' }),
        animate('150ms ease-out', style({ transform: 'scale(0)' })),
      ]),
    ]),
  ],
})
export class ContextMenuComponent {
  @Input() contextMenuItems: ContextMenuItem[] = [];
  @Input() contextValue: any = undefined;

  @HostBinding('style.marginLeft')
  @Input()
  posX = '0px';

  @HostBinding('style.marginTop')
  @Input()
  posY = '0px';

  constructor() {}

  handle(item: ContextMenuItem) {
    item.action(this.contextValue);
  }

  @HostBinding('@animate') animate = 'true';
}
