import { Component, Inject, Optional, ViewChild } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { FormEntry } from 'advoprocess/lib/nodes/default-nodes/form.node';
import * as _ from 'lodash';
import { ProcessNode } from 'advoprocess';
import { FormEditorCoreComponent } from './form-editor-core.component';

export interface FormEditorData {
  questions: FormEntry[];
  onUpdateQuestions: (questions: FormEntry[]) => void;
  name: string;
  dataSource?: ProcessNode[];
}

@Component({
  selector: 'app-form-editor',
  templateUrl: './form-editor.component.html',
  styleUrls: ['./form-editor.component.scss'],
})
export class FormEditorComponent {
  @ViewChild('form') formRef: FormEditorCoreComponent;

  constructor(
    @Optional() public dialogRef: MatDialogRef<FormEditorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FormEditorData
  ) {}

  save() {
    this.data.onUpdateQuestions(
      this.formRef.layout.reduce((acc, row, rowIndex) => {
        row.forEach(
          (field, columnIndex) => (field.position = [rowIndex, columnIndex])
        );
        return [...acc, ...row];
      }, [])
    );
    this.dialogRef.close();
  }
}
