import { Component, Inject } from '@angular/core';
import { token } from './token';
import { SettingInputData } from '../node-setting.component';

@Component({
  selector: 'app-checkbox-input',
  template: `
    <mat-slide-toggle [formControl]="data.formControl">{{
      data.logicRef.name | translate
    }}</mat-slide-toggle>
  `,
  styles: [],
})
export class CheckboxInputComponent {
  constructor(@Inject(token) public data: SettingInputData) {}
}
