import { Injectable } from '@angular/core';
import { Notification } from 'src/api/model/notification';

// Use this service to store information that should
// be available in the application context for logged in users

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  notifications: Notification[] = [];

  constructor() {}
}
