import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  MatLegacyMenu,
  MatLegacyMenuTrigger as MatMenuTrigger,
} from '@angular/material/legacy-menu';
import { TranslateService } from '@ngx-translate/core';
import { MenuEntry } from 'advoprocess/lib/types/menu';
import _ from 'lodash';
import { Observable, isObservable } from 'rxjs';
import { debounceTime, finalize } from 'rxjs/operators';
import { filterTerm } from 'src/app/common/helpers';

@Component({
  selector: 'app-search-menu',
  templateUrl: './search-menu.component.html',
  styleUrls: ['./search-menu.component.scss'],
})
export class SearchMenuComponent implements OnInit, AfterViewInit {
  @Input() entries:
    | MenuEntry<any>[]
    | ((searchTerm: string) => Observable<MenuEntry<any>[]>);
  @Input() asSelectionDropdown: boolean = false;
  @Input() skipCloseOnSelect: boolean = false;
  @Input() hideSearchBar = false;

  @Output() entrySelected = new EventEmitter<MenuEntry<any>>();

  @ViewChild('trigger', { static: true }) menuTriggerContainer: ElementRef;
  @ViewChild('hiddenTrigger', { static: true }) hiddenTrigger: MatMenuTrigger;
  @ViewChild('searchInput') searchInput: ElementRef;

  searchControl = new FormControl<string | undefined>(undefined);

  activeEntries: MenuEntry<any>[] = [];

  loading = false;

  constructor(private translator: TranslateService) {}

  ngOnInit(): void {
    this.searchControl.valueChanges.subscribe(() => {
      this.updateList();
    });
  }

  ngAfterViewInit(): void {
    const triggerButton = this.menuTriggerContainer.nativeElement.querySelector(
      'button, .menu-trigger'
    );
    triggerButton?.addEventListener('click', this.onEvent.bind(this));
    triggerButton?.addEventListener('keyup', (event) => {
      if (event.key !== 'Enter') return;
      this.onEvent();
    });
    this.hiddenTrigger.menuOpened.subscribe(() => {
      console.log(this.asSelectionDropdown);
      if (!this.asSelectionDropdown) return;
      const triggerWidth = triggerButton.offsetWidth;
      // Use CDK overlay to get the menu panel element
      const searchMenu = document.querySelector(
        '.sf-search-menu'
      ) as HTMLElement;
      const overlayPane = searchMenu?.parentElement;

      if (overlayPane) {
        // Set the menu panel width to match the trigger element width
        overlayPane.style.width = `${triggerWidth}px`;
      }
    });
  }

  private onEvent() {
    this.hiddenTrigger.openMenu();
    this.searchControl.setValue('');
    this.updateList();
    this.searchInput?.nativeElement?.focus();
  }

  private wrapInGroups(data: MenuEntry<any>[]): MenuEntry<any>[] {
    if (!data.some((e) => !!e.group)) return data;
    return _.flatten(
      Object.entries(
        data.reduce((p, c) => {
          let group = c.group;
          if (!group) {
            c.group = { name: '' };
            group = c.group;
          }
          if (!p[group.name]) p[group.name] = [];
          p[group.name].push(c);
          return p;
        }, {})
      )
        .sort((a: [string, MenuEntry<any>], b: [string, MenuEntry<any>]) => {
          // Sort by priority
          const priorityA = a?.[1]?.[0]?.group?.priority ?? 0;
          const priorityB = b?.[1]?.[0]?.group?.priority ?? 0;

          if (priorityA === priorityB || a[0] === '' || b[0] === '') {
            // Sort by name
            return a[0] >= b[0] ? 1 : -1;
          } else {
            return priorityB - priorityA;
          }
        })
        .map((e) => {
          return [
            {
              isGroupHeader: true,
              name: e[0],
              icon: e[1]?.[0]?.group?.icon,
            },
            ...(e[1] as any[]),
          ];
        })
    );
  }

  private updateList() {
    if (_.isFunction(this.entries)) {
      const ret = this.entries(this.searchControl.value);
      if (isObservable(ret)) {
        this.loading = true;
        ret
          .pipe(
            finalize(() => {
              this.loading = false;
            })
          )
          .subscribe((entries) => {
            this.activeEntries = this.wrapInGroups(entries);
            window.dispatchEvent(new Event('resize'));
          });
      } else {
        this.activeEntries = this.wrapInGroups(ret);
        window.dispatchEvent(new Event('resize'));
      }
    } else {
      this.activeEntries = this.wrapInGroups(
        this.entries.filter((entry) => {
          return filterTerm(this.searchControl?.value, entry, this.translator);
        })
      );
      window.dispatchEvent(new Event('resize'));
    }
  }

  selectEntry(event: PointerEvent, entry: MenuEntry<any>) {
    if (this.skipCloseOnSelect) event.stopPropagation();
    this.entrySelected.emit(entry);
    if (this.skipCloseOnSelect) {
      this.updateList();
    }
  }

  enterValue(event) {
    if (['ArrowUp', 'ArrowDown', 'Enter'].includes(event.key)) return;
    this.searchInput.nativeElement.focus();
  }

  isLink(icon: string) {
    return (
      (icon.startsWith('/') &&
        (icon.endsWith('png') || icon.endsWith('jpg'))) ||
      icon.startsWith('http')
    );
  }
}
