import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { ChatMessage, ProcessParser } from 'advoprocess';
import { OptionsInputAppearance } from './inputs/options-input.component';

@Component({
  selector: 'app-question-answers',
  templateUrl: './question-answers.component.html',
  styleUrls: ['./question-answers.component.scss'],
})
export class QuestionAnswersComponent implements OnInit, OnChanges {
  @Input() message: ChatMessage;
  @Input() initialValue: any;
  @Input() inline?: boolean;
  @Input() parser: ProcessParser;

  @Output() hasError = new EventEmitter<boolean>();

  private value: any;

  @Output() answered = new EventEmitter<any>();
  @Output() changed = new EventEmitter<void>();

  formControl: UntypedFormControl = new UntypedFormControl('', [
    this.validatorFunction.bind(this),
  ]);

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.message || changes.initialValue) {
      this.ngOnInit();
    }
  }

  ngOnInit(): void {
    this.formControl.setValue(this.initialValue ?? null);
    if (!this.message.responseRequest?.params?.canBeSkipped) {
      this.formControl.addValidators([Validators.required]);
    }

    if (this.message.responseRequest?.type === 'number') {
      setTimeout(() => {
        this.formControl.setValue(this.initialValue);
      }, 0);
    }

    this.formControl.valueChanges.subscribe(() => {
      this.value = this.formControl.value;
    });

    if (this.message?.responseRequest?.type === 'file' && !this.value) {
      this.value = [];
    }
  }

  private validatorFunction(
    control: UntypedFormControl
  ): null | { message: string } {
    if (this.message?.responseRequest?.validator) {
      if (this.message.responseRequest?.params?.canBeSkipped) {
        if (control.value === '' || control.value === null) {
          this.value = null;
          return null;
        } else {
          return this.message.responseRequest.validator(control.value || '');
        }
      } else {
        return this.message.responseRequest.validator(control.value || '');
      }
    } else {
      return null;
    }
  }

  skipQuestion() {
    let defaultValue = '';
    this.answered.emit(defaultValue);
  }
}
