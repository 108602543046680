<!-- Browse nodes -->
<div class="form-container" *ngIf="!editNode">
  <ng-scrollbar track="all" cdkDropListGroup>
    <ng-container *ngFor="let row of layout; let i = index">
      <div class="row">
        <div class="row-content" cdkDropList (cdkDropListDropped)="drop($event)" [cdkDropListData]="row"
          cdkDropListOrientation="horizontal">
          <div class="lawyer-form-editor-cell" *ngFor="let cell of row; let j = index" cdkDrag
            [class.optional]="cell.question?.isOptional?.value">
            <div class="form-editor-cell-placeholder" *cdkDragPlaceholder></div>
            <span class="refId" (click)="changeRefId(cell)" cdkDragHandle>
              <ng-container *ngIf="cell.question?.questionType?.value === 'form_output'">
                <mat-icon>drag_indicator</mat-icon>
              </ng-container>
              <ng-container *ngIf="cell.question?.questionType?.value !== 'form_output'">
                #{{ cell.refId
                }}{{ cell.question?.isOptional?.value ? "" : " *" }}
              </ng-container>
            </span>
            <mat-icon class="prefixIcon" [matMenuTriggerFor]="modeSelectMenu">{{
              translateIcon(cell.question.questionType.value)
              }}</mat-icon>
            <mat-menu #modeSelectMenu="matMenu">
              <ng-container *ngFor="let mode of questionModes">
                <button mat-menu-item (click)="setMode(cell, mode)" translate>
                  <mat-icon>{{ mode.icon }}</mat-icon>
                  {{ mode.name }}
                </button>
              </ng-container>
            </mat-menu>
            <div class="field">
              <app-rich-text-editor [readonly]="false" [text]="cell.question.questionText.value"
                (textChange)="cell.question.questionText.value = $event" [dataSource]="dataSource || []" [buttons]="
                  'font,fontsize,brush,|,bold,italic,underline,strikethrough,|,ul,ol,|,undo,redo'
                " [inline]="true" [stringInput]="true" *ngIf="editCell === cell">
              </app-rich-text-editor>
              <div class="field-content" *ngIf="editCell !== cell"
                [innerHTML]="cell.question.questionText.value | sanitizeHtml" tabindex="0"
                (pointerdown)="onFieldFocus($event, cell)" (focus)="onFieldFocus($event, cell)" contenteditable></div>
            </div>
            <button mat-icon-button [matTooltip]="
                (cell.question?.isOptional?.value
                  ? 'common.label.isOptional'
                  : 'common.label.isNotOptional'
                ) | translate
              " (click)="
                cell.question.isOptional = {
                  value: !cell.question?.isOptional?.value
                }
              " *ngIf="cell.question?.questionType?.value !== 'form_output'">
              <mat-icon>{{
                !cell.question?.isOptional?.value ? "star_rate" : "star_border"
                }}</mat-icon>
            </button>
            <button mat-icon-button (click)="deleteField(i, j)">
              <mat-icon>delete</mat-icon>
            </button>
            <button mat-icon-button (click)="editField(cell)">
              <mat-icon>edit</mat-icon>
            </button>
          </div>
          <button class="delete-button" mat-button (click)="deleteRow(row)" *ngIf="!row.length">
            <mat-icon>delete</mat-icon>
            <span>{{ 'common.button.deleteRow' | translate }}</span>
          </button>
        </div>
        <button mat-icon-button class="add" (click)="addField(i)">
          <mat-icon>add</mat-icon>
        </button>
      </div>
      <button mat-icon-button class="add main" style="width: 100%" (click)="addField(i, 'after')"
        [class.last]="!layout?.length || i === layout.length - 1">
        <mat-icon>add</mat-icon>
      </button>
    </ng-container>
  </ng-scrollbar>
</div>

<!-- Edit nodes -->
<div class="edit-node" *ngIf="editNode">
  <ng-scrollbar #editorScrollbar>
    <div class="toollist">
      <button mat-button (click)="saveNode()">
        <mat-icon>arrow_back</mat-icon> {{ "common.button.back" | translate }}
      </button>
    </div>
    <app-node [logicRef]="editNode.node" [processNodeRef]="{ _id: 'formEditDialog', node: editNode?.node }"
      [attr.nodeType]="editNode.node.identifier" class="node" [outOfView]="false" [isInForm]="true"
      [attr.nodeId]="editNode.node._id" [context]="nodeContext" *ngIf="editNode.node"
      (sizeChange)="onNodeEditorSizeChange()">
    </app-node>
    <div class="display-conditions">
      <app-condition-edit *ngIf="dataSource && editNode.field?.condition?.length" [dataSource]="dataSource"
        [parent]="conditionOperations"
        [currentCondition]="{ elmnt: null, cond: editNode.field.condition }"></app-condition-edit>
    </div>
  </ng-scrollbar>
</div>
<div class="toolbox" cdkDropList [cdkDropListData]="questionModes" id="form-mode-toolbox" *ngIf="!hideToolbar">
  <div class="form-editor-toolbox-question" *ngFor="let mode of questionModes" cdkDrag>
    <div class="form-editor-cell-placeholder" *cdkDragPlaceholder></div>
    <mat-icon>{{ mode.icon }}</mat-icon>
    <span translate>{{ mode.name }}</span>
  </div>
</div>