<ng-container *ngIf="readonly; else editTemplate">
  <textarea #editorContainer id="main-doc-content"></textarea>
</ng-container>
<ng-template #editTemplate>
  <div class="condition-edit-popover" [class.open]="!!currentCondition"
    [class.offsetY]="conditionEditPopupPosition === 'above'" (pointerdown)="$event.stopPropagation()">
    <app-condition-edit *ngIf="currentCondition" [dataSource]="dataSource" [parent]="this"
      [currentCondition]="currentCondition"></app-condition-edit>
  </div>
  <textarea #editorContainer id="main-doc-content"></textarea>
  <div class="floating-help-orb" [style.left]="orbPos.x + 'px'" [style.top]="orbPos.y + 'px'"
    [matTooltip]="'document.conditional.addToParagraph' | translate" [class.open]="isOrbOpen"
    (click)="addConditionToSelection()">
    <mat-icon>account_tree</mat-icon>
  </div>
  <button mat-icon-button #cursorPosFollower class="cursorPosFollower"></button>
</ng-template>
<ng-container #tagInputContainer></ng-container>