<ng-container *ngIf="appearance === 'checkbox'">
  <div class="answer-title" *ngIf="inline && message?.content">
    <mat-icon>info</mat-icon>
    <p [innerHTML]="message.content | sanitizeHtml"></p>
  </div>
  <div class="checkbox-input">
    <mat-checkbox *ngFor="let option of (allOptions ?? [])" (change)="inline ? logInCheckboxes() : changed.emit()"
      [(ngModel)]="option.selected" [matTooltip]="option.text" 
    >
      {{ option.text }}
    </mat-checkbox>
    <button mat-raised-button color="primary" *ngIf="!inline" (click)="logInCheckboxes()"
      [disabled]="checkboxGoDisabled">
      {{ "common.button.continue" | translate }}
    </button>
  </div>
</ng-container>
<ng-container *ngIf="appearance === 'inline-checkbox'">
  <ng-container *ngTemplateOutlet="formFieldInput; context: {multiple: true}">
  </ng-container>
</ng-container>
<ng-container *ngIf="appearance === 'dropdown' || appearance === 'horizontal'">
  <ng-container *ngTemplateOutlet="formFieldInput; context: {multiple: false}">
  </ng-container>
</ng-container>
<ng-container *ngIf="appearance === 'buttons'">
  <div class="button-options">
    <button mat-button color="primary" *ngFor="
        let option of (allOptions ?? []);
        let i = index
      " (click)="buttonClick.emit(option)" [class.with-icon]="option?.icon" [class.selected]="value === option.text"
      [class.discarded]="value && value !== option.text" [style.animationDelay]="i / 3 + 's'" [matTooltip]="option.text">
      <mat-icon *ngIf="option?.icon">
        {{ option.icon }}
      </mat-icon>
      {{ option.text }}
    </button>
    <button mat-button color="primary" *ngIf="
        !allOptions ||
        allOptions?.length === 0
      " (click)="buttonClick.emit(value)">
      {{ "common.button.continue" | translate }}
    </button>
  </div>
</ng-container>
<ng-template #formFieldInput let-multiple="multiple">
  <div class="input-area">
    <app-search-menu [entries]="queryOptions.bind(this)" (entrySelected)="selectOption($event, multiple)"
      [asSelectionDropdown]="true" [skipCloseOnSelect]="multiple" [hideSearchBar]="allOptions.length <= 5">
      <button mat-button [class.with-selection]="multiple ? !!formControl?.value?.length : !!formControl?.value"
        type="button">
        <mat-label [matTooltip]="message.title | translate | stripHtml" [innerHtml]="message.title | translate" [class.required]="required"></mat-label>
        <span class="overflow-ellipsis" [matTooltip]="selectionLabel">{{selectionLabel}}</span>
        <mat-icon>arrow_drop_down</mat-icon>
      </button>
    </app-search-menu>
    <button mat-raised-button color="primary" *ngIf="!inline"
      (click)="appearance === 'inline-checkbox' ? logInCheckboxes() : buttonClick.emit(formControl.value)"
      [disabled]="error">
      {{ "common.button.continue" | translate }}
    </button>
  </div>
</ng-template>