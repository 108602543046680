/**
 * Silberfluss API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.10
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface FilterCriterium { 
    filterId?: string;
    label?: string;
    operand: string;
    operator: FilterCriterium.OperatorEnum;
    value?: any | null;
}
export namespace FilterCriterium {
    export type OperatorEnum = 'eq' | 'ne' | 'startsWith' | 'contains' | 'containsNot' | 'includedIn' | 'notIncludedIn' | 'greaterThan' | 'lessThan';
    export const OperatorEnum = {
        Eq: 'eq' as OperatorEnum,
        Ne: 'ne' as OperatorEnum,
        StartsWith: 'startsWith' as OperatorEnum,
        Contains: 'contains' as OperatorEnum,
        ContainsNot: 'containsNot' as OperatorEnum,
        IncludedIn: 'includedIn' as OperatorEnum,
        NotIncludedIn: 'notIncludedIn' as OperatorEnum,
        GreaterThan: 'greaterThan' as OperatorEnum,
        LessThan: 'lessThan' as OperatorEnum
    };
}


