<app-options-input *ngIf="
    !message.responseRequest?.type ||
    message.responseRequest?.type === 'buttons' ||
    message.responseRequest?.type === 'rate' ||
    message.responseRequest?.type === 'check'
  " [message]="message" (buttonClick)="answered.emit($event)" [value]="value"
  [required]="!message.responseRequest?.params?.canBeSkipped" (hasError)="hasError.emit($event)"
  (changed)="changed.emit()" [inline]="inline" [parser]="parser">
</app-options-input>
<app-multitext-input class="multitext-input-container" *ngIf="message.responseRequest.type === 'address'"
  [message]="message" [initialValue]="initialValue" [(value)]="value" [inline]="inline"
  [required]="!message.responseRequest?.params?.canBeSkipped" (answered)="answered.emit($event)"
  (valueChanged)="changed.emit()" (hasError)="hasError.emit($event)">
</app-multitext-input>
<app-calendar-input class="calendar-input" *ngIf="message.responseRequest.type === 'date'" [message]="message"
  [control]="formControl" (answered)="answered.emit($event)" [inline]="inline" [class.inline]="inline"
  (hasError)="hasError.emit($event)" [canBeEmpty]="message.responseRequest?.params?.canBeSkipped">
</app-calendar-input>
<app-file-upload-input class="file-input" *ngIf="message.responseRequest.type === 'file'" [value]="value"
  [required]="!message.responseRequest?.params?.canBeSkipped" [inline]="inline" [message]="message" [parser]="parser"
  (answered)="answered.emit($event)" (changed)="changed.emit()"
  (hasError)="hasError.emit($event)"></app-file-upload-input>
<app-signature-input *ngIf="message.responseRequest.type === 'signature'" (finished)="answered.emit($event)"
  [data]="value" [message]="message" [parser]="parser"></app-signature-input>
<app-text-input class="text-input" *ngIf="
    message.responseRequest?.type &&
    message.responseRequest.type !== 'address' &&
    message.responseRequest.type !== 'date' &&
    message.responseRequest.type !== 'file' &&
    message.responseRequest.type !== 'signature' &&
    message.responseRequest?.type !== 'buttons' &&
    message.responseRequest?.type !== 'rate' &&
    message.responseRequest?.type !== 'check'
  " [inline]="inline" [message]="message" [control]="formControl" [(value)]="value" (answered)="answered.emit($event)"
  (changed)="changed.emit()" (hasError)="hasError.emit($event)"
  [canBeEmpty]="message.responseRequest?.params?.canBeSkipped">
</app-text-input>

<button mat-button class="skip-button" color="primary" *ngIf="message.responseRequest?.params?.canBeSkipped && !inline"
  (click)="skipQuestion()">
  {{ "common.button.skipQuestion" | translate }}
</button>