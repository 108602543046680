import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MenuEntry } from 'advoprocess/lib/types/menu';
import { Observable, Subject, combineLatest, of, timer } from 'rxjs';
import { map, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import {
  ClientsService,
  ExecutionService,
  ExecutionStateParticipant,
  Lawyer,
  LawyerService,
  Role,
  RolesService,
  User,
} from 'src/api';
import { HardCodedUser, ParsedAssignedUser } from 'advoprocess';
import { filterTerm, isParseAssignedUser } from 'src/app/common/helpers';
import { AuthService } from 'src/app/auth/auth.service';
import { addClient } from 'src/app/views/lawyer/dashboard/pages/clients-overview/clients-overview.component';
import { DialogService } from '../dialog/dialog.service';
import { PermissionsService } from 'src/app/auth/permissions.service';
import { SettingsService } from 'src/app/common/settings.service';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';

const QUERY_PAGINATION = {
  page: 1,
  rows_per_page: 5,
};

type AnyParticipant =
  | Role
  | ExecutionStateParticipant
  | User
  | Lawyer
  | { hardCoded: boolean; operation: string }
  | 'CLIENT'
  | 'CLERK'
  | 'OPPONENT'
  | 'MISC'
  | 'ASSISTANT';

@Component({
  selector: 'app-participant-select-menu',
  templateUrl: './participant-select-menu.component.html',
  styleUrls: ['./participant-select-menu.component.scss'],
})
export class ParticipantSelectMenuComponent {
  @Output() selected = new EventEmitter<string>();

  @Input() defaultPermission: 'read' | 'write' | 'answer' = 'answer';
  @Input() alreadyAssigned: (ParsedAssignedUser | HardCodedUser)[] = [];

  @Input() stateId?: string;
  @Input() restrictToState: boolean = false;

  @Input() types: ('lawyers' | 'clients' | 'roles' | 'magic')[] = [
    'lawyers',
    'clients',
    'roles',
    'magic',
  ];

  @Input() allowCreateNew = true;

  constructor(
    private translator: TranslateService,
    private rolesAPI: RolesService,
    private clientsAPI: ClientsService,
    private lawyersAPI: LawyerService,
    private auth: AuthService,
    private stateApi: ExecutionService,
    private dialog: DialogService,
    private permissions: PermissionsService,
    private settings: SettingsService,
    private route: ActivatedRoute
  ) {}

  abortQuery$ = new Subject<void>();
  lastResults: [
    MenuEntry<ExecutionStateParticipant>[],
    MenuEntry<Role>[],
    MenuEntry<User>[],
    MenuEntry<Lawyer>[],
    MenuEntry<unknown>[],
    MenuEntry<'CLIENT' | 'CLERK' | 'OPPONENT' | 'MISC' | 'ASSISTANT'>[],
    MenuEntry<{ hardCoded: boolean; operation: string }>[]
  ] = [[], [], [], [], [], [], []];

  queryAllParticipants(
    searchTerm: string
  ): Observable<MenuEntry<AnyParticipant>[]> {
    this.abortQuery$.next();
    let allSources: Array<
      Observable<Array<MenuEntry<AnyParticipant | unknown>>>
    >;
    if (this.restrictToState) {
      allSources = [this.getStateParticipants(searchTerm)];
    } else {
      allSources = [
        this.getStateParticipants(searchTerm),
        this.getRoles(searchTerm),
        this.getClients(searchTerm),
        this.getLawyers(searchTerm),
        this.getReferences(searchTerm),
        this.getMagicConstants(searchTerm),
        this.getHardCodedOptions(searchTerm),
      ];
    }
    allSources = allSources.map((obs, i) =>
      timer(1000).pipe(
        switchMap(() => obs),
        tap((x) => (this.lastResults[i] = x)),
        startWith(
          this.lastResults[i].filter((e) =>
            filterTerm(searchTerm, e, this.translator)
          )
        ),
        takeUntil(this.abortQuery$)
      )
    );
    return combineLatest(allSources).pipe(map((results) => _.flatten(results)));
  }

  private isEntryAlreadyAssigned(
    qualifier: any,
    type: 'role' | 'user' | 'MAGIC',
    source: string
  ) {
    return this.alreadyAssigned.some((entry) => {
      if (isParseAssignedUser(entry)) {
        return (
          entry.type == type &&
          (type == 'role' ? true : entry.source === source) &&
          entry.uuid == qualifier
        );
      } else {
        return entry.target == qualifier;
      }
    });
  }

  private getRoles(searchTerm: string): Observable<MenuEntry<Role>[]> {
    if (!this.types.includes('roles')) return of([]);
    const filter = [];
    if (searchTerm?.length) {
      filter.push({
        operand: 'name',
        operator: 'contains',
        value: searchTerm,
      });
    }
    // ToDo: Fix that this is called too often
    return this.rolesAPI
      .listRoles({
        filterViewPagination: {
          filter,
          pagination: QUERY_PAGINATION,
          view: {
            displayed_columns: [
              {
                display_name: 'name',
                internal_name: 'name',
              },
              {
                display_name: 'icon',
                internal_name: 'icon',
              },
            ],
            hidden_columns: [
              {
                display_name: 'id',
                internal_name: 'id',
              },
            ],
            sort_by: {
              by: 'name',
              direction: 'asc',
            },
          },
        },
      })
      .pipe(
        map((results) => {
          return results.roles
            .filter(
              (role) => !this.isEntryAlreadyAssigned(role.id, 'role', 'group')
            )
            .map((role) => ({
              name: role.name,
              icon: role.icon,
              details: role.description,
              value: role,
              group: {
                name: this.translator.instant(
                  'lawyer.dashboard.clients.groups'
                ),
                icon: 'group',
              },
            }));
        })
      );
  }

  private getHardCodedOptions(
    searchTerm: string
  ): Observable<MenuEntry<{ hardCoded: boolean; operation: string }>[]> {
    if (!this.allowCreateNew) return of([]);
    return this.auth.isClient
      ? of([])
      : of(
          [
            {
              name: this.translator.instant('lawyer.dashboard.clients.create'),
              icon: 'person_add',
              value: {
                hardCoded: true,
                operation: 'add',
              },
            },
          ].filter((n) => filterTerm(searchTerm, n, this.translator))
        );
  }

  private getStateParticipants(
    searchTerm: string
  ): Observable<MenuEntry<ExecutionStateParticipant>[]> {
    if (!this.stateId) return of([]);
    return this.stateApi
      .getStateParticipants({
        stateid: this.stateId,
      })
      .pipe(
        map((result) => {
          return result
            .map((part) => ({
              name:
                part.first_name || part.last_name
                  ? `${[part.first_name, part.last_name]
                      .filter((n) => n?.length)
                      .join(' ')}`
                  : part.mail ?? 'Gast',
              icon:
                part.source === 'client'
                  ? 'person'
                  : !!part.profile_picture
                  ? this.getProfilePicUrl(part.profile_picture)
                  : 'person',
              value: part,
              details:
                this.translator.instant(`common.assigned.${part.role}`) +
                ((part.first_name || part.last_name) && part.mail
                  ? ` | ${part.mail}`
                  : ''),
              group: {
                name: this.translator.instant('process.label.alreadyAssigned'),
                icon: 'diversity_3',
                priority: 10,
              },
            }))
            .filter(
              (part) =>
                filterTerm(searchTerm, part, this.translator) &&
                !this.isEntryAlreadyAssigned(
                  part.value.id,
                  'user',
                  part.value.source
                )
            );
        })
      );
  }

  private getClients(
    searchTerm: string,
    stateId?: string
  ): Observable<MenuEntry<User>[]> {
    if (!this.types.includes('clients')) return of([]);
    const filter = [];
    if (searchTerm?.length) {
      filter.push({
        operator: 'or',
        filters: [
          {
            operand: 'full_name',
            operator: 'contains',
            value: searchTerm,
          },
          {
            operand: 'mail',
            operator: 'contains',
            value: searchTerm,
          },
        ],
      });
    }
    if (stateId) {
      filter.push({
        operand: 'execution_states.id',
        operator: 'eq',
        value: stateId,
      });
    }
    return this.clientsAPI
      .listUsers({
        filterViewPagination: {
          filter,
          pagination: QUERY_PAGINATION,
          view: {
            displayed_columns: [
              {
                display_name: 'name',
                internal_name: 'full_name',
              },
              {
                display_name: 'mail',
                internal_name: 'mail',
              },
            ],
            hidden_columns: [
              {
                display_name: 'id',
                internal_name: 'id',
              },
            ],
            sort_by: {
              by: 'full_name',
              direction: 'asc',
            },
          },
        },
        includeGuests: true,
      })
      .pipe(
        map((results) => {
          return (results.users ?? [])
            .filter(
              (user) => !this.isEntryAlreadyAssigned(user.id, 'user', 'client')
            )
            .map((user: any) => ({
              name: user.name ?? user.mail,
              icon: 'person',
              value: user,
              details: _.isNil(user.name) ? undefined : user.mail,
              group: {
                name: this.translator.instant(
                  'lawyer.dashboard.clients.externalContacts'
                ),
                icon: 'contacts',
              },
            }))
            .filter((u: any) => u.name?.length);
        })
      );
  }

  private getLawyers(
    searchTerm: string,
    stateId?: string
  ): Observable<MenuEntry<Lawyer>[]> {
    if (!this.types.includes('lawyers')) return of([]);
    const filter = [];
    if (searchTerm?.length) {
      filter.push({
        operator: 'or',
        filters: [
          {
            operand: 'full_name',
            operator: 'contains',
            value: searchTerm,
          },
          {
            operand: 'mail',
            operator: 'contains',
            value: searchTerm,
          },
        ],
      });
    }
    if (stateId) {
      filter.push({
        operand: 'execution_states.id',
        operator: 'eq',
        value: stateId,
      });
    }
    return this.lawyersAPI
      .listLawyers({
        filterViewPagination: {
          filter,
          pagination: QUERY_PAGINATION,
          view: {
            displayed_columns: [
              {
                display_name: 'name',
                internal_name: 'full_name',
              },
              {
                display_name: 'mail',
                internal_name: 'mail',
              },
            ],
            hidden_columns: [
              {
                display_name: 'id',
                internal_name: 'id',
              },
              {
                display_name: 'profile_picture',
                internal_name: 'corporate_identity_.lawyer_headshot',
              },
            ],
            sort_by: {
              by: 'full_name',
              direction: 'asc',
            },
          },
        },
      })
      .pipe(
        map((results) => {
          return (results.users ?? [])
            .filter(
              (user) => !this.isEntryAlreadyAssigned(user.id, 'user', 'lawyer')
            )
            .map((user: any) => ({
              name: user.name ?? user.uname,
              value: user,
              icon: user.profile_picture
                ? this.getProfilePicUrl(user.profile_picture)
                : 'manage_accounts',
              details: user.mail ?? user.uname,
              group: {
                name: this.translator.instant(
                  'lawyer.dashboard.clients.internalContacts'
                ),
                icon: 'groups',
              },
            }))
            .filter((u: any) => u.name?.length);
        })
      );
  }

  private getReferences(searchTerm: string): Observable<MenuEntry<unknown>[]> {
    if (!this.types.includes('magic')) return of([]);
    // ToDo: Fix
    return of([]);
  }

  private getMagicConstants(
    searchTerm: string
  ): Observable<
    MenuEntry<'CLIENT' | 'CLERK' | 'OPPONENT' | 'MISC' | 'ASSISTANT'>[]
  > {
    if (!this.types.includes('magic')) return of([]);
    return of(
      (
        ['CLIENT', 'OPPONENT', 'MISC', 'CLERK', 'ASSISTANT'] as ReadonlyArray<
          'CLIENT' | 'CLERK' | 'OPPONENT' | 'MISC' | 'ASSISTANT'
        >
      )
        .map((name) => ({
          name: this.translator.instant(`common.assigned.${name}`),
          icon: 'auto_awesome',
          value: name,
          group: {
            name: this.translator.instant('common.label.assigned'),
            icon: 'auto_awesome',
          },
        }))
        .filter(
          (entry) =>
            filterTerm(searchTerm, entry, this.translator) &&
            !this.isEntryAlreadyAssigned(entry.value, 'MAGIC', 'any')
        )
    );
  }

  selectParticipant(event: MenuEntry<AnyParticipant>) {
    if ((event.value as any)?.hardCoded) {
      return this.handleHardCoded(
        event.value as { hardCoded: boolean; operation: string }
      );
    }
    let type = '';
    let source = '';
    let infoJson = {};
    switch (event.group.icon) {
      case 'group': // Role
        type = 'ROLE';
        source = 'group';
        infoJson = {
          uuid: (event.value as Role).id,
          name: event.name,
          permission: this.defaultPermission,
        };
        break;
      case 'diversity_3':
        type = 'USER';
        source = (event.value as ExecutionStateParticipant).source;
        infoJson = {
          uuid: (event.value as ExecutionStateParticipant).id,
          name: event.name,
          permission: this.defaultPermission,
          mail: (event.value as ExecutionStateParticipant).mail,
          profile_picture: (event.value as ExecutionStateParticipant)
            .profile_picture,
        };
        break;
      case 'contacts': // External contacts
        type = 'USER';
        source = 'client';
        infoJson = {
          uuid: (event.value as User).id,
          name: event.name,
          permission: this.defaultPermission,
          mail: (event.value as User).mail,
        };
        break;
      case 'groups': // Lawyers
        type = 'USER';
        source = 'lawyer';
        infoJson = {
          uuid: (event.value as Lawyer).id,
          name: event.name,
          permission: this.defaultPermission,
          mail: (event.value as Lawyer).mail,
          profile_picture: (event.value as any).profile_picture,
        };
        break;
      case 'auto_awesome': // Magic constants
        type = 'MAGIC';
        source = event.value as
          | 'CLIENT'
          | 'CLERK'
          | 'OPPONENT'
          | 'MISC'
          | 'ASSISTANT';
        infoJson = {
          permission: this.defaultPermission,
        };
        break;
      case 'tag': // RefID
        type = 'MAGIC';
        type = event.value as string;
        source = event.value as string;
        infoJson = {
          permission: this.defaultPermission,
        };
        break;
      default:
        return;
    }
    const newRef = `<<[${type}:${source}]::${JSON.stringify(infoJson)}>>`;
    this.selected.emit(newRef);
  }

  handleHardCoded(event: { hardCoded: boolean; operation: string }) {
    if (event.operation === 'add') {
      addClient(
        this.dialog,
        this.translator,
        this.clientsAPI,
        this.permissions,
        this.auth,
        this.rolesAPI,
        this.settings
      ).subscribe({
        next: (user) => {
          if (!user) {
            return;
          }
          const infoJson = {
            uuid: user.id,
            name: [user.first_name, user.last_name]
              .filter((n) => !!n)
              .join(' '),
            permission: this.defaultPermission,
            mail: user.mail,
          };
          const newRef = `<<[USER:client]::${JSON.stringify(infoJson)}>>`;
          this.selected.emit(newRef);
        },
        error: () => {},
      });
    }
  }

  getProfilePicUrl(profile_picture: string) {
    const realm =
      this.route.snapshot.paramMap.get('realm') ??
      this.route.firstChild?.snapshot.paramMap.get('realm');
    return environment.API_URL + '/file/' + profile_picture + '?realm=' + realm;
  }
}
