<div class="list-container">
  <div class="list-header">
    <span>{{ 'common.label.options' | translate }}</span>
    <div class="input-switch">
      <button [matTooltip]="'common.label.setOptions' | translate" (click)="setUseFetch(false)"
        [class.selected]="!useFetch">
        <mat-icon>list</mat-icon>
      </button>
      <button [matTooltip]="'common.label.fetchOptions' | translate" (click)="setUseFetch(true)"
        [class.selected]="useFetch">
        <mat-icon>tag</mat-icon>
      </button>
    </div>
  </div>
  <div class="data-source-input" *ngIf="useFetch">
    <mat-form-field appearance="outline">
      <mat-label>{{ 'lawyer.editor.variableSource' | translate }}</mat-label>
      <mat-select [formControl]="fetchForm.get('source')">
        <mat-option *ngFor="let option of validVarSources" [value]="option">
          {{ ('lawyer.editor.varSources.' + option) | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{ 'lawyer.editor.variableName' | translate }}</mat-label>
      <input matInput type="text" [formControl]="fetchForm.get('name')" />
    </mat-form-field>
  </div>
  <div cdkDropList class="list" (cdkDropListDropped)="drop($event)" *ngIf="!useFetch">
    <div class="list-box" *ngFor="let element of listElements; let i = index" cdkDrag
      (pointerdown)="$event.stopPropagation()">
      <span (click)="editListItem(i)">{{ element }}</span>
      <span class="options">
        <button mat-icon-button (click)="deleteListItem(i)" *ngIf="listElements.length > 1">
          <mat-icon>delete</mat-icon>
        </button>
        <button mat-icon-button (click)="editListItem(i)">
          <mat-icon>edit</mat-icon>
        </button>
      </span>
    </div>
  </div>
  <button mat-button [style.backgroundColor]="data.color ? data.color : null" (click)="addOption()" *ngIf="!useFetch">
    <mat-icon>add</mat-icon>
    <span>{{ 'common.button.addOption' | translate }}</span>
  </button>
</div>