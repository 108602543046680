import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-profile-chip',
  templateUrl: './profile-chip.component.html',
  styleUrls: ['./profile-chip.component.scss'],
})
export class ProfileChipComponent {
  showLogin = false;

  constructor(
    public auth: AuthService,
    private router: Router,
    public route: ActivatedRoute
  ) {}

  goToProfile() {
    if (!this.auth.loggedIn || this.auth.isGuest) return;
    console.log(this.route.snapshot);
    if (this.auth.isClient) {
      this.router.navigate([this.realm, 'profile']);
    } else {
      this.router.navigate([
        this.realm,
        'intern',
        'clients',
        'lawyer',
        this.auth.userId,
      ]);
    }
  }

  get realm(): string | undefined {
    return (
      this.route.snapshot?.paramMap?.get('realm') ??
      this.route.snapshot?.firstChild?.paramMap?.get('realm')
    );
  }
}
