import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { parseUserReference } from 'advoprocess';
import { ExecutionStateParticipant } from 'src/api';
import { AuthService } from 'src/app/auth/auth.service';
import { isParseAssignedUser } from 'src/app/common/helpers';
import { environment } from 'src/environments/environment';

export interface ParticipantChangeEvent {
  operation: 'add' | 'remove' | 'change';
  participant: HierarchicalParticipant;
}

export type HierarchicalParticipant = ExecutionStateParticipant & {
  children?: HierarchicalParticipant[];
};

@Component({
  selector: 'app-dossier-assigned-input',
  templateUrl: './dossier-assigned-input.component.html',
  styleUrls: ['./dossier-assigned-input.component.scss'],
})
export class DossierAssignedInputComponent {
  @Input() participants: HierarchicalParticipant[] = [];

  @Output() change = new EventEmitter<ParticipantChangeEvent>();

  availableLawyerRoles = ['CLERK', 'ASSISTANT'];
  availableClientRoles = ['CLIENT', 'OPPONENT', 'MISC'];

  API_URL = environment.API_URL;

  constructor(
    private auth: AuthService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {}

  get isLawyer(): boolean {
    return this.auth.loggedIn && !this.auth.isClient;
  }

  addParticipant(event: string, parent: HierarchicalParticipant | null = null) {
    const parsed = parseUserReference(event);
    if (!isParseAssignedUser(parsed)) return;
    this.change.emit({
      operation: 'add',
      participant: {
        id: parsed.uuid,
        role: parsed.source === 'lawyer' ? 'CLERK' : 'CLIENT',
        source: parsed.source as 'client' | 'lawyer',
        last_name: parsed.name,
        mail: parsed.mail,
        profile_picture: (parsed as any).profile_picture ?? undefined,
        parent: parent?.id ?? null,
      },
    });
  }

  changeParticipantRole(participant: HierarchicalParticipant, newRole: string) {
    this.change.emit({
      operation: 'change',
      participant: {
        ...participant,
        role: newRole,
      },
    });
  }

  removeParticipant(userid: string, source: string) {
    this.change.emit({
      operation: 'remove',
      participant: {
        id: userid,
        source: source as 'client' | 'lawyer',
      },
    });
  }

  get realm(): string {
    return (
      this.activatedRoute.snapshot.paramMap.get('realm') ??
      this.activatedRoute.firstChild.snapshot.paramMap.get('realm')
    );
  }

  viewParticipant(participant: HierarchicalParticipant) {
    if (this.auth.isClient) return;
    this.router.navigate([
      this.realm,
      'intern',
      'clients',
      participant.source,
      participant.id,
    ]);
  }
}
