import { Component, Inject } from '@angular/core';
import { token } from './token';
import { SettingInputData } from '../node-setting.component';

@Component({
  selector: 'app-text-input',
  template: `
    <mat-form-field appearance="outline" style="width: 100%">
      <mat-label>{{ data.logicRef.name | translate }}</mat-label>
      <ng-container *ngIf="data.logicRef.type !== 'dropdown'">
        <input
          matInput
          type="text"
          [formControl]="data.formControl"
          (keyup)="changeInput()"
          (pointerdown)="$event.stopPropagation()"
        />
        <span
          class="error"
          *ngIf="data.formControl?.errors?.message"
          translate
          >{{ data.formControl.errors.message }}</span
        >
      </ng-container>
      <ng-container
        *ngIf="data.logicRef.type === 'dropdown'"
        (selectionChange)="changeInput()"
      >
        <ng-container *ngIf="data.logicRef.multiselect; else singleSelect">
          <mat-select [formControl]="data.formControl" multiple>
            <mat-option
              *ngFor="let option of data.logicRef.possibleValues"
              [value]="option.value"
            >
              {{ option.title | translate }}
            </mat-option>
          </mat-select>
        </ng-container>
        <ng-template #singleSelect>
          <mat-select
            [formControl]="data.formControl"
            (selectionChange)="changeInput()"
          >
            <mat-option
              *ngFor="let option of data.logicRef.possibleValues"
              [value]="option.value"
            >
              {{ option.title | translate }}
            </mat-option>
          </mat-select>
        </ng-template>
      </ng-container>
    </mat-form-field>
  `,
  styles: [],
})
export class TextInputComponent {
  constructor(@Inject(token) public data: SettingInputData) {}

  changeInput(): void {
    if (this.data.logicRef.callback) {
      this.data.logicRef.callback();
    }
  }
}
