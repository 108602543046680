import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Inject, OnInit } from '@angular/core';
import { token } from './token';
import { SettingInputData } from '../node-setting.component';
import { DialogService } from 'src/app/widgets/dialog/dialog.service';
import { TranslateService } from '@ngx-translate/core';
import { FileAttachment } from 'advoprocess';

@Component({
  selector: 'app-file-list-input',
  template: `
    <div class="list-container">
      <span>{{ 'common.label.attachments' | translate }}</span>
      <app-attachment-list
        [config]="data?.logicRef?.value"
      ></app-attachment-list>
      <app-attachment-select-menu
        (selected)="addFileItem($event)"
        [nodeList]="data?.allNodes ?? []"
      >
        <button
          mat-icon-button
          [matTooltip]="'common.button.addAttachment' | translate"
        >
          <mat-icon>attach_file</mat-icon>
        </button>
      </app-attachment-select-menu>
    </div>
  `,
  styles: [],
})
export class FileListInputComponent implements OnInit {
  listElements = [];
  allDocuments: { id: string; name: string; skip: boolean }[];

  constructor(
    @Inject(token) public data: SettingInputData,
    private dialog: DialogService,
    private translator: TranslateService
  ) {}

  ngOnInit(): void {
    this.listElements = this.data.logicRef.value.value;
  }

  getAllDocuments(): void {
    const nodes =
      this.data.allNodes
        ?.filter((n) => n.node.identifier === 'DocumentNode')
        ?.map((d) => d.node) ?? [];
    this.allDocuments = nodes.map((doc) => {
      const skip =
        this.data.processNode.node?.config?.attachments?.value?.find(
          (d) => d.id === doc.nodeId
        ) ?? false;
      return {
        id: doc.nodeId ?? '',
        name: doc.config.title.value + '.pdf',
        skip: skip,
      };
    });
  }

  addFileItem(event: FileAttachment): void {
    this.listElements.push(event);
  }

  async deleteFileItem(index): Promise<void> {
    if (this.listElements.length <= 0 || index > this.listElements.length - 1) {
      return;
    }
    if (
      !(await this.dialog.confirm({
        text: `Datei "${this.listElements[index].name}" wirklich löschen?`,
      }))
    ) {
      return;
    }
    this.listElements.splice(index, 1);
    this.updateListSource();
  }

  addGeneratedDocumentFile(id: string, name: string): void {
    this.listElements.push({
      id,
      type: 'generated-document',
      name,
      format: 'default',
    });
    this.updateListSource();
  }

  anyFileAttachmentId(id: string) {
    return this.listElements.some((file) => file.id === id);
  }

  pdfMode(index, mode): void {
    this.listElements[index].format = mode;
    this.updateListSource();
  }

  drop(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.listElements, event.previousIndex, event.currentIndex);
    this.updateListSource();
  }

  private updateListSource(): void {
    if (this.data.logicRef.type !== 'attachments') {
      this.data.logicRef.value.value = this.listElements
        .map((el) => `"${el.replace(/"/gm, '\\"')}"`)
        .join(', ');
    } else {
      this.data.logicRef.value.value = this.listElements;
    }
    if (this.data.logicRef.callback) {
      this.data.logicRef.callback();
    }
  }
}
