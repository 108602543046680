import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { DestroyNotifier } from '../../views/process/process-view/destroy-notifier';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'app-timepicker',
  templateUrl: './timepicker.component.html',
  styleUrls: ['./timepicker.component.scss'],
})
export class TimepickerComponent extends DestroyNotifier implements OnChanges {
  @Input() label: string = undefined;

  @Input() hour: number = undefined;
  @Input() minute: number = undefined;

  @Output() hourChange = new EventEmitter<number>(undefined);
  @Output() minuteChange = new EventEmitter<number>(undefined);

  @Input() required: boolean = true;

  @Input() active: boolean = true;
  @Output() activeChange = new EventEmitter<boolean>();

  /**
   * The minimum value in minutes since midnight, e.g.
   * 15:30 => 15 * 60 + 30 = 900 + 30 = 930
   */
  @Input() min: number = undefined;

  _hour: FormControl<number> = new FormControl<number>(0);
  _minute: FormControl<number> = new FormControl<number>(0);

  constructor() {
    super();
    this._hour.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        this.hourChange.emit(value);
      });
    this._minute.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        this.minuteChange.emit(value);
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hour) {
      this._hour.setValue(Math.max(Math.min(this.hour, 23), 0));
      this.validateMinimum();
    }
    if (changes.minute) {
      this._minute.setValue(Math.max(Math.min(this.minute, 59), 0));
      this.validateMinimum();
    }
    if (changes.min) {
      this.validateMinimum();
    }
  }

  increase(target: FormControl<number>, max: number) {
    let newVal;
    if (target === this._hour) {
      newVal = target.value + 1;
    } else {
      newVal =
        Math.ceil((target.value % 5 ? target.value : target.value + 1) / 5) * 5;
    }
    target.setValue(Math.min(newVal, max));
  }

  decrease(target: FormControl<number>) {
    let newVal;
    if (target === this._hour) {
      newVal = target.value - 1;
    } else {
      newVal =
        Math.floor((target.value % 5 ? target.value : target.value - 1) / 5) *
        5;
    }
    target.setValue(Math.max(newVal, 0));
    this.validateMinimum();
  }

  valueFor(target: FormControl<number>) {
    return `00${target.value.toFixed(0)}`.slice(-2);
  }

  onClick(event: PointerEvent) {
    const target: HTMLInputElement = event.target as HTMLInputElement;
    target.focus();
    target.select();
  }

  onKeyDown(event: KeyboardEvent, target: FormControl<number>, max: number) {
    let targetEl: HTMLInputElement = event.target as HTMLInputElement;
    if (['ArrowLeft', 'ArrowRight'].includes(event.key)) {
      if (
        event.key === 'ArrowLeft' &&
        targetEl.selectionStart === 0 &&
        target === this._minute
      ) {
        targetEl = targetEl.parentElement.parentElement.querySelector(
          '.sec-wrapper:first-child input'
        );
        targetEl.focus();
        targetEl.selectionStart = 2;
      }
      if (
        event.key === 'ArrowRight' &&
        targetEl.selectionStart === 2 &&
        target === this._hour
      ) {
        targetEl = targetEl.parentElement.parentElement.querySelector(
          '.sec-wrapper:last-child input'
        );
        targetEl.focus();
        targetEl.selectionStart = 0;
      }
      return;
    }
    if (event.key === 'Tab') {
      return;
    }
    if (event.key === 'ArrowUp') {
      this.increase(target, max);
      setTimeout(() => (event.target as any)?.select(), 50);
    }
    if (event.key === 'ArrowDown') {
      this.decrease(target);
      setTimeout(() => (event.target as any)?.select(), 50);
    }
    event.preventDefault();
    event.stopPropagation();
    if (!'0123456789'.includes(event.key)) return;
    const value = parseInt(event.key);
    if (targetEl.selectionStart === 0) {
      this.handleFirstPart(target, targetEl, value);
    } else if (targetEl.selectionStart === 1) {
      this.handleSecondPart(target, targetEl, value);
    }
    this.validateMinimum();
  }

  private validateMinimum() {
    const minHours = Math.floor(this.min / 60);
    let isEdgeHour = this._hour.value === minHours;
    if (this._hour.value < minHours) {
      this._hour.setValue(minHours);
      isEdgeHour = true;
    }
    if (isEdgeHour) {
      const minMinutes = this.min % 60;
      if (this._minute.value < minMinutes) {
        this._minute.setValue(minMinutes);
      }
    }
  }

  private handleFirstPart(
    target: FormControl<number>,
    targetEl: HTMLInputElement,
    value: number
  ) {
    let cutoff = target === this._hour ? 3 : 6;
    if (value >= cutoff) {
      this.handleSecondPart(target, targetEl, value);
      return;
    }
    target.setValue(value * 10);
    setTimeout(() => {
      targetEl.focus();
      targetEl.selectionStart = 1;
    }, 50);
  }

  private handleSecondPart(
    target: FormControl<number>,
    targetEl: HTMLInputElement,
    value: number
  ) {
    if (
      target === this._hour &&
      Math.floor(target.value / 10) === 2 &&
      value > 3
    )
      return;
    target.setValue(Math.floor(target.value / 10) * 10 + value);

    setTimeout(() => {
      if (target === this._hour) {
        targetEl = targetEl.parentElement.parentElement.querySelector(
          '.sec-wrapper:last-child input'
        );
        targetEl.focus();
        targetEl.selectionStart = 0;
        targetEl.selectionEnd = 1;
      } else {
        targetEl.focus();
        targetEl.selectionStart = 1;
      }
    }, 50);
  }

  onLabelClick() {
    if (this.required) return;
    this.active = !this.active;
    this.activeChange.emit(this.active);
  }
}
