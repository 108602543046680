<h1 mat-dialog-title>
  {{ "lawyer.editor.form.title" | translate }} ({{ data?.name || "" }})
</h1>
<mat-dialog-content cdkDropListGroup>
  <app-form-editor-core
    [questions]="data.questions"
    [dataSource]="data.dataSource"
    #form
  ></app-form-editor-core>
</mat-dialog-content>
<mat-dialog-actions>
  <ng-container *ngIf="!form?.editNode">
    <button mat-button (click)="dialogRef.close()">
      {{ "common.button.close" | translate }}
    </button>
    <button mat-raised-button color="primary" (click)="save()">
      {{ "common.button.save" | translate }}
    </button>
  </ng-container>
</mat-dialog-actions>
