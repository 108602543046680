import { uploadFile } from 'advoprocess';
import { firstValueFrom, from, switchMap } from 'rxjs';
import { CorporateIdentityService, FilesService } from 'src/api';

export function uploadProfilePicture(
  filesService: FilesService,
  ciService: CorporateIdentityService,
  userId: string
): Promise<void> {
  const fInput = document.createElement('input');
  fInput.type = 'file';
  fInput.accept = 'image/png,image/jpeg';
  fInput.style.visibility = 'none';
  fInput.style.position = 'absolute';

  return new Promise<void>((resolve, reject) => {
    fInput.onchange = () => {
      const file = fInput.files[0];
      if (!file) {
        fInput.remove();
        return;
      }
      handleProfilePicUpload(file, filesService, ciService, userId)
        .then(() => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    };
    document.body.appendChild(fInput);
    fInput.click();
    fInput.remove();
  });
}

function scaleProfilePicture(
  file: File,
  maxWidth: number = 256,
  maxHeight: number = 256
): Promise<File> {
  return new Promise((resolve, reject) => {
    // Create an image element
    const img = new Image();
    img.src = URL.createObjectURL(file);

    img.onload = () => {
      // Calculate the scaling factors
      let scale = Math.min(maxWidth / img.width, maxHeight / img.height);

      // Calculate the new canvas dimensions
      const canvasWidth = img.width * scale;
      const canvasHeight = img.height * scale;

      // Create an off-screen canvas and get its context
      const canvas = document.createElement('canvas');
      canvas.width = canvasWidth;
      canvas.height = canvasHeight;
      const ctx = canvas.getContext('2d');

      if (!ctx) {
        reject(new Error('Unable to get canvas context'));
        return;
      }

      // Draw the image on the canvas with the new dimensions
      ctx.drawImage(img, 0, 0, canvasWidth, canvasHeight);

      // Convert the canvas back to a Blob
      canvas.toBlob((resultBlob) => {
        if (resultBlob) {
          const resizedFile = new File([resultBlob], file.name, {
            type: resultBlob.type,
            lastModified: new Date().getTime(),
          });
          resolve(resizedFile);
        } else {
          reject(new Error('Canvas to Blob conversion failed'));
        }
      });
    };

    img.onerror = () => {
      reject(new Error('Image loading failed'));
    };
  });
}

function handleProfilePicUpload(
  file: File,
  filesService: FilesService,
  ciService: CorporateIdentityService,
  userId: string
): Promise<void> {
  return firstValueFrom(
    from(scaleProfilePicture(file)).pipe(
      switchMap((scaledFile) => {
        return uploadFile(scaledFile, null, {}, filesService, undefined, true);
      }),
      switchMap((fileId) =>
        ciService.setLawyerCorporateIdentity({
          lawyerCorporateIdentity: {
            lawyer_headshot: fileId.file_identifier,
          },
          lawyerid: userId,
        })
      )
    )
  );
}
