<button class="profile-chip" *ngIf="auth.loggedIn" (click)="goToProfile()">
  <div class="user-icon">
    <mat-icon>person</mat-icon>
  </div>
  <span class="user-name" *ngIf="!auth.isGuest && (auth.userName || auth.userMail)">{{auth.userName ??
    auth.userMail}}</span>
  <div class="separator"></div>
  <button mat-icon-button [matTooltip]="'common.button.logout' | translate"
    (click)="$event.preventDefault(); $event.stopPropagation(); auth.logOut()">
    <mat-icon>logout</mat-icon>
  </button>
</button>
<button mat-button class="login-button" *ngIf="!auth.loggedIn && !!realm?.length" (click)="showLogin = true">
  <mat-icon>login</mat-icon>
  <span>{{ 'common.login.title' | translate }}</span>
</button>

<app-keycloak-overlay mode="login" *ngIf="showLogin" (close)="showLogin = false"
  (login)="showLogin = false"></app-keycloak-overlay>