import { Component, Inject } from '@angular/core';
import { token } from './token';
import { SettingInputData } from '../node-setting.component';

@Component({
  selector: 'app-multi-value-input',
  template: `
    <div class="multi-value-input">
      <div class="rich-label">{{ data.logicRef.name | translate }}</div>
      <div class="multi-value-inputs">
        <mat-form-field
          appearance="outline"
          *ngFor="let number of data.logicRef.value.value; let j = index"
        >
          <mat-label>{{
            data.logicRef?.multiValueLabels[j] ?? '' | translate
          }}</mat-label>
          <input
            matInput
            type="number"
            [value]="number"
            (change)="
              data.logicRef.value.value[j] = intFromString($event.target.value)
            "
            (keyup)="changeInput()"
            (pointerdown)="$event.stopPropagation()"
          />
        </mat-form-field>
      </div>
    </div>
  `,
  styles: [],
})
export class MultiValueInputComponent {
  intFromString = parseInt;

  constructor(@Inject(token) public data: SettingInputData) {}

  changeInput(): void {
    if (this.data.logicRef.callback) {
      this.data.logicRef.callback();
    }
  }
}
