import { Component, Inject } from '@angular/core';
import { token } from './token';
import { SettingInputData } from '../node-setting.component';
import { parseOpenAPIToAbstractNodeSetting } from 'advoprocess/lib/nodes/addon-nodes/actport-schemas';
import { AbstractNodeSetting } from 'advoprocess/lib/nodes/abstract-node';

@Component({
  selector: 'app-multi-setting-input',
  template: `
    <div class="sub-settings-container">
      <span class="title">{{ data.logicRef.name | translate }}</span>
      <div class="settings-list">
        <div
          class="sub-setting-group"
          *ngFor="let subsettingGroup of subSettings; let i = index"
        >
          <div class="sub-setting-settings">
            <button mat-icon-button (click)="removeSubSettingGroup(i)">
              <mat-icon>delete</mat-icon>
            </button>
          </div>
          <ng-container *ngFor="let subsetting of subsettingGroup">
            <app-node-setting
              *ngIf="subsetting?.formOnly ? isInForm : true"
              [context]="data?.context"
              [logicRef]="subsetting"
              [allNodes]="data?.allNodes"
              [color]="data?.color"
              [processNode]="data?.processNode"
            ></app-node-setting>
          </ng-container>
        </div>
      </div>
      <button mat-raised-button color="primary" (click)="addToSubSettingList()">
        <mat-icon>add</mat-icon
        ><span>{{ 'common.button.addEntry' | translate }}</span>
      </button>
    </div>
  `,
  styles: [],
})
export class MultiSettingInputComponent {
  subSettings: AbstractNodeSetting[][] = [];

  constructor(@Inject(token) public data: SettingInputData) {
    this.data.logicRef.value.value.forEach((newConfig) => {
      const newSubSetting = parseOpenAPIToAbstractNodeSetting(
        this.data.logicRef.childTemplate.ref,
        newConfig,
        this.data.logicRef.childTemplate.name
      );
      this.subSettings.push(newSubSetting);
    });
  }

  addToSubSettingList() {
    if (!this.data.logicRef.value?.value || !this.data.logicRef.childTemplate)
      return;
    const newConfig = { value: {} };
    this.data.logicRef.value.value.push(newConfig);
    const newSubSetting = parseOpenAPIToAbstractNodeSetting(
      this.data.logicRef.childTemplate.ref,
      newConfig,
      this.data.logicRef.childTemplate.name
    );
    this.subSettings.push(newSubSetting);
  }

  removeSubSettingGroup(index: number) {
    this.data.logicRef.value.value.splice(index, 1);
    this.subSettings.splice(index, 1);
  }
}
