import { ChatMessage, ProcessParser, RawThread } from 'advoprocess';
import { Observable, debounceTime } from 'rxjs';
import * as _ from 'lodash';
import { AuthService } from 'src/app/auth/auth.service';
import { Event, EventsService, ListEventsResponse } from 'src/api';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

export interface ScheduledNodeDefinition {
  node: any;
  chat: ChatMessage[];
}

export const isAutothrow = (type: string) => {
  return (
    !!type && ['appointment', 'deadline', 'contact', 'activity'].includes(type)
  );
};

export function getEventParser(
  rawEvent: Event,
  auth: AuthService,
  sourceThreadId: string,
  events: EventsService,
  onDateUpdate: () => void
): ProcessParser {
  dayjs.extend(customParseFormat);
  if (!auth.loggedIn) return;

  const input = rawEvent.event_content as ScheduledNodeDefinition;

  const parser = new ProcessParser({});

  parser.onMessage.pipe(debounceTime(250)).subscribe(() => {
    parser.save();
  });

  let previousDueAt = rawEvent.due_at;
  let previousDuration = rawEvent.durationMinutes;

  parser.onSaveCallback = (state) =>
    new Promise((resolve, reject) => {
      if (!state || (!_.has(state, 'chat') && !_.has(state, 'node_template'))) {
        resolve();
        return;
      }
      const isDurationDifferent = rawEvent.durationMinutes !== previousDuration;
      const parsedNewDate = dayjs(
        rawEvent.due_at.slice(0, 16),
        'DD.MM.YYYY HH:mm'
      );
      const parsedOldDate = dayjs(previousDueAt, 'DD.MM.YYYY HH:mm.SSS');
      const isDateDifferent = !parsedNewDate.isSame(parsedOldDate);
      const autothrow = isAutothrow(rawEvent.event_type);
      const done =
        autothrow && isDateDifferent
          ? parsedNewDate.isBefore(dayjs())
          : undefined;
      events
        .updatePlannedEvent({
          eventid: rawEvent.id,
          event: {
            event_content: {
              chat: parser.thread.chat,
              node: rawEvent.event_content.node,
            },
            done,
            due_at: isDateDifferent ? parsedNewDate.toISOString() : undefined,
            durationMinutes: isDurationDifferent
              ? rawEvent.durationMinutes
              : undefined,
          },
        })
        .subscribe(() => {
          if (isDateDifferent) {
            rawEvent.due_at = parsedNewDate.format('DD.MM.YYYY HH:mm:ss.SSS');
            rawEvent.done = done;
            onDateUpdate();
          }
          resolve();
        });
    });
  parser.onRequireAuth.subscribe(async ({ resolve, reject }) => {
    resolve();
  });
  parser.jwt$ = auth.jwtToken$;
  parser.setupThread(getEventThread(input, sourceThreadId));
  parser.start(() => {
    // ToDo: Post the finished nodes to the thread
  });
  return parser;
}

function getEventThread(
  input: ScheduledNodeDefinition,
  sourceThreadId: string
): RawThread {
  return {
    chat: input.chat,
    id: sourceThreadId,
    name: '',
    currentNode: input.node.id,
    dataStore: {},
    status: 'OPEN',
    nodeTemplate: {
      data: [input.node],
      info: {
        name: '',
      },
    },
  };
}

export interface ScheduledNode {
  parser: ProcessParser;
  chat: ChatMessage[];
  event: Event;
}

export const DEFAULT_EVENT_FIELDS = [
  {
    display_name: 'id',
    internal_name: 'id',
  },
  {
    display_name: 'event_type',
    internal_name: 'event_type',
  },
  {
    display_name: 'done',
    internal_name: 'done',
  },
  {
    display_name: 'due_at',
    internal_name: 'due_at',
  },
  {
    display_name: 'durationMinutes',
    internal_name: 'duration_minutes',
  },
  {
    display_name: 'priority',
    internal_name: 'priority',
  },
  {
    display_name: 'created_at',
    internal_name: 'created_at',
  },
  {
    display_name: 'state_ids',
    internal_name: 'states!.id',
  },
  {
    display_name: 'thread_ids',
    internal_name: 'threads!.id',
  },
  {
    display_name: 'event_content',
    internal_name: 'event_content',
  },
];

export function getThreadEvents(
  threadId: string,
  stateId: string,
  eventsService: EventsService
): Observable<ListEventsResponse> {
  return eventsService.queryEvents({
    filterViewPagination: {
      filter: [
        {
          operand: 'states!.id',
          operator: 'eq',
          value: stateId,
        },
        {
          operand: 'threads!.id',
          operator: 'eq',
          value: threadId,
        },
      ],
      pagination: {
        page: 1,
        rows_per_page: 100,
      },
      view: {
        sort_by: {
          by: 'created_at',
          direction: 'asc',
        },
        displayed_columns: [...DEFAULT_EVENT_FIELDS],
      },
    },
  });
}
