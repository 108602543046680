import dayjs from 'dayjs';
import _ from 'lodash';
import { Scope, Thread } from 'src/api';
import { AuthService } from 'src/app/auth/auth.service';
import { ProcessService } from './process.service';
import { canWriteThread } from './helpers';

export interface Activity {
  label: string;
  scope: string;
  children: (Activity[] | Thread)[];
}

type ExtendedScope = Scope & {
  children: ExtendedScope[];
  threads: Thread[];
};

function isThread(inpt: any): inpt is Thread {
  return inpt.path;
}

export function parseActivities(
  scopes: Scope[],
  threads: Thread[],
  auth: AuthService,
  service: ProcessService
): Activity | undefined {
  const scopeTree = scopes
    .map((sc) => {
      const s = sc as ExtendedScope;
      s.children = scopes.filter(
        (s2) => s2.parent_scope == s.id
      ) as ExtendedScope[];
      s.threads = threads.filter((t) => t.path == s.id);
      s.createdAt =
        s.threads[0]?.createdAt ?? s.createdAt ?? '1970-01-01T00:00:00';
      return s;
    })
    .find((s) => !s.parent_scope);
  if (!scopeTree) {
    return undefined;
  }
  // return flattenActivities(buildActivitesFromScope(scopeTree, auth, service));
  return buildActivitesFromScope(scopeTree, auth, service);
}

function buildActivitesFromScope(
  scope: ExtendedScope,
  auth: AuthService,
  service: ProcessService
): Activity {
  const groupedScopes: {
    scopes: ExtendedScope[];
    createdAt: string;
    scopePath: string;
  }[] = scope.children.reduce((p, c) => {
    const similarPath = p.find((k) =>
      _.isEqual(k.scopePath.slice(0, -1), c.template_path.slice(0, -1))
    );
    if (similarPath) {
      similarPath.scopes.push(c);
      if (dayjs(c.createdAt).isBefore(similarPath.createdAt)) {
        similarPath.createdAt = c.createdAt;
      }
    } else {
      p.push({
        scopePath: c.template_path,
        createdAt: c.createdAt,
        scopes: [c],
      });
    }
    return p;
  }, []);
  const scopesAndThreads = [...groupedScopes, ...scope.threads];
  const children = scopesAndThreads
    .sort((a, b) => {
      return dayjs(a.createdAt).diff(dayjs(b.createdAt));
    })
    .map((c) => {
      if (isThread(c)) {
        if (
          c.empty &&
          c.status === 'COMPLETED' &&
          !canWriteThread(c, auth, service, 'write')
        ) {
          // if (!scope?.template_path || !!scope.template_path.length) {
          return undefined;
          // }
        }
        return c;
      } else {
        const children = c.scopes
          .sort((a, b) => dayjs(a.createdAt).diff(dayjs(b.createdAt)))
          .map((s) => buildActivitesFromScope(s, auth, service))
          .filter((s) => !!s.children?.length);

        if (!children.length) return undefined;

        return children;
      }
    })
    .filter((c) => c !== undefined);
  return {
    label: scope.name ?? scope.alias ?? '',
    scope: scope.id,
    children,
  };
}

function flattenActivities(a: Activity): Activity {
  return {
    ...a,
    children: a.children.reduce((p, c) => {
      if (_.isArray(c)) {
        const flattened = c.map((a) => flattenActivities(a));
        if (c.length === 1) {
          return [...p, ...flattened[0].children];
        } else if (c.length > 1) {
          return [...p, flattened];
        }
      } else {
        return [...p, c];
      }
    }, [] as (Activity[] | Thread)[]),
  };
}
