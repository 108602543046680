import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { NgScrollbar } from 'ngx-scrollbar';
import { getContrastYIQ } from 'src/app/common/helpers';

export interface Setting {
  name: string;
  description?: string;
  icon?: string;
  value: any;
  placeholder?: string;
  type: 'text' | 'button' | 'check' | 'image' | 'color' | 'dropdown';
  validator?: (input: string) => string | boolean;
  onChange: (newValue: any) => Promise<void>;
  multiline?: boolean;
  options?: { label: string; value: string; icon?: string }[];
  beta?: boolean;
}

export interface SettingsGroup {
  title: string;
  icon: string;
  settings: Setting[];
}

@Component({
  selector: 'app-settings-view',
  templateUrl: './settings-view.component.html',
  styleUrls: ['./settings-view.component.scss'],
})
export class SettingsViewComponent implements OnInit {
  @Input() config: SettingsGroup[] = [];

  @ViewChild('scrollBar', { static: true })
  scrollBar: NgScrollbar;

  focusGroup: SettingsGroup | undefined = undefined;

  constructor(
    private snackBar: MatSnackBar,
    private translator: TranslateService
  ) {}

  ngOnInit(): void {
    // ToDo
    // this.scrollBar.psScrollY.subscribe(() => {
    //   this.updateFocusGroup();
    // });
  }

  private updateFocusGroup() {
    // const targetIndex = Array.from(
    //   this.scrollBar.directiveRef.elementRef.nativeElement.querySelectorAll(
    //     '.settings-group'
    //   )
    // ).findIndex((groupEl: HTMLElement | undefined) => {
    //   groupEl?.offsetTop > this.scrollBar.directiveRef.geometry().y;
    // });
    // console.log(targetIndex);
  }

  saveField(setting: Setting, value: any) {
    setting.value = value;
    setting.onChange(setting.value);
    this.snackBar.open(
      this.translator.instant(`common.message.savedSetting`, {
        name: this.translator.instant(setting.name),
      })
    );
  }

  updateColor(setting: Setting, event) {
    this.saveField(setting, event);
  }

  useLightText(color: string): boolean {
    if (!color) {
      return false;
    }
    return getContrastYIQ(color) < 200;
  }

  setImageFile(setting: Setting, event) {
    const files = (event.target as HTMLInputElement).files;
    if (files[0]) {
      setting.onChange(files[0]);
      this.snackBar.open(
        this.translator.instant(`common.message.savedSetting`, {
          name: this.translator.instant(setting.name),
        })
      );
    }
  }

  findOption(
    setting: Setting
  ): { label: string; value: string; icon?: string } | undefined {
    return setting.options?.find((o) => o.value === setting.value);
  }
}
