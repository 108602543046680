import {
  Input,
  NgZone,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
} from '@angular/core';
import { getContrastYIQ } from '../../../../../common/helpers';
import {
  AbstractNode,
  PortType,
  ProcessNode,
  StartNode,
  SubProcessNode,
} from 'advoprocess';
import { NodeOperationsService } from '../node-operations';
import { TranslateService } from '@ngx-translate/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import {
  TriggerSetupDialogComponent,
  TriggerSetupDialogData,
} from '../triggers/trigger-setup-dialog.component';
import {
  AvailableProperty,
  allProperties,
} from 'src/app/common/nodesProperties';

export interface NodeContextFallbacks {
  editDocument?: (node: ProcessNode) => void;
}

export interface NodeContext {
  currentEditorLocation?: string;
  fallbacks?: NodeContextFallbacks;
}

@Component({
  selector: 'app-node',
  templateUrl: './node.component.html',
  styleUrls: ['./node.component.scss'],
})
export class EditorNodeComponent implements OnInit {
  portOut = PortType.Output;
  portIn = PortType.Input;

  @ViewChild('inputNode', { read: ElementRef }) inputNodeRef: ElementRef;
  @ViewChildren('outputNodes', { read: ElementRef })
  outputNodeRefs: QueryList<ElementRef>;

  @ViewChild('outPortContainer', { read: ElementRef })
  outputContainer: ElementRef;

  availableProperties: AvailableProperty[] = [];

  @Input() logicRef: AbstractNode;
  @Input() processNodeRef?: ProcessNode;

  @Input() context: NodeContext;

  @Input() outOfView = false;

  @Input() isInForm = false;

  @Input() zoomLevel: number;

  @HostBinding('class.selected')
  selected = false;

  connectedToOut: EditorNodeComponent[] = [];
  connectedToIn: EditorNodeComponent[] = [];

  @HostBinding('class.compact')
  compact = false;

  minimizable = true;

  @Output() portDrag = new EventEmitter<{
    type: PortType;
    index: number;
    connectedTo: EditorNodeComponent;
  }>();
  @Output() portDragEnd = new EventEmitter<{ type: PortType; index: number }>();
  @Output() becomeMinimized = new EventEmitter<boolean>();
  @Output() requestDelete = new EventEmitter<boolean>();
  @Output() sizeChange = new EventEmitter<{ width: number; height: number }>();

  get backgroundColor(): string {
    return this.logicRef?.style?.backgroundColor || null;
  }

  @HostBinding('class.initial')
  get _isInitialNode(): boolean {
    return this.logicRef instanceof StartNode;
  }

  get _isSubprocessNode(): boolean {
    return this.logicRef instanceof SubProcessNode;
  }

  @HostBinding('class.expanded')
  get hasMultipleTriggers(): boolean {
    return this.sketchManager?.triggers?.length > 0;
  }

  sketchManager = this.nodeOperationsService.sketchManager;

  constructor(
    private el: ElementRef,
    public nodeOperationsService: NodeOperationsService,
    public translator: TranslateService,
    private matDialog: MatDialog,
    private zone: NgZone
  ) {}

  ngOnInit(): void {
    this.initOutConnections();
    if (this.processNodeRef) {
      this.processNodeRef.ui = this;
    }
    if (
      !this.logicRef?.settings &&
      this.logicRef?.debugOutput === '' &&
      !this._isSubprocessNode
    ) {
      this.compact = true;
      this.minimizable = false;
    }
    if (this._isSubprocessNode) {
      this.minimizable = false;
    }
    this.availableProperties = allProperties.filter((prop) =>
      prop.enableIf(this.logicRef)
    );
    const resizeObserver = new ResizeObserver((entries) => {
      this.zone.run(() => {
        this.sizeChange.emit(entries[0]?.contentRect);
      });
    });
    resizeObserver.observe(this.el.nativeElement);
  }

  initOutConnections(): void {
    if (this.connectedToOut) {
      this.connectedToOut.forEach((out) => {
        if (!out || !out.connectedToIn) {
          return;
        }
        out.connectedToIn = out.connectedToIn.filter((inp) => inp !== this);
      });
    }
    this.connectedToOut = this.logicRef.outputs.map((l, indx) => {
      if (this.connectedToOut && this.connectedToOut[indx]) {
        return this.connectedToOut[indx];
      }
      return null;
    });
  }
  @HostBinding('class.lightText')
  get useLightText(): boolean {
    if (!this.logicRef?.style?.backgroundColor) {
      return false;
    }
    return getContrastYIQ(this.logicRef.style.backgroundColor) < 200;
  }

  portPosition(type: PortType, index = 0): { x: number; y: number } {
    const s =
      type === PortType.Input
        ? this.inputNodeRef
        : this.outputNodeRefs.toArray()[index];
    if (!s) {
      return { x: 0, y: 0 };
    }
    const target = s?.nativeElement?.querySelector(
      '.port-container, .merge-condition'
    );
    return {
      x:
        this.el.nativeElement.offsetLeft +
        s.nativeElement?.offsetLeft +
        target.offsetWidth / 2 +
        target?.offsetLeft,
      y:
        this.el.nativeElement.offsetTop +
        (type === PortType.Input
          ? 30
          : this.el.nativeElement.offsetHeight - target.offsetHeight / 2),
    };
  }

  select(): void {
    this.selected = true;
  }

  deselect(): void {
    this.selected = false;
  }

  minimize(): void {
    this.compact = !this.compact;
    window.setTimeout(() => this.becomeMinimized.emit(this.compact), 300);
  }

  delete(): void {
    this.requestDelete.emit(true);
  }

  emitDragStart(
    event,
    type: PortType,
    index = -1,
    connectedTo?: EditorNodeComponent
  ): void {
    // if (!this.processNodeRef.subs?.list?.length) {
    //   event.stopPropagation();
    // }
    this.portDrag.emit({ type, index, connectedTo });
  }

  emitDragEnd(type: PortType, index = -1): void {
    this.portDragEnd.emit({ type, index });
  }

  // Helpers for placing

  get width(): number {
    return this.el.nativeElement.getBoundingClientRect().width;
  }

  get height(): number {
    return this.el.nativeElement.getBoundingClientRect().height;
  }

  get elementRef(): ElementRef {
    return this.el;
  }

  get outputNames(): string[] {
    return this.logicRef.outputs;
  }

  addProcessStartTrigger() {
    if (!this._isInitialNode) return;
    const dialog = this.matDialog.open<
      TriggerSetupDialogComponent,
      TriggerSetupDialogData
    >(TriggerSetupDialogComponent, {
      data: {
        trigger: {
          type: 'variable.change',
        },
      },
    });
    dialog.afterClosed().subscribe((data) => {
      if (!data) return;
      this.sketchManager.addSketchTrigger(data);
      setTimeout(() => this.nodeOperationsService.positionsChanged.next(), 50);
    });
  }

  onPropertySwitch(event: Event, property: AvailableProperty) {
    event.stopPropagation();
    if (!this.logicRef.properties) return;
    (this.logicRef.properties[property.property] as boolean) =
      !this.logicRef.properties[property.property];
  }
}
