import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RealmSelectionComponent } from './realm-selection.component';
import { LoginComponent } from './auth/login/login.component';

const routes: Routes = [
  {
    path: 'process',
    loadChildren: () =>
      import('./views/client/client.view.module').then(
        (m) => m.ClientViewModule
      ),
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: '',
    component: RealmSelectionComponent,
  },
  {
    path: 'intern',
    redirectTo: '/',
  },
  {
    path: ':realm/intern',
    loadChildren: () =>
      import('./views/lawyer/lawyer.view.module').then(
        (m) => m.LawyerViewModule
      ),
  },
  {
    path: ':realm',
    loadChildren: () =>
      import('./views/client/client.view.module').then(
        (m) => m.ClientViewModule
      ),
  },
  {
    path: '**',
    redirectTo: '/',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
