import { Injectable } from '@angular/core';
import { DestroyNotifier } from '../views/process/process-view/destroy-notifier';
import { AuthService } from './auth.service';
import {
  BehaviorSubject,
  debounceTime,
  delay,
  distinctUntilChanged,
  map,
} from 'rxjs';
import {
  BooleanOperator,
  FilterCriterium,
  Permission,
  PermissionMode,
  PermissionPolicy,
} from 'src/api';
import { PermissionsService as APIPermissionsServie } from 'src/api';

import * as _ from 'lodash';
import {
  buildErrorString,
  isBooleanOperator,
  isFilterCriterium,
} from '../common/helpers';
import { TranslateService } from '@ngx-translate/core';
import {
  FILTER_REGEX,
  applyFilterCondition,
  getModelField,
  isPermission,
  splitRelevantErrors,
} from './permission-helpers';
import { AvailableFilter } from 'advoprocess/lib/types/filter';

/**
 * Specifies whether a validation was successful or not.
 */
export interface ValidationResult {
  /** Was the validation succesful? */
  success: boolean;
  /** If the specification was not successful, this contains further information about why. */
  error?: (FilterCriterium | BooleanOperator)[] | Permission;
}

@Injectable({
  providedIn: 'root',
})
export class PermissionsService extends DestroyNotifier {
  private _permissions: Permission[] | undefined = undefined;

  public ready$ = new BehaviorSubject<boolean>(false);

  constructor(
    private auth: AuthService,
    private api: APIPermissionsServie,
    private translator: TranslateService
  ) {
    super();

    this.auth.jwtToken$
      .pipe(
        map((t) => Boolean(t)),
        distinctUntilChanged(),
        debounceTime(500),
        map((_) => this.auth.jwtToken$.value)
      )
      .subscribe((t) => {
        if (!t || this.auth.isClient) {
          this.clear();
        } else {
          this.init();
        }
      });
  }

  public get permissions(): Permission[] | undefined {
    return this._permissions;
  }

  private init() {
    this.api.getOwnPermissions({}).subscribe((permissions) => {
      this._permissions = permissions;
      this.ready$.next(true);
    });
  }

  private clear() {
    this._permissions = undefined;
    this.ready$.next(false);
  }

  public canRead(model: PermissionPolicy.ModelEnum) {
    if (!this._permissions?.length) return true;
    return !this._permissions?.some((p) => {
      const mode =
        p.policies?.find((pol) => pol.model === model)?.read?.mode ??
        PermissionMode.ModeEnum.Allow;
      return mode === PermissionMode.ModeEnum.Forbid;
    });
  }

  /**
   *
   * @param instance A model instance which should be validated against the current permission policies.
   * @param model A model specification to validate the instance against.
   * @returns A validation Result
   */
  public validate(
    instance: any,
    model: PermissionPolicy.ModelEnum,
    additionalPrefixes?: string[]
  ): ValidationResult {
    if (!this.permissions?.length)
      return {
        success: true,
      };
    let errors: Array<FilterCriterium | BooleanOperator> = [];
    for (const perm of this.permissions) {
      for (const policy of perm.policies) {
        if (policy.model !== model) continue;
        if (
          policy.read.mode === PermissionMode.ModeEnum.Forbid ||
          policy.write.mode === PermissionMode.ModeEnum.Forbid
        ) {
          return {
            success: false,
            error: perm,
          };
        }
        if (
          policy.read.mode === PermissionMode.ModeEnum.Allow &&
          policy.write.mode === PermissionMode.ModeEnum.Allow
        )
          continue;
        const filters = (
          policy.read.mode === PermissionMode.ModeEnum.Filter
            ? policy.read.filters
            : []
        ).concat(
          policy.write.mode === PermissionMode.ModeEnum.Filter
            ? policy.write.filters
            : []
        );
        for (const filter of filters) {
          const validated = this.applyFilter(
            instance,
            filter,
            additionalPrefixes
          );
          if (!validated.success) {
            errors = errors.concat(validated.error);
          }
        }
      }
    }
    if (errors.length) {
      return {
        success: false,
        error: errors,
      };
    } else {
      return {
        success: true,
      };
    }
  }

  // Get if condition cannot be validated using the given information in the frontend (e.g. if it is complicated join).
  // If that is the case, just assume that the filter is valid. In the worst case the backend will tell you that it
  // is not valid.
  private shouldSkipFilterCheckFor(
    operand: string,
    instance: any,
    additionalPrefixes: string[] = []
  ): boolean {
    let firstPart = operand?.split('.')?.[0]?.replace(/!/gm, '') ?? '';
    const withFilter = firstPart.match(FILTER_REGEX);
    firstPart = withFilter[1];
    if (!!instance && Object.keys(instance).includes(firstPart)) return false;
    if (additionalPrefixes.includes(firstPart)) return false;
    return true;
  }

  private applyFilter(
    instance: any,
    filter: FilterCriterium | BooleanOperator,
    additionalPrefixes?: string[]
  ): Omit<ValidationResult, 'error'> & {
    error?: (FilterCriterium | BooleanOperator)[];
  } {
    let errors: (FilterCriterium | BooleanOperator)[] = [];
    if (isFilterCriterium(filter)) {
      if (
        this.shouldSkipFilterCheckFor(
          filter.operand,
          instance,
          additionalPrefixes
        )
      ) {
        return {
          success: true,
        };
      }
      let compareVal = getModelField(instance, filter.operand, this.auth);
      const evaluated = applyFilterCondition(filter, compareVal, this.auth);
      if (!evaluated) {
        if (_.isArray(compareVal)) {
          for (const entry of compareVal) {
            const evaluated = applyFilterCondition(filter, entry, this.auth);
            if (evaluated) {
              return {
                success: true,
              };
            }
          }
          errors = [filter];
        } else {
          errors = [filter];
        }
      }
    } else {
      const validated: (Omit<ValidationResult, 'error'> & {
        error?: (FilterCriterium | BooleanOperator)[];
      })[] = [];
      for (const subFilter of filter.filters) {
        validated.push(
          this.applyFilter(instance, subFilter, additionalPrefixes)
        );
      }
      if (filter.operator === 'or' && validated.every((v) => !v.success)) {
        errors = [
          {
            operator: 'or',
            filters: validated.map((v) =>
              v.error.length === 1
                ? v.error[0]
                : { operator: 'and', filters: v.error }
            ),
          },
        ];
      } else if (
        filter.operator === 'and' &&
        validated.some((v) => !v.success)
      ) {
        errors = _.flatten(
          validated
            .filter((v) => !v.success)
            .map((v) => v.error as FilterCriterium[])
        );
      }
    }
    if (errors.length) {
      return {
        success: false,
        error: errors,
      };
    } else {
      return {
        success: true,
      };
    }
  }

  /**
   * Get a human-readable error string from a filter definition.
   * @param error The error response to be converted to a human-readable format.
   * @param availableFilters All known filters to System. They are used to find label data for string rendering.
   * @returns A (translated) string that informs the user about the error reasons.
   */
  public humanReadableError(
    error: (FilterCriterium | BooleanOperator)[] | Permission,
    availableFilters?: AvailableFilter[]
  ): string {
    let errorMessage = ``;
    if (_.isArray(error)) {
      errorMessage = error
        .map((err) => {
          return this.humanReadableFilterErrors(err, availableFilters);
        })
        .join('');
    } else {
      errorMessage = this.translator.instant(
        'common.error.explanations.policyForbids',
        {
          name: error.name,
        }
      );
    }
    return `<ul>${errorMessage}</ul>`;
  }

  private humanReadableFilterErrors(
    error: FilterCriterium | BooleanOperator,
    availableFilters?: AvailableFilter[],
    fromBool: 'and' | 'or' | null = null
  ): string {
    if (isFilterCriterium(error)) {
      return `<li>${buildErrorString(
        this.translator,
        error,
        availableFilters
      )}${
        fromBool
          ? ` <b>${this.translator.instant('common.label.' + fromBool)}</b>`
          : ''
      }</li>`;
    } else {
      const errorMessage = error.filters
        .map((err, i) => {
          return this.humanReadableFilterErrors(
            err,
            availableFilters,
            i === error.filters.length - 1 ? null : error.operator
          );
        })
        .join('');
      return `<li>${this.translator.instant(
        'common.error.explanations.' + error.operator
      )}<ul>${errorMessage}</ul></li>`;
    }
  }

  /**
   * Get the possible values for a model field based on a filter configuration.
   * @param model The model specification.
   * @param instance The model instance for which to get the possible values - should be a current snapshot of the model so filters not pertaining to the target operand can be evaluated as well.
   * @param operand The path to the field in the `instance` object for which we want to retrieve the possible values.
   * @returns Either an array of all the possible value options for the specified operand, or '*' if there are no specific restrictions.
   */
  public getPossibleValuesFor(
    model: PermissionPolicy.ModelEnum,
    instance: any,
    operand: string,
    defaultOtherHandling: [] | '*' = []
  ): string[] | '*' {
    if (!this._permissions?.length) return '*';
    let allFilters = [];

    for (const perm of this._permissions) {
      for (const policy of perm.policies) {
        if (policy.model !== model) continue;
        if (
          policy.write.mode === PermissionMode.ModeEnum.Forbid ||
          policy.read.mode === PermissionMode.ModeEnum.Forbid
        )
          return [];
        const combinedFilters = (
          policy.read.mode === PermissionMode.ModeEnum.Filter
            ? policy.read.filters
            : []
        ).concat(
          policy.write.mode === PermissionMode.ModeEnum.Filter
            ? policy.write.filters
            : []
        );
        allFilters = allFilters.concat(combinedFilters);
      }
    }

    return this.getPossibleValuesForFilter(
      {
        operator: 'and',
        filters: allFilters,
      },
      instance,
      operand,
      defaultOtherHandling
    );
  }

  /**
   * Get the possible values for a model field based on a filter configuration.
   * @param filter The filter criterium or Boolean operator which is restricting the possible values.
   * @param instance The model instance for which to get the possible values - should be a current snapshot of the model so filters not pertaining to the target operand can be evaluated as well.
   * @param operand The path to the field in the `instance` object for which we want to retrieve the possible values.
   * @returns Either an array of all the possible value options for the specified operand, or '*' if there are no specific restrictions.
   */
  private getPossibleValuesForFilter(
    filter: FilterCriterium | BooleanOperator,
    instance: any,
    operand: string,
    defaultOtherHandling: [] | '*' = []
  ): any[] | '*' {
    const intersect = (arr1: any[], arr2: any[]): any[] => {
      return arr1.filter((value) => arr2.includes(value));
    };

    const merge = (arr1: any[], arr2: any[]): any[] => {
      return [...new Set([...arr1, ...arr2])];
    };

    if (isBooleanOperator(filter)) {
      if (!filter.filters.length) {
        return '*';
      }
      const all = filter.filters.map((f) =>
        this.getPossibleValuesForFilter(
          f,
          instance,
          operand,
          defaultOtherHandling
        )
      );
      switch (filter.operator) {
        case 'and':
          return all.reduce((acc, cur) => {
            if (cur === '*') return acc;
            if (acc === '*') return cur;
            return intersect(cur, acc);
          }, '*');
        case 'or':
          return all.reduce((acc, cur) => {
            if (cur === '*' || acc === '*') return '*';
            return merge(cur, acc);
          }, []);
      }
    } else {
      if (filter.operand !== operand) {
        const appliedFilter = this.applyFilter(instance, filter);
        if (!appliedFilter.success) {
          return defaultOtherHandling;
        } else {
          return '*';
        }
      }
      switch (filter.operator) {
        case 'eq':
          return [filter.value];
        case 'includedIn':
          return filter.value;
        default:
          return '*';
      }
    }
  }

  /**
   * Validate a model but only caring about certain fields, ignoring all the others.
   * @param instance The model instance which should be validated against the current permissions.
   * @param focusOperands The operands on which to focus during validation (e.g. `node_template.info.name` for the name of a process)
   * @param model The model specification to determine correct permission
   * @returns Either true when there are no validation errors, a permission which forbids the validation, OR an object that defines the relevant errors (i.e. ones that the focusOperands specified) and the other ones
   */
  public validateFocused(
    instance: any,
    focusOperands: string[],
    model: PermissionPolicy.ModelEnum,
    additionalPrefixes?: string[]
  ):
    | true
    | {
        relevant: (FilterCriterium | BooleanOperator)[];
        other: (FilterCriterium | BooleanOperator)[];
      }
    | Permission {
    const validated = this.validate(instance, model, additionalPrefixes);
    if (validated.success) return true;
    else {
      let relevantErrors:
        | {
            relevant: (FilterCriterium | BooleanOperator)[];
            other: (FilterCriterium | BooleanOperator)[];
          }
        | Permission;
      if (_.isArray(validated.error)) {
        relevantErrors = splitRelevantErrors(validated.error, focusOperands);
      } else {
        relevantErrors = validated.error;
      }
      return relevantErrors;
    }
  }
}
