import {
  Overlay,
  OverlayPositionBuilder,
  OverlayRef,
} from '@angular/cdk/overlay';
import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ComponentPortal } from '@angular/cdk/portal';
import _ from 'lodash';
import { DueAtSelectorComponent } from './due-at-selector.component';
import { takeUntil } from 'rxjs';
import { DestroyNotifier } from '../../views/process/process-view/destroy-notifier';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[dueAtSelector]',
})
export class DueAtSelectorDirective
  extends DestroyNotifier
  implements OnInit, OnDestroy
{
  @Output() dueAtChanged = new EventEmitter<
    | {
        date: Date;
        durationMinutes?: number;
      }
    | undefined
  >();
  @Input() useTime = false;

  @Input('dueAtSelector') isActive = true;
  @Input() allowDelete = false;

  @Output() useTimeChange = new EventEmitter<boolean>();

  @Output() close = new EventEmitter<void>();

  @Input() initialDate?: Date | string = undefined;
  @Input() initialDurationMinutes?: number = undefined;

  @Input() allowEndTime = true;

  private _overlayRef: OverlayRef;

  constructor(
    private _overlay: Overlay,
    private _overlayPositionBuilder: OverlayPositionBuilder,
    private _elementRef: ElementRef
  ) {
    super();
  }

  /**
   * Init life cycle event handler
   */
  ngOnInit() {
    const positionStrategy = this._overlayPositionBuilder
      .flexibleConnectedTo(this._elementRef)
      .withPositions([
        {
          originX: 'center',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'bottom',
          offsetY: -5,
        },
      ]);

    this._overlayRef = this._overlay.create({ positionStrategy });
  }

  @HostListener('click', ['$event'])
  show(event) {
    event.stopPropagation();
    if (!this.isActive) return;
    //attach the component if it has not already attached to the overlay
    if (this._overlayRef && !this._overlayRef.hasAttached()) {
      const componentRef = this._overlayRef.attach(
        new ComponentPortal(DueAtSelectorComponent)
      );
      componentRef.instance.dueAtChanged
        .pipe(takeUntil(this.destroy$))
        .subscribe((value) => {
          this.dueAtChanged.emit(value);
        });
      componentRef.instance.close
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => this.close.emit());
      componentRef.instance.useTime = this.useTime;
      componentRef.instance.allowEndTime = this.allowEndTime;
      componentRef.instance.useTimeChange = this.useTimeChange;
      componentRef.instance.initialDate = this.initialDate;
      componentRef.instance.initialDurationMinutes =
        this.initialDurationMinutes;
      componentRef.instance.allowDelete = this.allowDelete;
      componentRef.instance.close.subscribe(() => {
        this.closeOverlay();
      });
      componentRef.instance.el.nativeElement.addEventListener(
        'click',
        (event) => {
          event.stopPropagation();
        }
      );
    }
  }

  @HostListener('document:click', ['$event'])
  clickout() {
    this.closeOverlay();
  }

  /**
   * Destroy lifecycle event handler
   * This method will make sure to close the tooltip
   */
  ngOnDestroy() {
    this.closeOverlay();
  }

  /**
   * This method will close the tooltip by detaching the component from the overlay
   */
  private closeOverlay() {
    if (this._overlayRef) {
      this._overlayRef.detach();
    }
  }
}
