import {
  HostListener,
  Injectable,
  Renderer2,
  RendererFactory2,
} from '@angular/core';
import dayjs from 'dayjs';

interface MetricsSession {
  startTimestamp: number;
  endTimestamp?: number;
  userid?: string;
  metrics: Metric[];
}

interface Metric {
  timestamp: number;
  name: string;
  value: any;
}

@Injectable({
  providedIn: 'root',
})
export class MetricsService {
  private _session: MetricsSession;

  constructor(private rendererFactory2: RendererFactory2) {
    const renderer = this.rendererFactory2.createRenderer(null, null);
    renderer.listen('window', 'beforeunload', this.onBeforeUnload);
  }

  startSession() {
    this._session = {
      startTimestamp: dayjs().unix(),
      metrics: [],
    };
    sessionStorage.setItem('metrics', JSON.stringify(this._session));
  }

  onBeforeUnload(): void {
    console.log(JSON.parse(sessionStorage.getItem('metrics')));
    console.log('Sending...');
    // const blob = new Blob([JSON.stringify(this._session)], { type: 'text/plain; charset=UTF-8' });
    // navigator.sendBeacon('https://api.silberfluss.io/metrics/raise', blob);
  }
}
