import { tagCreateRegex } from 'advoprocess';

export const applyWrapping = (editor: any) => {
  editor.s.save();
  const parentNode = editor.s?.current()?.parentNode as HTMLElement;
  if (!parentNode) {
    return;
  }
  if (parentNode.querySelectorAll('a')) {
    parentNode
      .querySelectorAll('a')
      .forEach((a) => a.setAttribute('target', '_blank'));
  }
  parentNode.innerHTML = parentNode.innerHTML.replace(
    tagCreateRegex,
    (_, match, match2) => {
      const path = match.split(/>|&gt;/);
      return `<placeholder contenteditable='false'><icon contenteditable='false'>check</icon>${
        path[0]
      }${
        path.length > 1
          ? path
              .slice(1)
              .reverse()
              .reduce(
                (acc, slice) => `<span class='subpath'>${slice}${acc}</span>`,
                ''
              )
          : ''
      }</placeholder>${match2}`;
    }
  );
  editor.s.restore();
};
