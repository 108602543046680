import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Inject, OnInit } from '@angular/core';
import { token } from './token';
import { SettingInputData } from '../node-setting.component';
import { DialogService } from 'src/app/widgets/dialog/dialog.service';
import {
  getDropDownOptions,
  parseFetchString,
  validVarSource,
} from 'advoprocess/lib/types/question';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-list-input',
  templateUrl: './list-input.component.html',
  styles: [],
})
export class ListInputComponent implements OnInit {
  listElements = [];
  useFetch: boolean = false;

  validVarSources: validVarSource[] = ['dossier', 'process'];

  fetchForm = new FormGroup({
    source: new FormControl<validVarSource>('dossier'),
    name: new FormControl<string>(''),
  });

  constructor(
    @Inject(token) public data: SettingInputData,
    private dialog: DialogService
  ) {}

  ngOnInit(): void {
    if (!this.data.logicRef) return;
    const opts = getDropDownOptions(this.data.logicRef.value);
    if (typeof opts === 'string') {
      this.listElements = [];
      this.useFetch = true;
      const parsedFetch = parseFetchString(opts);
      if (parsedFetch) {
        let name = parsedFetch.name;
        if (parsedFetch.labelKey?.length) {
          name += ` [${parsedFetch.labelKey}]`;
        }
        this.fetchForm.get('source').setValue(parsedFetch.source);
        this.fetchForm.get('name').setValue(name);
      }
      this.fetchForm.valueChanges.subscribe(() => this.updateListSource());
    } else {
      this.listElements = opts?.map((o) => o.text) ?? [];
    }
  }

  addOption(): void {
    this.dialog.prompt('Wert der Option').then(
      (newopt) => {
        if (newopt && newopt !== '') {
          this.listElements.push(newopt);
        }
        this.updateListSource();
      },
      () => {}
    );
  }

  async deleteListItem(index): Promise<void> {
    if (this.listElements.length <= 1 || index > this.listElements.length - 1) {
      return;
    }
    if (
      !(await this.dialog.confirm({
        text: `Option "${this.listElements[index]}" wirklich löschen?`,
      }))
    ) {
      return;
    }
    this.listElements.splice(index, 1);
    this.updateListSource();
  }

  editListItem(index): void {
    this.dialog
      .prompt('Wert der Option', { value: this.listElements[index] })
      .then(
        (value) => {
          this.listElements[index] = value;
          this.updateListSource();
        },
        () => {}
      );
  }

  drop(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.listElements, event.previousIndex, event.currentIndex);
    this.updateListSource();
  }

  private updateListSource(): void {
    if (this.data.logicRef.type !== 'attachments') {
      if (this.useFetch) {
        const name = this.fetchForm.get('name').value;
        const split = name.match(/^([^\[\s]+)\s*(?:\[([^\]]+)\])?$/);
        this.data.logicRef.value.value = `fetch::${
          this.fetchForm.get('source').value
        }::${split?.[1] ?? name}${split?.[2]?.length ? `::${split[2]}` : ''}`;
      } else {
        this.data.logicRef.value.value = this.listElements
          .map((el) => `"${el.replace(/"/gm, '\\"')}"`)
          .join(', ');
      }
    } else {
      this.data.logicRef.value.value = this.listElements;
    }
    if (this.data.logicRef.callback) {
      this.data.logicRef.callback();
    }
  }

  setUseFetch(fetch: boolean) {
    this.useFetch = fetch;
    this.updateListSource();
  }
}
