<div class="header-line">
  <div class="tabs">
    <button mat-button class="tab" [class.active]="activeView === 'notifications'" (click)="view('notifications')">
      <mat-icon>notifications</mat-icon>{{ "lawyer.dashboard.notifications.title" | translate }}
    </button>
    <button mat-button class="tab" [class.active]="activeView === 'tasks'" (click)="view('tasks')">
      <mat-icon>task_alt</mat-icon>{{ "lawyer.dashboard.notifications.tasks" | translate }}
    </button>
  </div>
  <mat-paginator [length]="notificationPagination.total" [pageSize]="notificationPagination.pageSize"
    [hidePageSize]="true" (page)="onNotificationPagination($event)"
    [style.display]="activeView === 'notifications' ? null : 'none'"></mat-paginator>
  <mat-paginator [length]="eventsPagination.total" [pageSize]="eventsPagination.pageSize" [hidePageSize]="true"
    (page)="onEventsPagination($event)" [style.display]="activeView === 'tasks' ? null : 'none'"></mat-paginator>
</div>
<div class="notifications" [class.expanded]="expanded">
  <ng-container *ngIf="activeView === 'notifications'">
    <app-search-breadcrumbs [filters]="notificationFilters" (filtersChanged)="filtersChanged()"
      [availableFilters]="availableNotificationFilters"></app-search-breadcrumbs>
    <div *ngIf="service.notifications.length <= 0 && !loadingNotifications" class="info-text">
      {{ "lawyer.dashboard.notifications.noNewNotifications" | translate }}
    </div>
    <div class="loading-ind" *ngIf="loadingNotifications">
      <mat-spinner [diameter]="40"></mat-spinner>
      <span>{{ "common.label.loading" | translate }}</span>
    </div>
    <ng-scrollbar>
      <div class="notification" *ngFor="let notification of service.notifications"
        (click)="viewNotification(notification)" tabindex="0" (keydown.enter)="viewNotification(notification)">
        <mat-icon *ngIf="notification.type === 'NEW_MESSAGE'">email</mat-icon>
        <mat-icon *ngIf="
            notification.type === 'NEW_MENTION' &&
            notification?.meta?.questionType !== 'file'
          ">edit_square</mat-icon>
        <mat-icon *ngIf="
            notification.type === 'NEW_MENTION' &&
            notification?.meta?.questionType === 'file'
          ">upload</mat-icon>
        <div class="message-info">
          <span class="title">
            <ng-container *ngIf="notification.type === 'NEW_MESSAGE'">{{
              "lawyer.dashboard.notifications.newMessage"
              | translate: { sender: parseSender(notification) }
              }}</ng-container>
            <ng-container *ngIf="
                notification.type === 'NEW_MENTION' &&
                notification?.meta?.questionType !== 'form' &&
                notification?.meta?.questionType !== 'file'
              ">{{
              "lawyer.dashboard.notifications.newMention" | translate
              }}</ng-container>
            <ng-container *ngIf="
                notification.type === 'NEW_MENTION' &&
                notification?.meta?.questionType === 'form'
              ">{{
              "lawyer.dashboard.notifications.newForm" | translate
              }}</ng-container>
            <ng-container *ngIf="
                notification.type === 'NEW_MENTION' &&
                notification?.meta?.questionType === 'file'
              ">{{
              "lawyer.dashboard.notifications.fileRequested" | translate
              }}</ng-container>
          </span>
          <span>
            {{ notification.send_at }} in "{{ notification.stateName }}"</span>
        </div>
        <div class="message-actions">
          <button mat-icon-button (click)="$event.stopPropagation(); markAsRead(notification.id)" [matTooltip]="
              'lawyer.dashboard.notifications.markAsRead' | translate
            ">
            <mat-icon>close</mat-icon>
          </button>
        </div>
      </div>
    </ng-scrollbar>
  </ng-container>
  <ng-container *ngIf="activeView === 'tasks'">
    <app-search-breadcrumbs [filters]="taskFilters" [availableFilters]="availableTaskFilters"
      (filtersChanged)="getTasks()"></app-search-breadcrumbs>
    <div *ngIf="!tasks?.length && !loadingNotifications" class="info-text">
      {{ "lawyer.dashboard.notifications.noNewNotifications" | translate }}
    </div>
    <div class="loading-ind" *ngIf="loadingNotifications">
      <mat-spinner [diameter]="40"></mat-spinner>
      <span>{{ "common.label.loading" | translate }}</span>
    </div>
    <ng-scrollbar *ngIf="tasks.length">
      <div class="task" *ngFor="let task of tasks" (click)="viewNotification(task)" tabindex="0"
        (keydown.enter)="viewNotification(notification)">
        <div class="task-content">
          <div [innerHTML]="task.parsedContent | sanitizeHtml"></div>
          <span>In {{ task.stateName }}</span>
        </div>
        <div class="due-at" [class.warn]="!!task.dueAtWarning">
          <mat-icon *ngIf="task.dueAtWarning">
            warning
          </mat-icon>
          <ng-container *ngIf="task.dueAt">
            {{ task.dueAt | date: 'dd.MM.YYYY'}}
          </ng-container>
          <ng-container *ngIf="!task.dueAt">
            ---
          </ng-container>
        </div>
      </div>
    </ng-scrollbar>
  </ng-container>
</div>
<button class="expand-button" mat-button *ngIf="service.notifications.length > 0 && !loadingNotifications"
  (click)="expanded = !expanded">
  <mat-icon>expand_more</mat-icon>
</button>