/**
 * Silberfluss API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.10
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Role } from './role';
import { Variable } from './variable';


/**
 * A user of a legal process
 */
export interface User { 
    additional_info?: object;
    allowed_legal_processes?: Array<string>;
    auth_token?: string;
    can_auth?: boolean;
    created_at?: string;
    first_name?: string;
    guest?: boolean;
    id?: string;
    last_name?: string;
    mail?: string;
    owned_by?: string;
    roles?: Array<Role>;
    variables?: Array<Variable>;
}

