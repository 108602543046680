import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { token } from './token';
import { SettingInputData } from '../node-setting.component';
import { TextContent } from 'vanilla-jsoneditor';

@Component({
  selector: 'app-rich-json-input',
  template: `
    <div class="rich-json-edit" (pointerdown)="$event.stopPropagation()">
      <div class="rich-label">{{ data.logicRef.name | translate }}</div>
      <div class="json-editor-container" #jsonEditor></div>
    </div>
  `,
  styles: [],
})
export class RichJsonInputComponent implements AfterViewInit {
  @ViewChild('jsonEditor') jsonEditor: ElementRef<HTMLDivElement>;

  constructor(@Inject(token) public data: SettingInputData) {}

  ngAfterViewInit(): void {
    import('vanilla-jsoneditor').then(({ JSONEditor, Mode }) => {
      new JSONEditor({
        target: this.jsonEditor.nativeElement,
        props: {
          onChange: (content: TextContent) => {
            this.data.formControl.setValue(JSON.parse(content.text));
          },
          content: {
            json: this.data.logicRef.value.value,
          },
          mode: Mode.text,
          mainMenuBar: false,
        },
      });
    });
  }
}
