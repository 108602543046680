<div class="top-bar" [class.hide]="isEmbed" *ngIf="!activeComponents.length">
  <div class="logo-container" (click)="customLogo ? null : goHome()">
    <ng-container *ngIf="!customLogo; else styleLogo">
      <img src="assets/LogoIcon@1.svg" />
    </ng-container>
    <ng-template #styleLogo>
      <img [src]="customLogo" />
    </ng-template>
  </div>
  <div class="user-options">
    <app-profile-chip></app-profile-chip>
  </div>
</div>