import {
  Component,
  HostListener,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  KNOWN_OPERATORS,
  OutputNode,
  ProcessNode,
  QuestionNode,
  SketchParser,
} from 'advoprocess';
import {
  FormEntry,
  buildLayout,
} from 'advoprocess/lib/nodes/default-nodes/form.node';
import {
  QuestionConfig,
  QuestionType,
  getAvailableSettings,
  questionModes,
} from 'advoprocess/lib/types/question';
import { NodeContext } from '../../editor/node/node.component';
import { ProcessContext } from 'advoprocess/lib/parser/process-context';
import { sha256 } from 'js-sha256';
import * as _ from 'lodash';
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import { DialogService } from 'src/app/widgets/dialog/dialog.service';
import { NgScrollbar } from 'ngx-scrollbar';

const DEFAULT_QUESTION_TEMPLATE: (mode?: QuestionType) => QuestionConfig = (
  mode: QuestionType
) => ({
  questionText: { value: '<p>Question</p>' },
  questionType: { value: mode ?? 'string' },
  defaultValue: { value: '' },
  isOptional: { value: true },
  requiredFields: { value: [] },
  requiredFormat: { value: [] },
});

@Component({
  selector: 'app-form-editor-core',
  templateUrl: './form-editor-core.component.html',
  styleUrls: ['./form-editor-core.component.scss'],
})
export class FormEditorCoreComponent implements OnChanges {
  layout: FormEntry[][] = [];

  @Input() questions: FormEntry[] = [];
  @Input() dataSource: ProcessNode[] | undefined = undefined;

  @Input() hideToolbar: boolean = false;

  @ViewChild('editorScrollbar')
  editorScrollbar: NgScrollbar;

  editCell: FormEntry | undefined = undefined;

  questionModes = [
    ...questionModes,
    {
      name: 'node.names.output',
      value: 'form_output',
      icon: 'live_help',
    },
  ];

  editNode?: {
    node?: QuestionNode | OutputNode;
    field: FormEntry;
  } = undefined;

  nodeContext: NodeContext = {
    currentEditorLocation: undefined,
  };

  constructor(private dialog: DialogService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.questions) {
      this.layout = buildLayout(this.questions);
    }
  }

  deleteField(rowIndex: number, columnIndex: number) {
    this.layout[rowIndex].splice(columnIndex, 1);
    if (!this.layout[rowIndex].length) {
      this.layout.splice(rowIndex, 1);
    }
  }

  async editField(field: FormEntry) {
    const config = _.cloneDeep(field.question);
    Object.keys(config).forEach((key) => {
      config[key] = config[key].value;
    });
    let node: QuestionNode | OutputNode;
    if (field.question.questionType.value !== 'form_output') {
      node = SketchParser.buildNode('QuestionNode', config) as QuestionNode;
      node.refId = field.refId;
    } else {
      node = SketchParser.buildNode('OutputNode', {
        value: config.questionText,
        mode: 'info',
        attachments: config.attachments,
      }) as OutputNode;
    }
    if (!field.condition?.length) {
      field.condition = [[{ refId: '', operator: '=', value: '' }]];
    }
    node.ctx = {
      nodes: this.dataSource,
    } as ProcessContext;
    this.editNode = {
      node,
      field,
    };
  }

  saveNode() {
    if (!this.editNode) return;
    if (this.editNode.node && this.editNode.node instanceof QuestionNode) {
      this.editNode.field.refId = this.editNode.node.refId;
      this.editNode.field.question = this.editNode.node.config;
    } else if (this.editNode.node && this.editNode.node instanceof OutputNode) {
      this.editNode.field.question.questionText =
        this.editNode.node.config.value;
      this.editNode.field.question.attachments =
        this.editNode.node.config.attachments;
    }
    this.editNode.field.condition = this.editNode.field.condition
      .map((g) => g.filter((c) => c.refId?.length && c.operator && c.value))
      .filter((g) => g?.length);
    this.editNode = undefined;
  }

  async addField(row: number, mode: 'in' | 'after' = 'in'): Promise<void> {
    const refId = sha256(Date.now().toString()).substr(0, 6);
    if (mode === 'after') {
      this.layout.splice(row + 1, 0, [
        {
          question: DEFAULT_QUESTION_TEMPLATE(),
          refId,
        },
      ]);
    } else {
      this.layout[row].push({
        question: DEFAULT_QUESTION_TEMPLATE(),
        refId,
      });
    }
  }

  translateType(name: string): string {
    return this.questionModes.find((mode) => mode.value === name)?.name;
  }

  translateIcon(name: string): string {
    return this.questionModes.find((mode) => mode.value === name)?.icon;
  }

  drop(event: CdkDragDrop<FormEntry[]>) {
    const refId = sha256(Date.now().toString()).substr(0, 6);
    if (event.previousContainer.id === 'form-mode-toolbox') {
      const mode = event.previousContainer.data[event.previousIndex].value;
      event.container.data.splice(event.currentIndex, 0, {
        question: DEFAULT_QUESTION_TEMPLATE(mode),
        refId,
      });
      return;
    }
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
  }

  changeRefId(cell: FormEntry) {
    this.dialog.prompt('ID für diesen Knoten', { value: cell.refId }).then(
      (value) => {
        cell.refId = value?.replace(/[\s]*/gm, '') || cell.refId;
      },
      () => {}
    );
  }

  setMode(cell: FormEntry, mode: any) {
    cell.question.questionType.value = mode.value;
    if (cell.question.defaultValue) {
      cell.question.defaultValue.value =
        (this.questionModes.find((m) => m.value === mode.value) as any)
          ?.default ?? false;
    }
    getAvailableSettings(cell.question as QuestionConfig, () => {});
  }

  conditionOperations = {
    addCondition: () => {},
    saveCondition: (
      _: HTMLElement,
      b: {
        refId: string;
        operator: keyof typeof KNOWN_OPERATORS;
        value: any;
      }[][]
    ) => {
      this.editNode.field.condition = b;
    },
    previewById: (a: string, b: string) => {},
    removeCurrentCondition: () => {},
  };

  @HostListener('keydown', ['$event'])
  preventPropagation(event) {
    event.stopPropagation();
  }

  onFieldFocus(event: any, cell: FormEntry) {
    this.editCell = cell;
    const target = event.target as HTMLElement;
    const parent = target.closest('.field');
    setTimeout(() => {
      const newElement = parent.querySelector('.jodit-wysiwyg') as HTMLElement;
      if (!newElement) return;
      newElement.focus();
      // "Transfer" the click event to the new html element
      if (event.clientX && event.clientY) {
        const x = event.clientX;
        const y = event.clientY;
        try {
          let range;
          if ((document as any).caretPositionFromPoint) {
            const position = (document as any).caretPositionFromPoint(x, y);
            if (position) {
              range = document.createRange();
              range.setStart(position.offsetNode, position.offset);
              range.collapse(true);
            }
          } else if (document.caretRangeFromPoint) {
            range = document.caretRangeFromPoint(x, y);
          }

          if (range) {
            const selection = window.getSelection();
            selection.removeAllRanges();
            selection.addRange(range);
          }
        } catch {}
      }
    }, 50);
  }

  onNodeEditorSizeChange() {
    this.editorScrollbar.update();
  }

  deleteRow(row: FormEntry[]) {
    this.layout.splice(this.layout.indexOf(row), 1);
  }
}
