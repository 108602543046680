import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import {
  ChatMessage,
  getPendingFile,
  KNOWN_OPERATORS,
  parseFileReference,
  parseWithPlaceholders,
  __pendingFileUploadBuffer,
  toFileReference,
  removePendingFile,
  uploadFile,
  ProcessParser,
  applyComparisonToDataStore,
  ParsedAttachment,
  isUploadedFile,
} from 'advoprocess';
import { wrapInJWT } from 'advoprocess/lib/helpers/parser';
import {
  buildLayout,
  FormEntry,
} from 'advoprocess/lib/nodes/default-nodes/form.node';
import DataStore from 'advoprocess/lib/parser/data-store';
import {
  getValidatorForQuestion,
  parseQuestionConfig,
  QuestionConfig,
} from 'advoprocess/lib/types/question';
import dayjs from 'dayjs';
import * as _ from 'lodash';
import { FilesService } from 'src/api';
import { AuthService } from 'src/app/auth/auth.service';
import { ProcessService } from '../../process.service';
import { debounceTime, takeUntil } from 'rxjs';
import {
  FileOverlayDialogComponent,
  FileOverlayDialogConfig,
} from 'src/app/views/document/file-overlay-dialog/file-overlay-dialog.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DestroyNotifier } from '../../process-view/destroy-notifier';

type ExtendedFormEntry = FormEntry & {
  error?: boolean;
  message?: ChatMessage;
  isHeading?: boolean;
};

interface ExtendedFormEntryFields {
  highlightAsSource?: boolean;
  prefilledBy: 'ai' | 'user' | null;
  originalSourceString?: string;
  originalDocument?: ParsedAttachment;
}

@Component({
  selector: 'app-form-renderer',
  templateUrl: './form-renderer.component.html',
  styleUrls: ['./form-renderer.component.scss'],
})
export class FormRendererComponent
  extends DestroyNotifier
  implements OnChanges {
  @Input() fields: (FormEntry & ExtendedFormEntryFields)[];
  @Input() title: string;
  @Input() readOnly: boolean = false;
  @Input() hideContinue: boolean = false;
  @Input() showPosterBadge: boolean = false;

  @Input() service?: ProcessService;
  @Input() parser?: ProcessParser;
  @Input() previousDataStore?: DataStore;

  @Output() answered = new EventEmitter<any>();
  @Output() formStateChange = new EventEmitter<ExtendedFormEntry[]>();

  layout: ExtendedFormEntry[][] = [];

  fileRefs: (
    | string
    | {
      [key: string]: any;
    }
  )[] = [];

  fileData: File[] = [];

  get isValid(): boolean {
    return !this.layout.some((row, rowindex) =>
      row.some(
        (entry, colindex) =>
          this.shouldRenderQuestion(rowindex, colindex) && entry.error
      )
    );
  }

  uploadingFiles = false;

  initialTimeStamp = null;

  constructor(
    private auth: AuthService,
    private files: FilesService,
    private snackBar: MatSnackBar,
    private el: ElementRef,
    private matDialog: MatDialog
  ) {
    super();
    this.initialTimeStamp = dayjs().format('YYYY-MM-DDTHH:mm:ss');

    this.formStateChange.pipe(debounceTime(500)).subscribe(() => {
      this.calculateQuestionTexts();
      this.updateRenderQuestionsCache();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.fields) {
      this.layout = buildLayout(this.fields);
      _.flatten(this.layout).forEach(
        (field) => (field.message = this.buildMessage(field))
      );
      this.calculateQuestionTexts();

      this.preFillValues();
    }
    // If the form is readonly, all input elements should be disabled
    if (changes.readOnly) {
      setTimeout(() => {
        if (this.readOnly) {
          this.el.nativeElement.querySelectorAll('input').forEach((i) => {
            i.disabled = true;
            i.classList.add('__done_form_block_input');
          });
        } else {
          this.el.nativeElement
            .querySelectorAll('input.__done_form_block_input')
            .forEach((i) => {
              i.disabled = false;
              i.classList.remove('__done_form_block_input');
            });
        }
      }, 50);
    }
    if (changes.service) {
      this.service.highlightRefIdSource$
        .pipe(takeUntil(this.destroy$))
        .subscribe((refId) => {
          this.fields.forEach((f) => (f.highlightAsSource = false));
          const field = this.fields.find((f) => f.refId === refId);
          if (!field) return;
          field.highlightAsSource = true;
        });
    }
  }

  private preFillSingleValue(
    f: FormEntry & ExtendedFormEntryFields,
    existingValue: any
  ) {
    if (
      f.question.questionType?.value === 'file' &&
      !_.isArray(existingValue)
    ) {
      f.value = [existingValue];
    } else {
      f.value = existingValue;
    }
  }

  private preFillValues() {
    let filledAnything = false;
    this.fields.forEach((f) => {
      const refId = f.refId;
      const existingValue =
        this.parser?.thread?.dataStore?.get(refId) ?? undefined;
      if (!f.value && existingValue) {
        this.preFillSingleValue(f, existingValue);
        f.prefilledBy = 'user';
        filledAnything = true;
        return;
      }
      const store = this.parser?.thread?.dataStore;
      if (!!store) {
        const aiValues = store.keys.filter((k) => store.type(k) === 'analysis');
        for (const key of aiValues) {
          let data = store.get(key);
          let meta = store.meta(key);
          if (typeof data === 'string') {
            try {
              data = JSON.parse(data);
            } catch { }
          }
          if (_.isObject(data)) {
            for (const aiKey of Object.keys(data)) {
              if (aiKey !== refId) continue;
              let existingValue = data[aiKey] ?? undefined;
              if (!f.value && !!existingValue?.value) {
                let v = existingValue?.value;
                let source = existingValue?.source;
                let sourceDocumentName = existingValue?.sourceDocumentName;
                if (f.question.questionType.value === 'number') {
                  try {
                    v = parseFloat(v);
                  } catch { }
                }
                this.preFillSingleValue(f, v);
                const foundAttachment = meta?.documents?.find(
                  (d: ParsedAttachment) => d.name === sourceDocumentName
                );
                if (foundAttachment) {
                  f.originalDocument = foundAttachment;
                  f.originalSourceString = source;
                }
                f.prefilledBy = 'ai';
                filledAnything = true;
              }
            }
          }
        }
        const mandatsaufnahmeValues = store.get('mandatsaufnahme');
        if (mandatsaufnahmeValues) {
          let existingValue =
            mandatsaufnahmeValues?.[refId]?.data?.[0] ?? undefined;
          if (!f.value && !!existingValue) {
            this.preFillSingleValue(f, existingValue);
            f.prefilledBy = 'user';
            filledAnything = true;
          }
        }
      }
    });
    if (filledAnything) {
      this.emitStateChange();
    }
  }

  buildMessage(field: ExtendedFormEntry): ChatMessage {
    if (!field.instanceTime) {
      field.instanceTime = dayjs().format('YYYY-MM-DDTHH:mm:ss');
    }
    const config = field.question;
    const { params, parsedQuestion } = parseQuestionConfig(
      config as unknown as QuestionConfig,
      new DataStore({})
    );

    const typeTranslation: { [key: string]: string } = {
      string: 'word',
      number: 'number',
      dropdown: 'buttons',
    };

    const type =
      typeTranslation[config.questionType?.value as string] ||
      config.questionType?.value ||
      'word';

    let data: ChatMessage;

    switch (config?.questionType?.value) {
      case 'form_output':
        data = {
          content: parsedQuestion.body.innerHTML,
          sender: 'bot',
          format: 'plain',
          timestamp: field.instanceTime,
          id: '',
        };
        break;
      default:
        data = {
          id: '',
          format: 'default',
          sender: 'bot',
          title: parsedQuestion.body.innerHTML,
          content: parsedQuestion.body.innerHTML,
          timestamp: field.instanceTime,
          responseRequest: {
            params,
            type,
            validator:
              getValidatorForQuestion(config as QuestionConfig) ?? undefined,
            placeholder:
              type !== 'buttons'
                ? config.defaultValue?.value?.toString()
                : undefined,
          },
        };
        break;
    }

    return data;
  }

  private calculateQuestionTexts() {
    const dataStore = buildFormTempDataStore(
      this.layout,
      this.parser?.thread?.dataStore
    );
    _.flatten(this.layout).forEach((field) => {
      const wrapped = wrapInJWT(
        parseWithPlaceholders(
          field.question.questionText.value,
          dataStore,
          ' '
        ),
        this.auth.jwtToken$.value
      );
      if (
        wrapped.querySelectorAll('h2').length &&
        !wrapped.querySelectorAll('p').length
      ) {
        field.isHeading = true;
      }
      field.message.title = field.message.content = wrapped.body.innerHTML;
    });
  }

  fieldEntered(field: ExtendedFormEntry, value: any) {
    if (['dropdown', 'check'].includes(field?.question.questionType?.value)) {
      if (_.isArray(value)) {
        value = value.map((r) => r.value ?? r.text);
      }
      if (_.isObject(value) && (value as any)?.text) {
        value = (value as any)?.value ?? (value as any)?.text;
      }
    }
    field.value = value;
    this.emitStateChange();
  }

  async continue() {
    if (!this.isValid) {
      return;
    }
    try {
      this.uploadingFiles = true;
      for (let row = 0; row < this.layout.length; row++) {
        for (let column = 0; column < this.layout[row].length; column++) {
          const field = this.layout[row][column];
          if (
            field.message?.responseRequest?.type === 'file' &&
            field?.value?.length &&
            this.shouldRenderQuestion(row, column)
          ) {
            await this.saveFile(field);
          }
          field.value =
            !this.shouldRenderQuestion(row, column) || _.isNil(field.value)
              ? ''
              : field.value;
        }
      }
      this.uploadingFiles = false;
    } catch (err) {
      this.uploadingFiles = false;
      console.error(err);
      return;
    }
    const aE: any = document.activeElement;
    if (aE?.blur) {
      aE.blur();
    }
    this.answered.emit();
  }

  private async saveFile(field: ExtendedFormEntry) {
    for (let i = 0; i < field.value.length; i++) {
      const fileRef = parseFileReference(field.value[i]);
      this.fileRefs.push(fileRef);
      if (!fileRef || typeof fileRef === 'string') {
        return;
      }
      if (!fileRef.id.match(/^<<<PENDING::(.*)>>>$/m)) {
        return;
      }
      let file = getPendingFile(fileRef.id)?.file as File;
      if (!(file instanceof File)) {
        // How could this have happened? This shouldn't happen at all 🤔
        throw new Error("Can't upload a generated document using a Form");
      }
      this.fileData.push(file);
      if (!file) {
        const text = parseWithPlaceholders(
          field.question?.questionText?.value,
          buildFormTempDataStore(this.layout, this.parser?.thread?.dataStore)
        );
        this.snackBar.open(
          `Es gab ein Problem beim Upload der Datei: ${text.body?.innerText ?? 'Unbekannt'
          }. Bitte überprüfen Sie die Datei und fügen Sie im Zweifelsfall erneut hinzu.`
        );
        throw new Error('Pending file not found');
      }
      const path =
        field.message.responseRequest?.params?.targetFilePath ?? undefined;
      if (this.auth.loggedIn) {
        await new Promise<void>((resolve, reject) => {
          uploadFile(
            file,
            this.service.stateId,
            {
              path,
              nodeid: this.parser?.visitor?.currentNode?._id,
              threadid: this.parser?.thread.id,
            },
            this.files
          )
            .then((fileIdentifierObject) => {
              const value = toFileReference(
                file,
                fileIdentifierObject.file_identifier,
                path,
                this.parser?.thread.id,
                this.parser?.visitor.currentNode._id
              );
              field.value[i] = value;
              removePendingFile(fileRef.id.match(/<<<PENDING::(.*)>>>/m)?.[1]);
              resolve();
            })
            .catch((error) => {
              if (error.file_identifier) {
                const value = toFileReference(
                  file,
                  error.file_identifier,
                  path,
                  this.parser?.thread.id,
                  this.parser?.visitor.currentNode._id
                );
                field.value = value;
                removePendingFile(
                  fileRef.id.match(/<<<PENDING::(.*)>>>/m)?.[1]
                );
                resolve();
              } else {
                const text = parseWithPlaceholders(
                  field.question?.questionText?.value,
                  buildFormTempDataStore(this.layout, this.parser?.dataStore)
                );
                this.snackBar.open(
                  `Es gab ein Problem beim Upload der Datei: ${text.body?.innerText ?? 'Unbekannt'
                  }. Bitte überprüfen Sie die Datei und fügen Sie im Zweifelsfall erneut hinzu.`
                );
                throw new Error(error);
              }
            });
        });
      } else {
        const value = toFileReference(
          file,
          undefined,
          path,
          this.parser?.thread.id,
          this.parser?.visitor.currentNode._id
        );
        field.value[i] = value;
        removePendingFile(fileRef.id.match(/<<<PENDING::(.*)>>>/m)?.[1]);
      }
    }
  }

  emitStateChange() {
    const intermediateState = _.flatten(this.layout).map((field) => {
      return { ...field, value: _.isNil(field.value) ? '' : field.value };
    });
    this.formStateChange.emit(intermediateState);
  }

  renderQuestionsCache: boolean[][] | undefined = undefined;

  shouldRenderQuestion(row: number, column: number): boolean {
    if (this.renderQuestionsCache?.[row]?.[column] === undefined) {
      this.updateRenderQuestionsCache();
    }
    return this.renderQuestionsCache[row][column];
  }

  updateRenderQuestionsCache() {
    this.renderQuestionsCache = createRenderQuestionsCache(
      this.layout,
      this.previousDataStore ?? this.parser?.thread?.dataStore
    );
  }

  viewPrefillSource(field: FormEntry & ExtendedFormEntryFields) {
    if (!field.originalDocument) return;
    if (!isUploadedFile(field.originalDocument) || !field.originalDocument?.id) return;
    this.matDialog.open<FileOverlayDialogComponent, FileOverlayDialogConfig>(
      FileOverlayDialogComponent,
      {
        panelClass: 'slide-in-dialog',
        position: {
          right: '0',
        },
        data: {
          file: {
            ...field.originalDocument,
            uuid: field.originalDocument.id,
            size: field.originalDocument?.size?.toString() ?? '0',
          },
          highlight: field.originalSourceString,
          service: this.service,
        },
      }
    );
  }
}

export function buildFormTempDataStore(
  layout: ExtendedFormEntry[][],
  previousMessage?: DataStore
): DataStore {
  const message = new DataStore();
  message.parseFrom(_.cloneDeep(previousMessage?.toJSON() ?? {}));
  _.flatten(layout).forEach((entry) => {
    message.set(entry.refId, entry.value);
  });
  return message;
}

export function createRenderQuestionsCache(
  layout: ExtendedFormEntry[][],
  dataStore: DataStore
) {
  return layout.map((row) => {
    return row.map((field) => {
      if (!field.condition?.length) {
        return true;
      }
      const message = buildFormTempDataStore(layout, dataStore);
      return field.condition.some(
        (
          conditiongroup: {
            refId: string;
            operator: keyof typeof KNOWN_OPERATORS;
            value: any;
          }[]
        ) => {
          return conditiongroup.every((condition) => {
            if (!condition?.refId?.length) return true;
            return applyComparisonToDataStore(
              condition.refId,
              condition.operator,
              condition.value,
              message,
              []
            );
          });
        }
      );
    });
  });
}
