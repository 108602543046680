/**
 * Silberfluss API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.10
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ProcessStep } from './processStep';


/**
 * Fields of an execution state that are set automatically
 */
export interface AdditionaleExecutionStateFields { 
    createdAt?: string;
    id: string;
    initial_processid?: string;
    steps?: Array<Array<ProcessStep>>;
    sync_id?: string;
    updatedAt?: string;
    version: string;
    widgets?: object;
}

