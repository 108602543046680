<app-poster-badge *ngIf="showPosterBadge" [sender]="'bot'" [service]="service"></app-poster-badge>
<form [class.done]="readOnly">
  <h2 *ngIf="title">{{ title }}</h2>
  <div class="row" *ngFor="let row of layout; let i = index">
    <ng-container *ngFor="let field of row; let j = index">
      <div class="cell" *ngIf="field?.message && shouldRenderQuestion(i, j)"
        [class.highlighted]="field?.highlightAsSource">
        <div class="static-output" [class.heading]="field.isHeading" *ngIf="field.message.format === 'plain'">
          <mat-icon> info </mat-icon>
          <div [innerHTML]="field.message.content | sanitizeHtml"></div>
        </div>
        <app-question-answers (answered)="fieldEntered(field, $event)" (hasError)="field.error = $event" [inline]="true"
          [parser]="parser" [message]="field.message" [initialValue]="field.value || undefined"
          *ngIf="field.message.format !== 'plain'" [attr.filledby]="field.prefilledBy"></app-question-answers>
        <button mat-icon-button type="button" class="source-button"
          *ngIf="field.prefilledBy === 'ai' && field.originalDocument" (click)="viewPrefillSource(field)"
          [matTooltip]="'common.button.showSource' | translate">
          <mat-icon>plagiarism</mat-icon>
        </button>
      </div>
    </ng-container>
  </div>
  <button mat-raised-button color="primary" (click)="continue()" [disabled]="!isValid"
    *ngIf="!readOnly && !hideContinue">
    {{ "common.button.continue" | translate }}
  </button>
  <mat-spinner [diameter]="20" style="margin: 10px auto" *ngIf="uploadingFiles"></mat-spinner>
</form>