<!-- <div class="settings-categories">
  <div
    class="setting-selector"
    *ngFor="let group of config"
    [class.active]="foucsGroup === group"
  >
    <mat-icon>{{ group.icon }}</mat-icon>
    {{ group.title | translate }}
  </div>
</div> -->
<div class="settings-content">
  <ng-scrollbar #scrollBar>
    <h1>{{ "lawyer.dashboard.settings.title" | translate }}</h1>
    <div class="settings-group" *ngFor="let group of config">
      <h2>
        <mat-icon>{{ group.icon }}</mat-icon>{{ group.title | translate }}
      </h2>
      <div class="settings">
        <div class="setting" *ngFor="let setting of group.settings">
          <div class="left">
            <h3>
              <mat-icon *ngIf="setting.icon">{{ setting.icon }}</mat-icon>{{ setting.name | translate }}
              <beta-hint *ngIf="setting?.beta"></beta-hint>
            </h3>
            <p *ngIf="setting.description" [innerHTML]="setting.description | translate"></p>
          </div>
          <div class="right" [class.broad]="setting.type === 'text' && setting.multiline">
            <ng-container *ngIf="setting.type === 'text'">
              <mat-form-field appearance="fill" *ngIf="!setting.multiline">
                <mat-label>{{ setting.name | translate }}</mat-label>
                <input matInput type="text" [value]="setting.value" (blur)="saveField(setting, $event.target.value)" />
              </mat-form-field>
              <app-rich-setting-editor *ngIf="setting.multiline" (valueChanged)="saveField(setting, $event)"
                [value]="setting.value"></app-rich-setting-editor>
            </ng-container>
            <ng-container *ngIf="setting.type === 'button'">
              <button mat-raised-button color="primary" (click)="setting.onChange(null)">
                {{ setting.name | translate }}
              </button>
            </ng-container>
            <ng-container *ngIf="setting.type === 'image'">
              <div class="logo" [class.empty]="!setting.value" (click)="imageFileInput.click()">
                <ng-container *ngIf="!setting.value">
                  <mat-icon>image</mat-icon>
                  <span translate>process.label.uploadFile</span>
                </ng-container>
                <ng-container *ngIf="setting.value">
                  <img [src]="setting.value" />
                </ng-container>
                <input type="file" accept="image/png,image/jpeg" #imageFileInput
                  (change)="setImageFile(setting, $event)" />
              </div>
            </ng-container>
            <ng-container *ngIf="setting.type === 'color'">
              <input [(colorPicker)]="setting.value" [style.background]="setting.value" [value]="setting.value"
                [class.lightText]="useLightText(setting.value)" readonly cpPosition="top" cpAlphaChannel="disabled"
                (colorPickerSelect)="updateColor(setting, $event)" class="colorpicker" />
            </ng-container>
            <ng-container *ngIf="setting.type === 'dropdown'">
              <mat-form-field appearance="fill">
                <mat-label>{{ setting.name | translate }}</mat-label>
                <mat-select [value]="setting.value" (selectionChange)="saveField(setting, $event.value)">
                  <mat-select-trigger>
                    <mat-icon *ngIf="findOption(setting)?.icon">{{
                      findOption(setting)?.icon
                      }}</mat-icon>&nbsp;{{ findOption(setting)?.label | translate }}
                  </mat-select-trigger>
                  <mat-option *ngFor="let option of setting.options" [value]="option.value">
                    <mat-icon *ngIf="option.icon">{{ option.icon }}</mat-icon>
                    {{ option.label | translate }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </ng-scrollbar>
</div>