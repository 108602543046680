import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MenuEntry } from 'advoprocess/lib/types/menu';
import { Observable, Subject, combineLatest, of, timer } from 'rxjs';
import { map, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { ExecutionService, FileInfo, FilesService } from 'src/api';
import { ProcessService } from 'src/app/views/process/process.service';
import {
  FileAttachment,
  ProcessNode,
  exportOptions,
  parseFileReference,
  toFileReference,
} from 'advoprocess';
import { filterTerm } from 'src/app/common/helpers';
import { DialogService } from '../dialog/dialog.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import {
  DossierExportDialogComponent,
  DossierExportDialogData,
} from '../../common/dossier-export-dialog/dossier-export-dialog.component';

type StaticFileSelectOptions =
  | 'request_link_input'
  | 'request_upload'
  | 'create_document';

@Component({
  selector: 'app-attachment-select-menu',
  templateUrl: './attachment-select-menu.component.html',
  styleUrls: ['./attachment-select-menu.component.scss'],
})
export class AttachmentSelectMenuComponent {
  @Output() selected = new EventEmitter<
    FileAttachment & { can_write?: boolean }
  >();
  @Input() processService?: ProcessService;
  @Input() validSources: ('files' | 'exports' | 'static' | 'refids')[] = [
    'files',
    'exports',
    'static',
    'refids',
  ];
  @Input() nodeList?: ProcessNode[];

  abortQuery$ = new Subject<void>();
  lastResults: [
    MenuEntry<FileAttachment | StaticFileSelectOptions>[],
    MenuEntry<FileAttachment | StaticFileSelectOptions>[],
    MenuEntry<FileAttachment | StaticFileSelectOptions>[],
    MenuEntry<StaticFileSelectOptions>[]
  ] = [[], [], [], []];

  allFiles: FileInfo[] = undefined;

  constructor(
    private translator: TranslateService,
    private stateAPI: ExecutionService,
    private fileAPI: FilesService,
    private dialog: DialogService,
    private matDialog: MatDialog
  ) {}

  queryAllAttachments(
    searchTerm: string
  ): Observable<MenuEntry<FileAttachment | StaticFileSelectOptions>[]> {
    this.abortQuery$.next();
    const allSources = [
      this.getFileAttachments(searchTerm),
      this.getExportOptions(searchTerm),
      this.getStaticFileOptions(searchTerm),
      this.getFileRefIds(searchTerm),
    ].map((obs, i) =>
      timer(1000).pipe(
        switchMap(() => obs),
        tap((x) => (this.lastResults[i] = x)),
        startWith(
          this.lastResults[i].filter((e) =>
            filterTerm(searchTerm, e, this.translator)
          )
        ),
        takeUntil(this.abortQuery$)
      )
    );
    return combineLatest(allSources).pipe(map((results) => _.flatten(results)));
  }

  private getFileAttachments(
    searchTerm: string
  ): Observable<MenuEntry<FileAttachment>[]> {
    if (!this.validSources.includes('files')) return of([]);
    let fileSource: Observable<FileInfo[]>;
    if (this.processService) {
      fileSource = this.stateAPI.getStateFiles({
        stateid: this.processService.stateId,
      });
    } else {
      fileSource = of([]); // ToDo
    }
    const filterFiles = (files: FileInfo[]) => {
      return files
        .map((f) => {
          const value: FileAttachment & { can_write: boolean } = {
            name: f.name,
            id: f.uuid,
            type: 'upload',
            mime: f.mime ?? 'generated',
            size: wrapException(() => parseInt(f.size), null),
            can_write: f.can_write,
          };
          return {
            name: f.name,
            icon: 'insert_drive_file',
            value,
            group: {
              name: this.translator.instant('process.label.files'),
              icon: 'folder',
            },
          };
        })
        .filter((f) => filterTerm(searchTerm, f, this.translator));
    };
    if (!this.allFiles) {
      return fileSource.pipe(
        tap((files) => {
          this.allFiles = files.filter((f) => !f.is_folder);
        }),
        map((files) => {
          return filterFiles(files.filter((f) => !f.is_folder));
        })
      );
    }
    return of(filterFiles(this.allFiles));
  }

  private getFileRefIds(
    searchTerm: string
  ): Observable<MenuEntry<FileAttachment>[]> {
    if (!this.validSources.includes('refids')) return of([]);
    if (!this.nodeList?.length) {
      return of([]);
    }
    return of(
      _.flatten(
        this.nodeList
          .filter((n) => n?.node?.refIds?.length)
          .map((n) => n.node.refIds)
      )
        .map((n): MenuEntry<FileAttachment> => {
          return {
            name: `#${n}`,
            icon: 'tag',
            group: {
              name: this.translator.instant(
                'node.names.mailAttachments.refIds'
              ),
              icon: 'tag',
            },
            value: {
              type: 'refid',
              id: n,
              name: `#${n}`,
              format: 'default',
            },
          };
        })
        .filter((m) => filterTerm(searchTerm, m, this.translator))
        .slice(0, 5)
    );
  }

  private getExportOptions(
    searchTerm: string
  ): Observable<MenuEntry<FileAttachment>[]> {
    if (!this.validSources.includes('exports')) return of([]);
    return of(
      exportOptions
        .map((option) => {
          const value: FileAttachment = {
            type: 'export',
            format: option.id,
            name: option.buildName(),
            id: option.id,
          };
          return {
            name: this.translator.instant(option.label),
            icon: option.icon,
            value,
            group: {
              name: this.translator.instant(
                'node.names.mailAttachments.miscFiles'
              ),
              icon: 'import_export',
            },
          };
        })
        .filter((entry) => filterTerm(searchTerm, entry, this.translator))
    );
  }

  private getStaticFileOptions(
    searchTerm: string
  ): Observable<MenuEntry<StaticFileSelectOptions>[]> {
    if (!this.validSources.includes('static')) return of([]);
    return of(
      (
        [
          {
            name: this.translator.instant('process.label.uploadFile'),
            icon: 'file_upload',
            value: 'request_upload',
          },
          // {
          //   name: this.translator.instant('process.label.uploadFromLink'),
          //   icon: 'link',
          //   value: 'request_link_input',
          // },
          ...(this.processService
            ? [
                {
                  name: this.translator.instant('process.label.createFile'),
                  icon: 'note_add',
                  value: 'create_document',
                },
              ]
            : []),
        ] as ReadonlyArray<MenuEntry<StaticFileSelectOptions>>
      )
        .map((e) => ({
          ...e,
          group: {
            name: this.translator.instant(
              'node.names.mailAttachments.externalFiles'
            ),
            icon: 'file_upload',
          },
        }))
        .filter((e) => filterTerm(searchTerm, e, this.translator))
    );
  }

  selectAttachment(event: MenuEntry<FileAttachment | StaticFileSelectOptions>) {
    if (event.value === 'request_upload') {
      this.dialog
        .promptFile('Datei', {
          stateId: this.processService?.stateId ?? null,
          isPublic: !this.processService,
        })
        .then(
          (newFile) => {
            this.selected.emit({ ...newFile, can_write: true });
          },
          () => {}
        );
      return;
    }
    if (event.value === 'create_document') {
      this.dialog
        .promptDocument({
          defaultDocumentName: this.translator.instant(
            'process.files.defaultDocumentAttachmentName'
          ),
          defaultPath: this.translator.instant(
            'process.files.defaultDocumentAttachmentPath'
          ),
        })
        .then((newDocument) => {
          if (!newDocument) return;
          const fileReference = toFileReference(
            newDocument,
            null,
            newDocument.path,
            this.processService.parser.thread.id
          );
          const parsed = parseFileReference(fileReference);
          if (typeof parsed === 'string') return;
          this.selected.emit({
            id: parsed.id,
            name: newDocument.name ?? '',
            type: 'upload',
            size: 0,
            mime: 'generated',
            can_write: true,
          });
        });

      return;
    }
    if (event.value === 'request_link_input') {
      // ToDo: Accept link input
      return;
    }
    if (event.value?.type === 'export') {
      this.matDialog
        .open<DossierExportDialogComponent, DossierExportDialogData>(
          DossierExportDialogComponent,
          {
            data: {},
          }
        )
        .afterClosed()
        .subscribe((result) => {
          const newAttachment: FileAttachment = {
            ...(event.value as FileAttachment),
            name: result.exportName,
            exportParameters: result.includes ?? [],
          };
          this.selected.emit(newAttachment);
        });
      return;
    }
    if (event.value?.id && event.value.name) {
      this.selected.emit(event.value);
    }
  }
}

function wrapException<T>(func: Function, defaultValue: T): T {
  try {
    return func();
  } catch {
    return defaultValue;
  }
}
