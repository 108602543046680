export const PROTOTYPE_NODE_TEMPLATE = {
  data: [
    {
      id: '0619e8a47eeb1e63147fd708227db2a268fabf9af9e213b5bfc9e8f45ae49aad',
      type: 'StartNode',
      outputs: [
        'b8df2755bf7ab9ad168a6bc04e4a4bdd5e3a31bb9a8a48cd407577c32c882d75',
      ],
      _sketch: {
        x: 5030,
        y: 4811.333312988281,
      },
      properties: {},
      configCookies: {},
    },
    {
      id: 'b8df2755bf7ab9ad168a6bc04e4a4bdd5e3a31bb9a8a48cd407577c32c882d75',
      type: 'SubProcessNode',
      outputs: [null],
      assigned: [],
      subs: {
        list: [
          {
            id: 0,
            alias: 'mandant',
            assigned: [`<<[MAGIC:CLIENT]::{"permission":"write"}>>`],
            x: 19,
            y: 9,
          },
          {
            id: 1,
            alias: 'assistent',
            assigned: [],
            x: 59,
            y: 9,
          },
        ],
        requires: 'never',
      },
      start: true,
      _sketch: {
        x: 4899.333251953125,
        y: 4926.000152587891,
      },
      properties: {},
      configCookies: {},
    },
  ],
  info: {
    name: 'Prototype Node Template',
    lastSaved: '1716214299794',
    version: '1.0.1',
  },
  subs: [
    {
      data: [
        {
          id: '0619e8a47eeb1e63147fd708227db2a268fabf9af9e213b5bfc9e8f45ae49aad',
          type: 'StartNode',
          outputs: [
            'b8df2755bf7ab9ad168a6bc04e4a4bdd5e3a31bb9a8a48cd407577c32c882d75',
          ],
          _sketch: {
            x: 5030,
            y: 4811.333312988281,
          },
          properties: {},
          configCookies: {},
        },
      ],
      id: 0,
      info: {
        name: 'Chat mit Mandanten',
      },
    },
    {
      data: [
        {
          id: '0619e8a47eeb1e63147fd708227db2a268fabf9af9e213b5bfc9e8f45ae49aad',
          type: 'StartNode',
          outputs: [
            'b8df2755bf7ab9ad168a6bc04e4a4bdd5e3a31bb9a8a48cd407577c32c882d75',
          ],
          _sketch: {
            x: 5030,
            y: 4811.333312988281,
          },
          properties: {},
          configCookies: {},
        },
      ],
      id: 1,
      info: {
        name: 'Assistent',
      },
    },
  ],
};
