import { AvailableFilter } from 'advoprocess/lib/types/filter';
import { lawyersFilters } from './lawyers-filters';
import { processFilters } from './process-filters';
import { clientsFilters } from './clients-filters';
import { Injector } from '@angular/core';
import { TemplatesService } from 'src/api';
import { map, of } from 'rxjs';
import { MenuEntry } from 'advoprocess/lib/types/menu';
import _ from 'lodash';
import { filterTerm } from 'src/app/common/helpers';
import { TranslateService } from '@ngx-translate/core';

export const executionStateFilters: AvailableFilter[] = [
  {
    id: 'state_name',
    label: 'process.filter.label.file_name',
    internal_name: 'file_name',
    icon: 'drive_file_rename_outline',
    type: 'string',
  },
  {
    id: 'state_updated_at',
    label: 'process.filter.label.updated_at',
    internal_name: 'updated_at',
    icon: 'history',
    type: 'date',
  },
  {
    id: 'state_created_at',
    label: 'process.filter.label.created_at',
    internal_name: 'created_at',
    icon: 'today',
    type: 'date',
  },
  {
    id: 'state_sync_id',
    label: 'process.filter.label.sync_id',
    internal_name: 'extern_id',
    icon: 'bookmark',
    type: 'string',
  },
  {
    label: 'process.filter.label.closed',
    type: 'boolean',
    id: 'state_closed',
    options: [
      {
        label: 'process.filter.label.closed',
        value: true,
      },
      {
        label: 'process.filter.label.notClosed',
        value: false,
      },
    ],
    internal_name: (value: string, params: { [key: string]: string }) => {
      return [
        {
          operand: 'closed',
          label: 'process.filter.label.closed',
          operator: 'eq',
          value,
        },
      ];
    },
    icon: 'archive',
  },
  {
    id: 'state_sync_id',
    label: 'process.filter.label.id',
    internal_name: 'id',
    icon: 'tag',
    type: 'string',
  },
  {
    id: 'state_client_full_name',
    label: 'process.filter.label.clientName',
    internal_name: 'client_roles.client.full_name',
    icon: 'label',
    type: 'string',
  },
  {
    id: 'state_lawyers',
    label: 'process.filter.label.lawyers.title',
    internal_name: 'lawyer_roles.lawyer',
    type: 'entity',
    icon: 'face',
    children: lawyersFilters,
  },
  {
    id: 'state_processes',
    label: 'process.filter.label.legal_processes.title',
    internal_name: 'legal_processes',
    icon: 'account_tree',
    children: processFilters,
  },
  {
    id: 'state_variables',
    label: (params) => {
      if (params?.name) {
        return `${params.name}`;
      } else {
        return 'process.filter.label.variable';
      }
    },
    icon: 'tag',
    type: 'string',
    fetch: (searchTerm: string, injector: Injector) => {
      const templatesService = injector.get(TemplatesService);
      const translator = injector.get(TranslateService);
      return templatesService
        .listTemplates({
          filterViewPagination: {
            filter: [
              {
                operand: 'type',
                operator: 'eq',
                value: 'node',
              },
              {
                operand: 'definition.type',
                operator: 'eq',
                value: 'FormNode',
              },
            ],
            pagination: {
              page: 1,
              rows_per_page: 5,
            },
            view: {
              displayed_columns: [],
              hidden_columns: [
                {
                  display_name: 'id',
                  internal_name: 'id',
                },
                {
                  display_name: 'name',
                  internal_name: 'name',
                },
                {
                  display_name: 'fieldNames',
                  internal_name: 'definition.config.questions.refId',
                },
              ],
            },
          },
        })
        .pipe(
          map((result) => {
            return _.flatten(
              result.templates.map(
                (t): AvailableFilter =>
                  (t as any).fieldNames?.map(
                    (refId: string): AvailableFilter => {
                      return {
                        id: `state_filter_custom_${refId}`,
                        internal_name: `variables(name eq ${refId}).value.-1`,
                        label: refId,
                        type: 'string',
                        icon: 'tag',
                      };
                    }
                  )
              )
            )
              .filter((f) =>
                filterTerm(searchTerm, { name: f.label as string }, translator)
              )
              .slice(0, 10);
          })
        );
    },
    parameters: [
      {
        label: 'process.filter.label.variableName',
        name: 'name',
      },
    ],
    internal_name: (value: string, params: { [key: string]: string }) => {
      const split = params?.name?.split(/>/gm) ?? [''];
      return [
        {
          operand: `variables(name eq ${split[0]}).value.-1${
            split.length > 1 ? `.${split.slice(1).join('.')}` : ''
          }`,
          label: `${params.name}`,
          operator: 'contains',
          value,
        },
      ];
    },
    grouping_clause: (value, params) => {
      const split = params?.name?.split(/>/gm) ?? [''];
      return {
        property_name_1: `variables(name eq ${split[0]}).value.-1${
          split.length > 1 ? `.${split.slice(1).join('.')}` : ''
        }`,
        label: `${params.name}`,
      };
    },
  },
];
