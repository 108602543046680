import { animate, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { sha256 } from 'js-sha256';
import { Observable, from, of } from 'rxjs';
import {
  catchError,
  delay,
  distinctUntilChanged,
  filter,
  map,
  startWith,
  switchMap,
  takeUntil,
} from 'rxjs/operators';
import {
  Lawyer,
  LawyerRequest,
  LawyerService,
  Permission,
  PermissionPolicy,
  Role,
  RoleRequest,
  RolesService,
} from 'src/api';
import { ClientsService } from 'src/api/api/clients.service';
import { PermissionsService as APIPermissionsService } from 'src/api/api/permissions.service';
import { User } from 'src/api/model/user';
import { AuthService } from 'src/app/auth/auth.service';
import { buildErrorString, filterTerm } from 'src/app/common/helpers';
import { EditUserDefinition } from 'src/app/common/profile-edit/profile-edit.component';
import { clientsFilters } from 'src/app/views/process/filters/clients-filters';
import { groupsFilter } from 'src/app/views/process/filters/groups-filters';
import { lawyersFilters } from 'src/app/views/process/filters/lawyers-filters';
import { DestroyNotifier } from 'src/app/views/process/process-view/destroy-notifier';
import { DialogService } from 'src/app/widgets/dialog/dialog.service';
import {
  TableComponent,
  TableConfig,
} from 'src/app/widgets/table/table.component';
import * as _ from 'lodash';
import { HttpErrorResponse } from '@angular/common/http';
import {
  applyFiltersToModel,
  isPermission,
  userDecisionHandler,
} from 'src/app/auth/permission-helpers';
import { PermissionsService } from 'src/app/auth/permissions.service';
import { MenuEntry } from 'advoprocess/lib/types/menu';
import { SettingsService } from 'src/app/common/settings.service';

type ClientsGroupOptions = 'clients' | 'lawyers' | 'groups';

@Component({
  selector: 'app-clients-overview',
  templateUrl: './clients-overview.component.html',
  styleUrls: ['./clients-overview.component.scss'],
  animations: [
    trigger('fadeAnimation', [
      transition(':enter', [
        style({ opacity: 0, transform: 'scale(0.8)' }),
        animate('150ms', style({ opacity: 1, transform: 'scale(1)' })),
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'scale(1)' }),
        animate('150ms', style({ opacity: 0, transform: 'scale(0.8)' })),
      ]),
    ]),
  ],
})
export class ClientsOverviewComponent extends DestroyNotifier {
  externalTableConfig: TableConfig<User>;
  internalTableConfig: TableConfig<User>;
  groupTableConfig: TableConfig<Role>;

  @Output() triggerExport = new EventEmitter<void>();

  @ViewChild('tableRef') tableRef: TableComponent;

  activeView: ClientsGroupOptions = 'clients';

  detailUser: EditUserDefinition | undefined = undefined;

  constructor(
    private clientAPI: ClientsService,
    private lawyerAPI: LawyerService,
    private roleAPI: RolesService,
    private snackBar: MatSnackBar,
    private dialog: DialogService,
    private auth: AuthService,
    private translator: TranslateService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private permissions: PermissionsService,
    private permissionAPI: APIPermissionsService,
    private settings: SettingsService
  ) {
    super();
    this.initializeData();
    this.router.events
      .pipe(
        takeUntil(this.destroy$),
        delay(50),
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          return this.getCurrentDetailUser();
        }),
        startWith(this.getCurrentDetailUser()),
        distinctUntilChanged()
      )
      .subscribe((detailUser: EditUserDefinition | undefined) => {
        this.detailUser = detailUser;
      });
    this.permissions.ready$.subscribe(() => {
      if (!this.canRead('clients')) {
        if (!this.canRead('lawyers')) {
          this.activeView = 'groups';
        } else {
          this.activeView = 'lawyers';
        }
      }
    });
  }

  private getCurrentDetailUser(): EditUserDefinition | undefined {
    const opts = this.activatedRoute.snapshot?.firstChild?.url
      ?.map((u) => u?.path)
      ?.filter((n) => !!n);
    if (
      !opts ||
      opts.length !== 2 ||
      !['client', 'lawyer', 'role'].includes(opts[0])
    )
      return undefined;
    return {
      entity: opts[0] as 'client' | 'lawyer' | 'role',
      uuid: opts[1],
    };
  }

  private initializeData(): void {
    this.initializeExternalConfig();
    this.initializeInternalConfig();
    this.initializeGroupConfig();
  }

  async addClient(): Promise<void> {
    addClient(
      this.dialog,
      this.translator,
      this.clientAPI,
      this.permissions,
      this.auth,
      this.roleAPI,
      this.settings
    ).subscribe({
      next: () => {
        this.tableRef.dataSourceObj.update();
        this.snackBar.open(
          this.translator.instant('lawyer.dashboard.clients.createdSuccessful'),
          '',
          {
            duration: 3000,
          }
        );
      },
      error: (err: HttpErrorResponse) => {
        let errorMsg: string;
        if (err.status === 409) {
          errorMsg = this.translator.instant('common.error.userAlreadyExists');
        } else if (!_.isNil(err?.error.details)) {
          errorMsg = this.translator.instant(
            'common.error.createWithExplanation',
            {
              explanation: buildErrorString(
                this.translator,
                err.error.details,
                clientsFilters
              ),
            }
          );
        } else {
          errorMsg = this.translator.instant('common.error.genericCreate');
        }
        this.snackBar.open(errorMsg, '', { duration: 4000 });
      },
    });
  }

  async addLawyer(): Promise<void> {
    from(
      this.dialog.edit('lawyer.dashboard.lawyersList.create', {
        mail: {
          value: '',
          type: 'text',
          name: 'common.label.emailAddress',
          required: true,
        },
        firstName: {
          value: '',
          type: 'text',
          name: 'common.label.firstName',
        },
        lastName: {
          value: '',
          type: 'text',
          name: 'common.label.lastName',
        },
        requestPasswordSet: {
          value: true,
          type: 'boolean',
          name: 'common.login.requestPasswordReset',
        },
        password: {
          value: '',
          type: 'password',
          name: 'common.login.password',
          required: true,
          hideIf: (value: { [key: string]: any }) => {
            return value?.requestPasswordSet?.value;
          },
        },
        // roles: getRolesConfig(this.roleAPI),
        permissions: getPermissionConfig(
          this.permissionAPI,
          this.translator,
          this.permissions
        ),
      })
    )
      .pipe(
        catchError(() => of(null)),
        filter((n) => !!n),
        map((value: any) => {
          const mail = value.mail?.value;
          if (!mail) {
            this.snackBar.open(
              this.translator.instant('common.error.emailMustBeSet'),
              null,
              {
                duration: 3000,
              }
            );
            return null;
          }
          const firstName =
            value.firstName?.value ||
            this.translator.instant('common.label.firstName');
          const lastName =
            value.lastName?.value ||
            this.translator.instant('common.label.lastName');
          const password =
            value.password?.value ||
            sha256(Date.now().toString()).substr(0, 8) ||
            null;
          if (password === null) {
            this.snackBar.open(
              this.translator.instant(
                'lawyer.dashboard.clients.error.passwordInvalid'
              ),
              '',
              { duration: 3000 }
            );
            return null;
          }
          return {
            first_name: firstName,
            last_name: lastName,
            additional_info: {},
            mail: mail,
            changed_pw: false,
            permissions: (value.permissions?.value ?? []).map(
              (m: MenuEntry<Permission>) => m.value
            ),
            roles: [],
            auth_token: value?.requestPasswordSet?.value ? '' : password,
            request_password_reset: value?.requestPasswordSet?.value ?? false,
          };
        }),
        filter((n) => !!n),
        switchMap(
          (user: LawyerRequest & { request_password_reset: boolean }) => {
            const validation = this.permissions.validateFocused(
              user,
              ['mail', 'first_name', 'last_name', 'full_name', 'roles.name'],
              PermissionPolicy.ModelEnum.Lawyers,
              ['variables', 'permissions', 'roles']
            );
            if (isPermission(validation)) return of(false);
            if (validation !== true) {
              return from(
                applyFiltersToModel(
                  validation.other,
                  user,
                  this.auth,
                  (options) =>
                    userDecisionHandler(
                      options,
                      this.dialog,
                      this.translator,
                      lawyersFilters
                    )
                )
              ).pipe(
                map(() => {
                  if (!!user.permissions && !_.isArray(user.permissions)) {
                    user.permissions = [user.permissions];
                  }
                  if (!!user.variables && !_.isArray(user.variables)) {
                    user.variables = [user.variables];
                  }
                  return user;
                })
              );
            }
            return of(user);
          }
        ),
        filter((n) => !!n),
        switchMap(
          (
            lawyer_model: LawyerRequest & { request_password_reset: boolean }
          ) => {
            return this.lawyerAPI
              .createLawyer({
                lawyerRequest: lawyer_model,
              })
              .pipe(
                switchMap((lawyer) => {
                  if (lawyer_model?.request_password_reset) {
                    return this.lawyerAPI.requestLawyerPasswordChange({
                      userid: lawyer.id,
                    });
                  }
                  return of(lawyer);
                })
              );
          }
        )
      )
      .subscribe(
        () => {
          this.tableRef.dataSourceObj.update();
          this.snackBar.open(
            this.translator.instant(
              'lawyer.dashboard.lawyersList.createdSuccessful'
            ),
            '',
            {
              duration: 3000,
            }
          );
        },
        (err) => {
          let errorMsg: string;
          if (err.status === 409) {
            errorMsg = this.translator.instant(
              'common.error.userAlreadyExists'
            );
          } else if (!_.isNil(err?.error.details)) {
            errorMsg = this.translator.instant(
              'common.error.createWithExplanation',
              {
                explanation: buildErrorString(
                  this.translator,
                  err.error.details,
                  lawyersFilters
                ),
              }
            );
          } else {
            errorMsg = this.translator.instant('common.error.genericCreate');
          }
          this.snackBar.open(errorMsg, '', { duration: 4000 });
        }
      );
  }

  async addGroup() {
    from(
      this.dialog.edit('lawyer.dashboard.groups.create', {
        name: {
          value: '',
          type: 'text',
          name: 'common.label.name',
          required: true,
        },
        description: {
          value: '',
          type: 'text',
          name: 'common.label.description',
        },
        icon: {
          value: '',
          type: 'icon',
          name: 'common.label.icon',
        },
      })
    )
      .pipe(
        catchError((n) => of(null)),
        filter((n) => !!n),
        map((value) => {
          const name = value.name?.value;
          if (!name) {
            this.snackBar.open(
              this.translator.instant('common.error.groupNameMustBeSet'),
              null,
              {
                duration: 3000,
              }
            );
            return;
          }
          const description = value.description?.value || '';
          const icon = value.icon?.value || '';
          return {
            description,
            icon,
            name,
          };
        }),
        filter((n) => !!n),
        switchMap((role: RoleRequest) => {
          const validation = this.permissions.validateFocused(
            role,
            ['description', 'name'],
            PermissionPolicy.ModelEnum.Roles,
            ['variables', 'permissions']
          );
          if (isPermission(validation)) return of(false);
          if (validation !== true) {
            return from(
              applyFiltersToModel(
                validation.other,
                role,
                this.auth,
                (options) =>
                  userDecisionHandler(
                    options,
                    this.dialog,
                    this.translator,
                    groupsFilter
                  )
              )
            ).pipe(
              map(() => {
                if (!!role.permissions && !_.isArray(role.permissions)) {
                  role.permissions = [role.permissions];
                }
                return role;
              })
            );
          }
          return of(role);
        }),
        filter((n) => !!n),
        switchMap((roleRequest: RoleRequest) =>
          this.roleAPI.createRole({
            roleRequest,
          })
        )
      )
      .subscribe(
        () => {
          this.tableRef.dataSourceObj.update();
          this.snackBar.open(
            this.translator.instant(
              'lawyer.dashboard.groups.createdSuccessful'
            ),
            '',
            {
              duration: 3000,
            }
          );
        },
        (err) => {
          let errorMsg: string;
          if (!_.isNil(err?.error.details)) {
            errorMsg = this.translator.instant(
              'common.error.createWithExplanation',
              {
                explanation: buildErrorString(
                  this.translator,
                  err.error.details,
                  groupsFilter
                ),
              }
            );
          } else {
            errorMsg = this.translator.instant('common.error.genericCreate');
          }
          this.snackBar.open(errorMsg, '', { duration: 4000 });
        }
      );
  }

  async editGroup(id: string) {
    this.router.navigate(['.', 'role', id], {
      relativeTo: this.activatedRoute,
    });
  }

  async editClient(id: string): Promise<void> {
    this.router.navigate(['.', 'client', id], {
      relativeTo: this.activatedRoute,
    });
  }

  async editLawyer(id: string): Promise<void> {
    this.router.navigate(['.', 'lawyer', id], {
      relativeTo: this.activatedRoute,
    });
  }

  exportClientData(): void {
    this.triggerExport.emit();
  }

  private initializeExternalConfig() {
    this.externalTableConfig = {
      id: 'internal-clients',
      availableFilters: clientsFilters,
      fetch: (params) => {
        // const variable: Filter = filters.find((f) => f.name === 'variable');
        return this.clientAPI
          .listUsers({
            filterViewPagination: params,
            includeGuests: true,
          })
          .pipe(
            map((data) => {
              return {
                data: data.users,
                total_entries: data.total_entries,
                view: params.view,
              };
            })
          );
      },
      onRowClick: (element) => {
        this.editClient(element.id);
      },
      actions: [
        {
          id: 'edituser',
          icon: 'edit',
          name: 'lawyer.dashboard.clients.edit',
          handler: (element: User) => {
            this.editClient(element.id);
          },
        },
        {
          id: 'delete',
          icon: 'delete',
          name: 'common.button.delete',
          handler: async (element, dataSource) => {
            if (
              await this.dialog.confirm({
                text: 'lawyer.dashboard.clients.confirmDelete',
              })
            ) {
              dataSource.setLoading(true);
              this.clientAPI
                .deleteUser({
                  userid: element.id,
                })
                .subscribe(
                  () => {
                    dataSource.update();
                  },
                  (error) => {
                    dataSource.setLoading(false);
                    let errorMsg: string;
                    if (!_.isNil(error?.error.details)) {
                      errorMsg = this.translator.instant(
                        'common.error.deleteWithExplanation',
                        {
                          explanation: buildErrorString(
                            this.translator,
                            error.error.details,
                            clientsFilters
                          ),
                        }
                      );
                    } else {
                      errorMsg = this.translator.instant(
                        'common.error.genericDelete'
                      );
                    }
                    this.snackBar.open(errorMsg, '', { duration: 3000 });
                  }
                );
            }
          },
        },
      ],
      view: {
        displayed_columns: [
          {
            display_name: this.translator.instant('client.filter.label.mail'),
            internal_name: 'mail',
          },
          {
            display_name: this.translator.instant('client.filter.label.name'),
            internal_name: 'full_name',
          },
        ],
        hidden_columns: [
          {
            display_name: 'id',
            internal_name: 'id',
          },
        ],
        sort_by: {
          by: 'full_name',
          direction: 'asc',
        },
      },
    };
  }

  private initializeInternalConfig() {
    this.internalTableConfig = {
      id: 'internal-lawyers',
      availableFilters: lawyersFilters,
      fetch: (params) => {
        return this.lawyerAPI
          .listLawyers({
            filterViewPagination: params,
          })
          .pipe(
            map((data) => {
              return {
                data: data.users,
                total_entries: data.total_entries,
                view: params.view,
              };
            })
          );
      },
      onRowClick: (element) => this.editLawyer(element.id),
      actions: [
        {
          id: 'editlawyer',
          icon: 'edit',
          name: 'lawyer.dashboard.lawyersList.edit',
          handler: (element: User) => {
            this.editLawyer(element.id);
          },
        },
        {
          id: 'delete',
          icon: 'delete',
          name: 'common.button.delete',
          handler: async (element, dataSource) => {
            if (
              await this.dialog.confirm({
                text: 'lawyer.dashboard.clients.confirmDelete',
              })
            ) {
              dataSource.setLoading(true);
              this.lawyerAPI
                .deleteLawyer({
                  lawyerid: element.id,
                })
                .subscribe(
                  () => {
                    dataSource.update();
                  },
                  () => {
                    dataSource.setLoading(false);
                    this.snackBar.open(
                      this.translator.instant('common.error.genericDelete'),
                      '',
                      { duration: 3000 }
                    );
                  }
                );
            }
          },
        },
      ],
      view: {
        displayed_columns: [
          {
            display_name: this.translator.instant('lawyer.filter.label.mail'),
            internal_name: 'mail',
          },
          {
            display_name: this.translator.instant(
              'lawyer.filter.label.fullName'
            ),
            internal_name: 'full_name',
          },
        ],
        hidden_columns: [
          {
            display_name: 'id',
            internal_name: 'id',
          },
        ],
        sort_by: {
          by: 'mail',
          direction: 'asc',
        },
      },
    };
  }

  private initializeGroupConfig() {
    this.groupTableConfig = {
      id: 'internal-groups',
      availableFilters: groupsFilter,
      fetch: (params) => {
        return this.roleAPI
          .listRoles({
            filterViewPagination: params,
          })
          .pipe(
            map((data) => {
              return {
                data: data.roles,
                total_entries: data.total_entries,
                view: params.view,
              };
            })
          );
      },
      onRowClick: (element) => {
        this.editGroup(element.id);
      },
      actions: [
        {
          id: 'editrole',
          icon: 'edit',
          name: 'lawyer.dashboard.clients.edit',
          handler: (element: User) => {
            this.editGroup(element.id);
          },
        },
        {
          id: 'delete',
          icon: 'delete',
          name: 'common.button.delete',
          handler: async (element, dataSource) => {
            if (
              await this.dialog.confirm({
                text: 'lawyer.dashboard.clients.confirmDelete',
              })
            ) {
              dataSource.setLoading(true);
              this.roleAPI
                .deleteRole({
                  roleid: element.id,
                })
                .subscribe(
                  () => {
                    dataSource.update();
                  },
                  () => {
                    dataSource.setLoading(false);
                    this.snackBar.open(
                      this.translator.instant('common.error.genericDelete'),
                      '',
                      { duration: 3000 }
                    );
                  }
                );
            }
          },
        },
      ],
      view: {
        displayed_columns: [
          {
            display_name: this.translator.instant('role.filter.label.name'),
            internal_name: 'name',
            icon: (element) => {
              return element.icon;
            },
          },
          {
            display_name: this.translator.instant(
              'role.filter.label.description'
            ),
            internal_name: 'description',
          },
        ],
        hidden_columns: [
          {
            display_name: 'id',
            internal_name: 'id',
          },
          {
            display_name: 'icon',
            internal_name: 'icon',
          },
        ],
        sort_by: {
          by: 'name',
          direction: 'asc',
        },
      },
    };
  }

  returnFromView() {
    const realm = this.activatedRoute.snapshot.paramMap.get('realm');
    this.router.navigate([realm, 'intern', 'clients']);
  }

  canRead(tab: ClientsGroupOptions): boolean {
    return this.permissions.canRead(
      tab === 'clients'
        ? PermissionPolicy.ModelEnum.Clients
        : tab === 'lawyers'
        ? PermissionPolicy.ModelEnum.Lawyers
        : PermissionPolicy.ModelEnum.Roles
    );
  }
}

export function addClient(
  dialog: DialogService,
  translator: TranslateService,
  clientAPI: ClientsService,
  permissions: PermissionsService,
  auth: AuthService,
  roleAPI: RolesService,
  settings: SettingsService
): Observable<User | undefined> {
  const clientPortalEnabled =
    settings.getSettingSync('feature.clientPortal.disable', 'global')?.value !==
    'true';
  return from(
    dialog.edit('lawyer.dashboard.clients.create', {
      mail: {
        value: '',
        type: 'text',
        name: 'common.label.emailAddress',
        required: (dataSource) => {
          return !!dataSource.createLogin?.value;
        },
      },
      firstName: {
        value: '',
        type: 'text',
        name: 'common.label.firstName',
      },
      lastName: {
        value: '',
        type: 'text',
        name: 'common.label.lastName',
      },
      createLogin: {
        value: clientPortalEnabled,
        type: 'boolean',
        name: 'common.login.createLogin',
        hideIf: () => !clientPortalEnabled,
      },
      passwordMode: {
        value: 'requestPasswordReset',
        type: 'dropdown',
        options: [
          {
            name: 'common.login.requestPasswordReset',
            value: 'requestPasswordReset',
          },
          {
            name: 'common.login.setPasswordNow',
            value: 'setPasswordNow',
          },
          {
            name: 'common.login.noPasswordNeeded',
            value: 'asGuest',
          },
        ],
        name: 'common.login.loginMethod',
        hideIf: (value: { [key: string]: any }) => {
          return !value?.['createLogin']?.value;
        },
      },
      password: {
        value: '',
        type: 'password',
        name: 'common.login.password',
        required: true,
        hideIf: (value: { [key: string]: any }) => {
          return (
            !value?.['createLogin']?.value ||
            value?.['passwordMode']?.value !== 'setPasswordNow'
          );
        },
      },
      // roles: getRolesConfig(roleAPI),
    })
  ).pipe(
    catchError(() => of(undefined)),
    filter((n) => !!n),
    map((value) => {
      const mail = value.mail?.value;
      const firstName =
        value.firstName?.value || translator.instant('common.label.firstName');
      const lastName =
        value.lastName?.value || translator.instant('common.label.lastName');
      const canAuth = value.createLogin?.value;
      const pwMode = value?.passwordMode?.value ?? 'asGuest';
      const password =
        pwMode === 'setPasswordNow' && value.password?.value?.length
          ? value.password?.value
          : '';
      return {
        mail,
        auth_token: password,
        can_auth: canAuth,
        guest: pwMode === 'asGuest' ? true : false,
        first_name: firstName,
        last_name: lastName,
        request_password_set: pwMode === 'requestPasswordReset' ? true : false,
      };
    }),
    switchMap((user: User & { request_password_set: boolean }) => {
      const validation = permissions.validateFocused(
        user,
        ['mail', 'first_name', 'last_name', 'full_name'],
        PermissionPolicy.ModelEnum.Clients,
        ['variables', 'permissions', 'roles']
      );
      if (isPermission(validation)) return of(false);
      if (validation !== true) {
        return from(
          applyFiltersToModel(validation.other, user, auth, (options) =>
            userDecisionHandler(options, dialog, translator, clientsFilters)
          )
        ).pipe(
          map(() => {
            if (!!user.variables && !_.isArray(user.variables)) {
              user.variables = [user.variables];
            }
            return user;
          })
        );
      }
      return of(user);
    }),
    filter((n) => !!n),
    switchMap((user: User & { request_password_set: boolean }) => {
      return clientAPI
        .createUser({
          user,
        })
        .pipe(
          switchMap((client) => {
            if (user.can_auth && user?.request_password_set) {
              return clientAPI
                .requestClientPasswordChange({
                  userid: client.id,
                })
                .pipe(map(() => client));
            }
            return of(client);
          })
        )
        .toPromise();
    })
  );
}

function getRolesConfig(roleAPI: RolesService) {
  return {
    value: [],
    type: 'join',
    name: 'lawyer.dashboard.clients.groups',
    queryEntities: (
      searchTerm: string,
      currentValue: any
    ): Observable<MenuEntry<Role>[]> => {
      return roleAPI
        .listRoles({
          filterViewPagination: {
            filter: [
              {
                operand: 'name',
                operator: 'contains',
                value: searchTerm,
              },
            ],
            pagination: {
              page: 1,
              rows_per_page: 10,
            },
            view: {
              displayed_columns: [
                {
                  display_name: 'name',
                  internal_name: 'name',
                },
                {
                  display_name: 'description',
                  internal_name: 'description',
                },
                {
                  display_name: 'icon',
                  internal_name: 'icon',
                },
                {
                  display_name: 'id',
                  internal_name: 'id',
                },
              ],
              hidden_columns: [],
            },
          },
        })
        .pipe(
          map((resp) => {
            return resp.roles
              .map(
                (role): MenuEntry<Role> => ({
                  name: role.name,
                  details: role.description,
                  icon: role.icon,
                  value: role,
                })
              )
              .filter(
                (e) => !currentValue.some((v) => v.value.id === e.value.id)
              );
          })
        );
    },
  };
}

function getPermissionConfig(
  permissionAPI: APIPermissionsService,
  translator: TranslateService,
  permissions: PermissionsService
) {
  const initialValue: MenuEntry<Permission>[] = [];
  const possibleValues = permissions.getPossibleValuesFor(
    PermissionPolicy.ModelEnum.Lawyers,
    {},
    `permissions.name`,
    '*'
  );
  if (possibleValues === '*') {
    const mainPermission = permissions.permissions.find(
      (perm) => perm.via === 'user'
    );
    if (!!mainPermission) {
      initialValue.push({
        name: mainPermission.name,
        value: {
          name: mainPermission.name,
          id: mainPermission.id,
        } as any,
      });
    }
  } else {
    if (possibleValues?.length) {
      const val = possibleValues[0];
      initialValue.push({
        name: val,
        value: {
          name: val,
        } as any,
      });
    }
  }
  return {
    value: initialValue,
    type: 'join',
    name: 'lawyer.dashboard.clients.permissions',
    tooltip: 'common.tooltip.permissions',
    singleChoice: true,
    queryEntities: (
      searchTerm: string,
      currentValue: any
    ): Observable<MenuEntry<Permission>[]> => {
      return permissionAPI.listPermissions({}).pipe(
        map((permissions) => {
          return permissions
            .map(
              (perm): MenuEntry<Permission> => ({
                name: perm.name,
                value: perm,
              })
            )
            .filter(
              (m) =>
                filterTerm(searchTerm, m, translator) &&
                (possibleValues === '*' ||
                  possibleValues.includes(m.value.name))
            )
            .concat([
              {
                name: 'common.button.removeSelection',
                icon: 'close',
                value: undefined,
              },
            ]);
        })
      );
    },
  };
}
