<!-- <mat-icon *ngIf="sender === 'bot'">auto_awesome</mat-icon> -->
<img class="bot" *ngIf="sender === 'bot'" src="/assets/silberfluss-poster-badge.svg" />
<mat-icon *ngIf="sender !== 'bot' && !senderInfo">face</mat-icon>
<div class="sender-info" *ngIf="senderInfo" [matTooltip]="senderInfo.mail ?? senderInfo.name ?? ''">
  <mat-icon *ngIf="!senderInfo?.initials">face</mat-icon>
  <mat-icon *ngIf="senderInfo?.initials?.type === 'icon'">{{senderInfo?.initials?.label}}</mat-icon>
  <img *ngIf="senderInfo?.initials?.type === 'profile-pic'" [src]="API_URL +
  '/file/' +
  senderInfo.initials.label +
  '?realm=' +
  realm" />
  <span *ngIf="senderInfo?.initials?.type === 'initials'">{{ senderInfo.initials?.label }}</span>
</div>