import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  Optional,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import {
  Observable,
  Subject,
  firstValueFrom,
  map,
  startWith,
  switchMap,
  takeUntil,
  tap,
  timer,
} from 'rxjs';
import {
  BooleanOperator,
  CorporateIdentityService,
  ExecutionService,
  ExecutionStateRequest,
  FilterCriterium,
  LegalProcess,
  LegalProcessService,
  Permission,
  PermissionPolicy,
} from 'src/api';
import { MenuEntry } from 'advoprocess/lib/types/menu';
import * as _ from 'lodash';
import { filterTerm } from 'src/app/common/helpers';
import {
  HierarchicalParticipant,
  ParticipantChangeEvent,
} from 'src/app/widgets/dossier-assigned-input/dossier-assigned-input.component';
import { AuthService } from 'src/app/auth/auth.service';
import { PermissionsService } from 'src/app/auth/permissions.service';
import {
  applyFiltersToModel,
  isPermission,
  userDecisionHandler,
} from 'src/app/auth/permission-helpers';
import { executionStateFilters } from 'src/app/views/process/filters/execution-state-filters';
import { DialogService } from 'src/app/widgets/dialog/dialog.service';
import { PROTOTYPE_NODE_TEMPLATE } from './prototype-node-template';

export interface NewDossierDialogData {
  initialName?: string;
  initialProcess?: LegalProcessDefinition;
  initialParticipants?: HierarchicalParticipant[];
  lock?: ('name' | 'process' | 'participants')[];
}

interface LegalProcessDefinition {
  id: string;
  name: string;
}

interface CreateForm {
  name: FormControl<string>;
  fromProcess: FormControl<LegalProcessDefinition | undefined>;
  participants: FormControl<Array<HierarchicalParticipant>>;
}

@Component({
  selector: 'app-new-dossier-dialog',
  templateUrl: './new-dossier-dialog.component.html',
  styleUrls: ['./new-dossier-dialog.component.scss'],
})
export class NewDossierDialogComponent implements AfterViewInit {
  validation: boolean | string = undefined;

  createForm = new FormGroup<CreateForm>({
    name: new FormControl<string>(
      this.translator.instant('lawyer.dashboard.dossiers.newName')
    ),
    fromProcess: new FormControl<LegalProcessDefinition | undefined>(undefined),
    participants: new FormControl<Array<HierarchicalParticipant>>([]),
  });

  clerkAdded = false;

  constructor(
    @Optional()
    public dialogRef: MatDialogRef<NewDossierDialogComponent>,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: NewDossierDialogData,
    private translator: TranslateService,
    private processes: LegalProcessService,
    private stateAPI: ExecutionService,
    private el: ElementRef,
    private auth: AuthService,
    public permissions: PermissionsService,
    private dialog: DialogService,
    private ci: CorporateIdentityService
  ) {
    if (this.data.initialName) {
      this.createForm.get('name').setValue(this.data.initialName);
    }
    if (this.data.initialParticipants) {
      this.createForm
        .get('participants')
        .setValue(this.data.initialParticipants);
    } else {
      this.addSelfAsClerk();
    }
    if (this.data.initialProcess) {
      this.createForm.get('fromProcess').setValue(this.data.initialProcess);
    }
    if (this.data?.lock?.includes('name')) {
      this.createForm.get('name').disable();
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.el.nativeElement?.querySelector('.name-input')?.select();
    }, 50);
    this.updateValidation();
  }

  abortQuery$ = new Subject<void>();
  lastProcessesResult: MenuEntry<LegalProcess>[] = [];

  queryProcesses(searchTerm: string): Observable<MenuEntry<LegalProcess>[]> {
    this.abortQuery$.next();
    const processStates$ = timer(1000).pipe(
      switchMap(() =>
        this.processes.listProcesses({
          filterViewPagination: {
            filter: [
              {
                operand: 'published.info.name',
                operator: 'contains',
                value: searchTerm,
              },
            ],
            pagination: {
              page: 1,
              rows_per_page: 10,
            },
            view: {
              displayed_columns: [
                { display_name: 'id', internal_name: 'id' },
                {
                  display_name: 'name',
                  internal_name: 'published.info.name',
                },
              ],
            },
          },
        })
      ),
      map((resp) =>
        resp.legal_processes.map(
          (p: any) =>
            ({
              name: p.name,
              value: p,
            } as MenuEntry<LegalProcess>)
        )
      ),
      tap((x) => (this.lastProcessesResult = x)),
      startWith(this.lastProcessesResult),
      takeUntil(this.abortQuery$)
    );
    return processStates$.pipe(
      map((results) => {
        return [
          {
            name: this.translator.instant('common.button.removeSelection'),
            icon: 'close',
            value: undefined,
            group: {
              name: '',
              priority: 10,
            },
          } as MenuEntry<LegalProcess | undefined>,
        ]
          .concat(_.flatten(results))
          .filter((e) => filterTerm(searchTerm, e, this.translator));
      })
    );
  }

  setProcess(entry: MenuEntry<LegalProcessDefinition>) {
    this.createForm.get('fromProcess').setValue(entry.value);
    this.updateValidation();
  }

  updateParticipants(event: ParticipantChangeEvent) {
    const control = this.createForm.get('participants').value;
    switch (event.operation) {
      case 'add':
        const existingEntryIndex = control.findIndex(
          (p) =>
            p.id === event.participant.id &&
            p.source === event.participant.source
        );
        if (existingEntryIndex != -1) {
          control.splice(existingEntryIndex, 1, event.participant);
        } else {
          control.push(event.participant);
        }
        break;
      case 'remove':
        control.splice(
          control.findIndex(
            (i) =>
              i.id === event.participant.id &&
              i.source === event.participant.source
          ),
          1
        );
        break;
      case 'change':
        control.find(
          (i) =>
            i.id === event.participant.id &&
            i.source === event.participant.source
        ).role = event.participant.role;
        break;
    }
    this.clerkAdded = control.some((p) => p.id === this.auth.userId);
    this.updateValidation();
  }

  async submit() {
    const name = this.createForm?.get('name')?.value;
    if (!name?.length || !!this.validation) return;

    const request: ExecutionStateRequest = this.getValidationModel();

    const validation = this.permissions.validateFocused(
      this.getValidationModel(),
      ['file_name', 'initial_processid', 'clients.id', 'lawyers.id'],
      PermissionPolicy.ModelEnum.ExecutionStates,
      ['data_store', 'lawyers', 'clients']
    );
    if (isPermission(validation)) return;

    if (validation !== true) {
      await applyFiltersToModel(
        validation.other,
        request,
        this.auth,
        (options) =>
          userDecisionHandler(
            options,
            this.dialog,
            this.translator,
            executionStateFilters
          )
      );
    }

    request.fromProcess = (request as any).initial_processid ?? undefined;
    request.name = (request as any).file_name;
    request.dataStore = (request as any).data_store;
    request.nodeTemplate = PROTOTYPE_NODE_TEMPLATE;

    this.stateAPI
      .addNewState({
        executionStateRequest: request,
      })
      .subscribe((state) => {
        this.dialogRef.close(state.id);
      });
  }

  ownProfilePicture?: string = null;

  async addSelfAsClerk() {
    if (this.ownProfilePicture === null) {
      const ci = await firstValueFrom(
        this.ci.getLawyerCorporateIdentity({
          lawyerid: this.auth.userId,
        })
      );
      this.ownProfilePicture = ci?.lawyer_headshot ?? undefined;
    }
    this.createForm.get('participants').value.push({
      id: this.auth.userId,
      mail: this.auth.userMail,
      role: 'CLERK',
      source: 'lawyer',
      profile_picture: this.ownProfilePicture ?? undefined,
      first_name: this.auth.rawDecoded.given_name,
      last_name: this.auth.rawDecoded.family_name,
    });
    this.clerkAdded = true;
  }

  updateValidation() {
    const validated = this.permissions.validateFocused(
      this.getValidationModel(),
      ['file_name', 'initial_processid', 'clients.id', 'lawyers.id'],
      PermissionPolicy.ModelEnum.ExecutionStates,
      ['data_store', 'lawyers', 'clients']
    );
    if (validated === true) {
      this.validation = undefined;
      return;
    }

    let relevantErrors: (FilterCriterium | BooleanOperator)[] | Permission;
    if (isPermission(validated)) {
      relevantErrors = validated;
    } else {
      relevantErrors = validated.relevant;
    }

    if (_.isArray(relevantErrors) && !relevantErrors.length) {
      this.validation = undefined;
      return;
    }

    this.validation = this.translator.instant(
      'common.error.inputWithExplanation',
      {
        explanation: this.permissions.humanReadableError(
          relevantErrors,
          executionStateFilters
        ),
      }
    );
  }

  private getValidationModel() {
    const participants = this.createForm.get('participants')
      .value as HierarchicalParticipant[];
    const clients = participants.filter((p) => p.source === 'client');
    const lawyers = participants.filter((p) => p.source === 'lawyer');
    return {
      file_name: this.createForm.get('name').value,
      initial_processid: this.createForm.get('fromProcess').value?.id ?? null,
      clients,
      lawyers,
      closed: false,
    };
  }
}
