import { ElementRef, Injectable } from '@angular/core';
import { HeaderComponent } from './common/header/header.component';

@Injectable({
  providedIn: 'root',
})
export class AdornmentsService {
  private _header: HeaderComponent;

  public registerHeader(header: HeaderComponent) {
    this._header = header;
  }

  public setHeaderTo(element: ElementRef<any>) {
    if (!this._header) return;
    this._header.replaceHeader(element);
  }

  public removeHeader(element: ElementRef<any>) {
    if (!this._header) return;
    this._header.removeHeader(element);
  }
}
