import {
  AfterViewInit,
  Component,
  ComponentRef,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';

import { applyWrapping } from './jodit-plugins';
import {
  getDeepPath,
  pathFromTo,
  ProcessNode,
  SPECIAL_FUNCTIONS,
  uploadFile,
} from 'advoprocess';
import { sha256 } from 'js-sha256';
import { AuthService } from 'src/app/auth/auth.service';
import { wrapInJWT } from 'advoprocess/lib/helpers/parser';
import { Jodit, IJodit, Select } from 'jodit-pro';
import { environment } from 'src/environments/environment';
import { RefidInputComponent } from '../refid-input/refid-input.component';
import { FilesService } from 'src/api';
import * as _ from 'lodash';
import { Overlay, OverlayPositionBuilder } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { getRectWithZoom } from 'src/app/common/helpers';
import DataStore from 'advoprocess/lib/parser/data-store';

export interface Condition {
  cond: any;
  elmnt: HTMLElement;
  selection?: Select;
  id: string;
  cells?: HTMLTableCellElement[];
  marker?: any;
}

export interface PreviewNode {
  node: ProcessNode;
  oldRefId: string;
}

@Component({
  selector: 'app-rich-text-editor',
  templateUrl: './rich-text-editor.component.html',
  styleUrls: ['./rich-text-editor.component.scss'],
})
export class RichTextEditorComponent
  implements AfterViewInit, OnChanges, OnDestroy
{
  @Input() text: string;
  @Input() dataSource?: ProcessNode[];
  @Input() dataStore?: DataStore;
  @Input() readonly = false;
  @Input() currentCondition: Condition;
  @Input() currentTagNode: PreviewNode;
  @Input() ownNode?: ProcessNode;
  @Input() buttons: string;
  @Input() stringInput: boolean;
  @Input() inline = false;
  @Input() options?: { [key: string]: any };
  @Input() conditionEditPopupPosition: 'inside' | 'above' = 'inside';

  @Output() textChange = new EventEmitter<string>();
  @Output() currentTagNodeChange = new EventEmitter<PreviewNode>();
  @Output() currentConditionChange = new EventEmitter<Condition>();

  @ViewChild('editorContainer') editorContainer: ElementRef;
  @ViewChild('tagInputContainer', { read: ViewContainerRef })
  tagInputContainer: ViewContainerRef;
  @ViewChild('cursorPosFollower', { read: ElementRef })
  cursorPosRef: ElementRef;

  editor: IJodit;

  isOrbOpen = false;

  tagInputOpen = false;

  orbPos = { x: 0, y: 0 };

  uploadPath: string;
  fileName: string;

  currentTagSnippet = '';

  defaultButtons =
    '|,font,fontsize,paragraph,brush,|,bold,italic,underline,strikethrough,|,superscript,subscript,|,ul,ol,align,lineHeight,|,image,table,link,symbols,|,undo,redo,|';

  buttonList: string;

  constructor(
    private auth: AuthService,
    private files: FilesService,
    private _overlay: Overlay,
    private _overlayPositionBuilder: OverlayPositionBuilder,
    private _elementRef: ElementRef
  ) {
    Jodit.defaultOptions.license = environment.joditKey;
    Jodit.defaultOptions.tabIndex = 0;
    Jodit.defaultOptions.controls.condInsert = {
      iconURL:
        'https://www.seekpng.com/png/full/807-8071036_png-file-condition-icon-png.png',
      isDisabled: (e: Jodit) => {
        if (!this.dataSource) return true;
        const current = e.s.current();
        const className =
          Jodit.modules.Dom['closest'](
            current,
            'span',
            null
          )?.className?.includes('conditional-add') || false;
        return e.s.range.collapsed || className;
      },
      tooltip: 'Bedingtes Einfügen',
      exec: () => {
        this.addConditionToSelection();
      },
    };
    Jodit.defaultOptions.controls.fromDocx = {
      iconURL:
        'https://upload.wikimedia.org/wikipedia/commons/thumb/f/fb/.docx_icon.svg/128px-.docx_icon.svg.png',
      tooltip: 'Aus Word-Datei einfügen',
      exec: () => {
        // Load file
        const inp = document.createElement('input');
        inp.type = 'file';
        inp.accept =
          '.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document';
        inp.multiple = false;
        document.body.appendChild(inp);
        inp.click();
        inp.onchange = () => {
          const file = inp.files[0];
          const reader = new FileReader();
          reader.onload = () => {
            this.convertDocxToHTML(reader.result as ArrayBuffer).then(
              (data) => {
                this.text = data;
                this.updateText();
              }
            );
          };
          reader.readAsArrayBuffer(file);
          inp.remove();
        };
      },
    };

    Jodit.defaultOptions.controls.addDynamicButton = {
      iconURL: '/assets/baseline_smart_button_black_24dp.png',
      tooltip: 'Dynamischen Button einfügen',
      popup: (editor, current, self, close) => {
        const form = editor.create.fromHTML(
          `<form>
            <input type="text" name="ref-id" placeholder="ID des Knopfes"/>
            <button type="submit">Einfügen</button>
          </form>
          `
        );

        editor.e.on(form, 'submit', (e) => {
          e.preventDefault();
          const refId = form.querySelector('input[name="ref-id"]').value;
          if (!refId?.length) {
            return;
          }
          editor.s.insertHTML(
            `<div class="eb-dynamic-button" eb-ref-id="${refId}">Knopf</div>`
          );
          close();
        });

        return form;
      },
    };
  }

  ngAfterViewInit(): void {
    this.buttonList = this.buttons ?? this.defaultButtons;

    if (this.stringInput) {
      this.buttonList = '';
    }

    if (
      environment.env === 'dev' ||
      environment.env === 'local' ||
      environment.env === 'new' ||
      environment.env === 'alpha'
    ) {
      this.buttonList = `|,source,|,${this.buttonList}`;
    }

    this.initializeEditor();

    if (!this.readonly) {
      this.initializeEvents();
    }

    this.updateText();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.options) {
      this.applyOptions();
    }
    if (!changes.text) {
      return;
    }
    this.updateText();
  }

  ngOnDestroy(): void {
    if (this.editor) {
      this.editor.destruct();
    }
  }

  private initializeEditor(): void {
    const config: IJodit['options'] = {
      allowResizeY: false,
      allowResizeX: false,
      showPlaceholder: false,
      colorPickerDefaultTab: 'foreground',
      language: 'de',
      toolbarAdaptive: false,
      toolbarInlineForSelection: true,
      toolbar: !this.inline,
      buttons: !this.readonly ? this.buttonList : '',
      extraButtons: !this.readonly
        ? ['condInsert', 'addDynamicButton', 'fromDocx', '|']
        : [],
      showXPathInStatusbar: false,
      readonly: this.readonly,
      uploader: {
        insertImageAsBase64URI: true,
        url: environment.API_URL + '/httpstat/200',
        buildData: async (data: FormData) => {
          await this.uploadFile(data.getAll('files[0]')[0] as unknown as File);
          data.delete('files');
          return {};
        },
        process: (resp) => {
          return {
            files: [this.uploadPath],
            baseurl: '',
          };
        },
        getDisplayName: (_, name) => this.fileName,
        isSuccess: () => {
          return true;
        },
      },
      style: {
        fontFamily: 'Arial',
      },
      allowResizeTags: ['img', 'table', 'placeholder', 'jodit', 'iframe'],
      showWordsCounter: false,
      showCharsCounter: false,
      cleanHTML: {
        removeEmptyElements: false,
        fillEmptyParagraph: false,
        denyTags: {
          script: true,
        },
      },
      controls: {
        classSpan: {
          list: {
            roman: 'Römische Zahlen',
          },
        },
      },
      disablePlugins: 'add-new-line',
      askBeforePasteFromWord: false,
      defaultActionOnPasteFromWord: 'insert_clear_html',
    };
    if (this.stringInput) {
      config.extraButtons = [];
    }
    this.editor = Jodit.make(this.editorContainer.nativeElement, config);
    const editorWrapper = document.createElement('div');
    editorWrapper.className = 'format-outline';
    this.editor.editor.parentNode.insertBefore(
      editorWrapper,
      this.editor.editor
    );
    editorWrapper.addEventListener('wheel', (e) => {
      if (this.tagInputOpen || this.isOrbOpen) {
        e.preventDefault();
      }
    });
    editorWrapper.appendChild(this.editor.editor);
    this.applyOptions();

    this.auth.jwtToken$.subscribe((token) => {
      this.text = wrapInJWT(
        new DOMParser().parseFromString(this.text, 'text/html'),
        token
      ).body.innerHTML;
      this.textChange.emit(this.text);
      this.updateText();
    });
  }

  private initializeEvents(): void {
    this.editor.e.on('change', (newValue) => {
      this.text = newValue;
      this.textChange.emit(this.text);
      window.setTimeout(() => {
        applyWrapping(this.editor);
        if (this.dataSource || this.dataStore) {
          this.checkReferences();
        }
      }, 0);
    });

    this.editor.currentPlace.editor.addEventListener(
      'keydown',
      (event: KeyboardEvent) => {
        this.updateOrbOpen();
        if (event.key === '#') {
          if (!this.dataSource && !this.dataStore) return;
          this.insertTagInput();
          event.preventDefault();
          event.stopPropagation();
        }
      }
    );

    this.editor.currentPlace.editor.addEventListener('pointerup', (event) => {
      this.updateOrbOpen();
      const tableSelection: Set<HTMLTableCellElement> =
        this.editor?.getInstance('Table')?.selected;
      if (this.editor?.s.range.collapsed && !tableSelection.size) {
        this.orbPos.y = -2000;
        this.orbPos.x = -2000;
      } else {
        let rangePos;
        if (tableSelection.size) {
          rangePos = [...tableSelection][0].getBoundingClientRect();
        } else {
          rangePos = this.editor?.s?.range?.getBoundingClientRect();
        }
        const parentRect =
          this.cursorPosRef.nativeElement.parentNode.getBoundingClientRect();
        this.orbPos.y =
          (rangePos?.top || 0) + (rangePos?.height || 0) / 2 - parentRect.top;
        this.orbPos.x = (rangePos?.left || 0) - parentRect.left - 20;
      }
      if (
        (event.target as HTMLElement)?.classList.contains('conditional-add')
      ) {
        this.clickOnConditionalAdd(event.target as HTMLElement);
        return;
      }
      window.setTimeout(() => {
        this.currentTagNode = null;
        this.currentCondition = null;
        this.selectConditions(undefined);
        this.currentTagNodeChange.emit(this.currentTagNode);
        this.currentConditionChange.emit(this.currentCondition);
      }, 550);
    });
  }

  private uploadFile(file: File): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      // ToDo: Set location and stateid if this is uploaded from inside a thread
      uploadFile(file, null, {}, this.files, [], false)
        .then((fileIdentifierObject) => {
          this.fileName = file.name;
          this.uploadPath = `${environment.API_URL}/file/${fileIdentifierObject.file_identifier}?adv_tk_gen=<token>`;
          resolve();
        })
        .catch((error) => {
          if (error.file_identifier) {
            this.fileName = file.name;
            this.uploadPath = `${environment.API_URL}/file/${error.file_identifier}?adv_tk_gen=<token>`;
            resolve();
          } else {
            reject();
            throw new Error(error);
          }
        });
    });
  }

  private updateText(): void {
    if (!this.editor || !this.editor.element) {
      return;
    }
    this.editor.setElementValue(this.text);
    window.setTimeout(() => {
      this.checkReferences();
    }, 0);
  }

  checkReferences(): void {
    Array.prototype.forEach.call(
      this.editor?.editor?.querySelectorAll('placeholder') ?? [],
      (el) => {
        const path = getDeepPath(el);
        let fittingNode = this.dataSource?.find((n) =>
          n.node.refIds?.includes(path[0])
        );
        const fittingFunction = Object.keys(SPECIAL_FUNCTIONS).find(
          (f) => f === path[0]
        );
        let reason = 'Diese Bezeichnung konnte nicht gefunden werden.';
        if (fittingNode && this.ownNode) {
          const isSignature = fittingNode.node.identifier === 'SignNode';
          let pathInProcess: [ProcessNode, number][];
          if (isSignature) {
            pathInProcess = pathFromTo(
              this.ownNode,
              fittingNode,
              this.dataSource
            );
          } else {
            pathInProcess = pathFromTo(
              fittingNode,
              this.ownNode,
              this.dataSource
            );
          }
          if (!pathInProcess || pathInProcess.length <= 0) {
            reason = 'Dieses Feld ist an dieser Stelle potentiell undefiniert.';
          } else {
            reason = undefined;
          }
        }
        if (fittingNode) {
          el.className = `found${reason ? ' warning' : ''}`;
          el.setAttribute('title', reason ?? '');
          let icon = el.querySelector('icon');
          if (!icon) {
            icon = document.createElement('icon');
            el.insertBefore(icon, el.firstChild);
          }
          icon.innerHTML =
            fittingNode.node.selectedMode?.icon || fittingNode.node.icon;
          if (
            icon.innerHTML !== 'drive_file_rename_outline' &&
            icon.innerHTML !== 'folder'
          ) {
            el.addEventListener('pointerup', (event) => {
              event.stopPropagation();
              this.previewById(
                getDeepPath(event.target)[0],
                getDeepPath(event.target)[0]
              );
              window.setTimeout(() => {
                this.editor.__plugins.resizer.hide();
              }, 0);
            });
          }
          el.style.setProperty(
            '--base-color',
            fittingNode.node.style.backgroundColor
          );
        } else if (fittingFunction) {
          el.className = `found`;
          let icon = el.querySelector('icon');
          if (!icon) {
            icon = document.createElement('icon');
            el.insertBefore(icon, el.firstChild);
          }
          icon.innerHTML = 'functions';
          if (
            icon.innerHTML !== 'drive_file_rename_outline' &&
            icon.innerHTML !== 'folder'
          ) {
            el.addEventListener('pointerup', (event) => {
              event.stopPropagation();
              window.setTimeout(() => {
                this.editor.__plugins.resizer.hide();
              }, 0);
            });
          }
          el.style.setProperty('--base-color', '#e38715');
        } else {
          el.className = 'not-found';
          el.setAttribute('title', reason);
        }
      }
    );
  }

  previewById(id: string, oldRefId: string): void {
    const question = this.dataSource.find((node) =>
      node?.node?.refIds?.includes(id)
    );
    if (question?.node) {
      this.selectConditions(undefined);
      this.currentCondition = null;
      this.currentTagNode = {
        node: question,
        oldRefId,
      };
      this.currentConditionChange.emit(this.currentCondition);
      this.currentTagNodeChange.emit(this.currentTagNode);
    }
  }

  saveNodeRefId(node: ProcessNode, oldRefId: string) {
    if (node.node.refId && oldRefId) {
      Array.prototype.forEach.call(
        this.editor.editor.querySelectorAll('placeholder'),
        (el) => {
          if (getDeepPath(el)[0] !== oldRefId) {
            return;
          }
          const path = getDeepPath(el);
          el.innerHTML = `<icon>${node.node.icon}</icon>${node.node.refId}${
            path.length > 1
              ? path
                  .slice(1)
                  .reverse()
                  .reduce(
                    (acc, slice) =>
                      `<span class='subpath'>${slice}${acc}</span>`,
                    ''
                  )
              : ''
          }`;
        }
      );
      Array.prototype.forEach.call(
        this.editor.editor.querySelectorAll('span.conditional-add'),
        (el) => {
          const curval = JSON.parse(
            el.getAttribute('eb-conditions') || 'null'
          ) || {
            refId: '',
            operator: '=',
            value: '',
          };
          let conditions = curval;
          if (!Array.isArray(curval)) {
            conditions = [[curval]];
          }
          conditions = conditions.map((condition) =>
            Array.isArray(condition) ? condition : [condition]
          );
          conditions.forEach((group) =>
            group.forEach((condition) =>
              condition?.refId === oldRefId
                ? (condition.refId = node.node.refId)
                : null
            )
          );
          this.saveCondition(el, conditions);
        }
      );
    }
  }

  /** Tag inputs */

  private insertTagInput(): void {
    this.tagInputOpen = true;
    this.editor.s.save();
    const range = this.editor.s.range;
    const tmpNode = document.createTextNode('\ufeff');
    range.insertNode(tmpNode);
    const rect = getRectWithZoom(range);
    tmpNode.remove();

    const parentRect = getRectWithZoom(this.editor.container);

    const offsetX = rect.left - parentRect.left;
    const offsetY = rect.top - parentRect.top + 5;
    const positionStrategy = this._overlayPositionBuilder
      .flexibleConnectedTo(this.editor.container)
      .withPositions([
        {
          originX: 'start',
          originY: 'top',
          overlayX: 'start',
          overlayY: 'top',
          offsetX,
          offsetY,
        },
      ]);

    const _overlayRef = this._overlay.create({ positionStrategy });
    const component: ComponentRef<RefidInputComponent> = _overlayRef.attach(
      new ComponentPortal(RefidInputComponent)
    );

    component.instance.dataSource = this.dataSource;
    component.instance.dataStore = this.dataStore;
    component.instance.ownNode = this.ownNode;
    component.instance.destroy.subscribe((value) => {
      this.editor.setReadOnly(true);
      this.tagInputOpen = false;
      component.destroy();
      if (!value?.text?.length) {
        if (value.directValue) {
          this.editor.s.insertHTML(value.directValue, true);
        } else {
          this.editor.s.insertHTML('&nbsp;', true);
        }
        this.editor.setReadOnly(false);
      } else {
        const path = value.text.split(/>|&gt;/);
        this.editor.s.insertHTML(
          `<placeholder contenteditable='false'><icon contenteditable='false'></icon>${
            path[0]
          }${
            path.length > 1
              ? path
                  .slice(1)
                  .reverse()
                  .reduce(
                    (acc, slice) =>
                      `<span class='subpath'>${slice}${acc}</span>`,
                    ''
                  )
              : ''
          }</placeholder>&#160;`,
          true
        );
        this.editor.s.restore();
        window.setTimeout(() => {
          this.editor.setReadOnly(false);
          this.checkReferences();
          this.editor.selection.focus();
        }, 0);
      }
    });
  }

  /** Conditions */

  updateOrbOpen() {
    if (!this.dataSource) {
      this.isOrbOpen = false;
      return;
    }
    const tableSelection: Set<HTMLTableCellElement> =
      this.editor?.getInstance('Table')?.selected;
    if (tableSelection?.size) {
      this.isOrbOpen = true;
      return;
    } else if (this.editor?.s?.range) {
      this.isOrbOpen = !this.editor.s.range.collapsed || false;
      return;
    } else {
      this.isOrbOpen = false;
      return;
    }
  }

  addConditionToSelection(): void {
    const e = this.editor;
    const tableSelection: Set<HTMLTableCellElement> =
      e?.getInstance('Table')?.selected;
    let curid = sha256(Date.now().toString());
    while (
      this.editor?.editor?.querySelectorAll(
        `.conditional-add[eb-cond-id="${curid}"]`
      )?.length
    ) {
      curid = sha256(Date.now().toString());
    }
    this.currentCondition = {
      cond: [[{ refId: '', operator: '', value: '' }]],
      elmnt: null,
      id: curid,
      selection: e.s,
    };
    if (tableSelection?.size) {
      this.currentCondition.cells = [...tableSelection];
    }
    this.currentConditionChange.emit(this.currentCondition);
  }

  addCondition(): void {
    let tags;
    if (this.currentCondition?.cells) {
      tags = this.currentCondition.cells;
    } else {
      this.currentCondition.selection.restore();
      const range: Range = this.currentCondition.selection.range;
      const setStyle = (style: 'none' | null) => {
        (range.commonAncestorContainer as HTMLElement)
          .querySelectorAll('placeholder')
          .forEach((p: HTMLElement) => {
            p.style.display = style;
          });
      };
      setStyle('none');
      this.currentCondition.selection.restore();
      tags = this.currentCondition.selection.wrapInTag('span');
      this.currentCondition.selection.restore();
      setStyle(null);
    }
    let id = sha256(Date.now().toString());
    while (
      this.editor?.editor?.querySelectorAll(
        `.conditional-add[eb-cond-id="${id}"]`
      )?.length
    ) {
      id = sha256(Date.now().toString());
    }
    tags.forEach((tag) => {
      tag.setAttribute(
        'eb-conditions',
        JSON.stringify(this.currentCondition.cond)
      );
      tag.setAttribute('eb-cond-id', id);
      tag.classList.add('conditional-add');
    });
    this.selectConditions(id);
    this.currentCondition.selection = null;
    delete this.currentCondition.cells;
    this.currentCondition.elmnt = tags[0];
    this.currentCondition.id = id;
  }

  saveCondition(id: string, event): void {
    const els = this.editor.editor.querySelectorAll(
      `.conditional-add[eb-cond-id="${id}"]`
    );
    this.selectConditions(id);
    els?.forEach((elmnt) =>
      elmnt.setAttribute('eb-conditions', JSON.stringify(event))
    );
  }

  removeCurrentCondition(): void {
    if (!this.currentCondition) {
      return;
    }
    const id = this.currentCondition.id ?? 'undefined';
    const els = [
      this.currentCondition.elmnt,
      ...this.editor.editor.querySelectorAll(
        `.conditional-add[eb-cond-id="${id}"]`
      ),
    ];
    els.forEach((el) => {
      switch (el.tagName) {
        case 'TD':
          el.classList.remove('conditional-add');
          el.removeAllListeners('eb-conditions');
          break;
        default:
          Jodit.modules.Dom['unwrap'](el);
      }
    });
    // this.detailsOpen = false;
    window.setTimeout(() => {
      this.selectConditions(undefined);
      this.currentCondition = null;
      this.currentConditionChange.emit(this.currentCondition);
    }, 550);
  }

  private selectConditions(id: string) {
    this.editor?.editor
      ?.querySelectorAll(`.conditional-add`)
      ?.forEach((e) => e.classList.remove('selected'));
    if (!id) return;
    this.editor?.editor
      ?.querySelectorAll(`.conditional-add[eb-cond-id="${id}"]`)
      ?.forEach((e) => e.classList.add('selected'));
  }

  private clickOnConditionalAdd(target: HTMLElement): void {
    this.currentTagNode = null;
    this.currentTagNodeChange.emit(this.currentTagNode);
    const curval = JSON.parse(
      target?.getAttribute('eb-conditions') || 'null'
    ) || {
      refId: '',
      operator: '=',
      value: '',
    };
    let curid = target?.getAttribute('eb-cond-id');
    if (!curid) {
      curid = sha256(Date.now().toString());
      while (
        this.editor?.editor?.querySelectorAll(
          `.conditional-add[eb-cond-id="${curid}"]`
        )?.length
      ) {
        curid = sha256(Date.now().toString());
      }
    }
    let conditions = curval;
    if (!Array.isArray(curval)) {
      conditions = [[curval]];
    }
    conditions = conditions.map((condition) =>
      Array.isArray(condition) ? condition : [condition]
    );
    this.selectConditions(curid);
    this.currentCondition = { cond: conditions, elmnt: target, id: curid };
    this.currentConditionChange.emit(this.currentCondition);
  }

  /** Utility functions */

  convertDocxToHTML(file: ArrayBuffer): Promise<string> {
    (window as any).Buffer = (window as any).Buffer || require('buffer').Buffer;
    return new Promise<string>(async (resolve) => {
      const mammoth = await import('mammoth');
      mammoth
        .convertToHtml(
          {
            arrayBuffer: file,
          },
          {
            includeEmbeddedStyleMap: true,
            convertImage: mammoth.images.imgElement(function (image) {
              return image.read('base64').then(function (imageBuffer) {
                return {
                  src: 'data:' + image.contentType + ';base64,' + imageBuffer,
                };
              });
            }),
          }
        )
        .then((data) => {
          resolve(data.value);
        });
    });
  }

  applyOptions() {
    const target = this.editor?.editor;
    if (!target) return;

    if (this.options?.letterhead?.value?.id) {
      const src = `url("${environment.API_URL}/file/${this.options.letterhead.value.id}?adv_tk_gen=${this.auth.jwtToken$.value}")`;
      target.style.backgroundImage = src;
    } else if (this.options?.letterhead?.id) {
      const src = `url("${environment.API_URL}/file/${this.options.letterhead.id}?adv_tk_gen=${this.auth.jwtToken$.value}")`;
      target.style.backgroundImage = src;
    } else if (
      this.options?.letterhead &&
      _.isString(this.options.letterhead)
    ) {
      const src = `url("${environment.API_URL}/file/${this.options.letterhead}?adv_tk_gen=${this.auth.jwtToken$.value}")`;
      target.style.backgroundImage = src;
    } else {
      target.style.backgroundImage = null;
    }

    if (this.options?.margins) {
      target.style.paddingTop = `${this.options.margins?.[0] ?? 20}mm`;
      target.style.paddingLeft = `${this.options.margins?.[1] ?? 13}mm`;
      target.style.paddingBottom = `${this.options.margins?.[2] ?? 20}mm`;
      target.style.paddingRight = `${this.options.margins?.[3] ?? 13}mm`;
    } else {
      target.style.paddingTop =
        target.style.paddingLeft =
        target.style.paddingBottom =
        target.style.paddingRight =
          null;
    }
  }
}
