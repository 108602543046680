import { Component, Inject } from '@angular/core';
import { DialogService } from 'src/app/widgets/dialog/dialog.service';
import { environment } from 'src/environments/environment';
import { token } from './token';
import { SettingInputData } from '../node-setting.component';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-file-input',
  template: `
    <div class="file-input">
      <div class="rich-label">{{ data.logicRef.name | translate }}</div>
      <div class="uploaded-view" *ngIf="data.logicRef.value?.value?.id">
        <div class="preview">
          <img
            [src]="
              API_URL +
              '/file/' +
              data.logicRef.value?.value?.id +
              '?adv_tk_gen=' +
              auth.jwtToken$.value
            "
          />
        </div>
        <button
          mat-icon-button
          (click)="data.logicRef.value.value.id = undefined"
        >
          <mat-icon>delete</mat-icon>
        </button>
      </div>
      <button
        mat-raised-button
        (click)="uploadFile()"
        *ngIf="!data.logicRef.value?.value?.id"
      >
        {{ 'node.names.mailAttachments.upload' | translate }}
      </button>
    </div>
  `,
  styles: [],
})
export class FileInputComponent {
  API_URL = environment.API_URL;

  constructor(
    private dialog: DialogService,
    @Inject(token) public data: SettingInputData,
    public auth: AuthService
  ) {}

  uploadFile() {
    this.dialog
      .promptFile('Datei', {
        stateId: null,
        isPublic: true,
      })
      .then(
        (newFile) => {
          if (newFile?.id && newFile.id !== '') {
            this.data.logicRef.value.value = newFile;
          }
        },
        () => {}
      );
  }
}
