import { NgModule } from '@angular/core';
import { ProfileEditComponent } from './profile-edit/profile-edit.component';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { WidgetsModule } from '../widgets/widgets.module';
import { PipesModule } from '../pipes/pipes.module';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { HeaderDirective } from './header.directive';
import { PermissionEditComponent } from './permission-edit/permission-edit.component';
import { DossierExportDialogComponent } from './dossier-export-dialog/dossier-export-dialog.component';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { SearchMenuModule } from '../widgets/search-menu/search-menu.module';

@NgModule({
  declarations: [
    ProfileEditComponent,
    FooterComponent,
    HeaderComponent,
    HeaderDirective,
    PermissionEditComponent,
    DossierExportDialogComponent,
  ],
  exports: [
    ProfileEditComponent,
    FooterComponent,
    HeaderComponent,
    HeaderDirective,
    DossierExportDialogComponent,
  ],
  providers: [],
  imports: [
    CommonModule,
    TranslateModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
    NgScrollbarModule,
    MatSnackBarModule,
    WidgetsModule,
    PipesModule,
    MatMenuModule,
    MatDialogModule,
    MatSelectModule,
    MatCheckboxModule,
    SearchMenuModule,
  ],
})
export class CommonSilberflussModule {}
