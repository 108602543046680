import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Pagination } from 'src/api';
@Component({
  selector: 'app-table-paginator',
  templateUrl: './table-paginator.component.html',
  styleUrls: ['./table-paginator.component.scss'],
})
export class TablePaginatorComponent {
  @Input() pagination: Pagination;
  @Input() totalElements: number = 0;

  @Output() paginate = new EventEmitter<Pagination>();

  get pageRangeLabel(): string {
    const end = Math.min(
      this.pagination.page * this.pagination.rows_per_page,
      this.totalElements
    );
    const start = Math.min(
      (this.pagination.page - 1) * this.pagination.rows_per_page + 1,
      end
    );
    return `${start} - ${end}`;
  }

  paginatePrev() {
    if (this.pagination?.page <= 1) return;
    this.pagination.page -= 1;
    this.paginate.emit(this.pagination);
  }

  paginateNext() {
    const end = this.pagination.page * this.pagination.rows_per_page;
    if (end >= this.totalElements) return;
    this.pagination.page += 1;
    this.paginate.emit(this.pagination);
  }

  setPageSize(size: number) {
    this.pagination.rows_per_page = size;
    this.paginate.emit(this.pagination);
  }
}
