<ng-container *ngIf="!detailUser; else detailView">
  <div class="tabs">
    <div class="tab" [class.active]="activeView === 'clients'" (click)="activeView = 'clients'" tabindex="0"
      (keyup.enter)="activeView = 'clients'" *ngIf="canRead('clients')">
      <mat-icon>people</mat-icon>
      <span>{{ "lawyer.dashboard.clients.externalContacts" | translate }}</span>
    </div>
    <div class="tab" [class.active]="activeView === 'lawyers'" (click)="activeView = 'lawyers'" tabindex="0"
      (keyup.enter)="activeView = 'lawyers'" *ngIf="canRead('lawyers')">
      <mat-icon>engineering</mat-icon>
      <span>{{ "lawyer.dashboard.clients.internalContacts" | translate }}</span>
    </div>
    <!-- <div class="tab" [class.active]="activeView === 'groups'" (click)="activeView = 'groups'" tabindex="0"
      (keyup.enter)="activeView = 'groups'" *ngIf="canRead('groups')">
      <mat-icon>groups_2</mat-icon>
      <span>{{ "lawyer.dashboard.clients.groups" | translate }}</span>
    </div> -->
  </div>
  <ng-scrollbar>
    <div class="view-contents-container">
      <div class="view-contents" *ngIf="activeView === 'clients'" [@fadeAnimation]>
        <div class="sketch-actions">
          <button mat-button (click)="addClient()">
            <mat-icon class="button-icon">add</mat-icon>
            <span>{{ "lawyer.dashboard.clients.new" | translate }}</span>
          </button>
          <!-- <button mat-button (click)="exportClientData()">
            <mat-icon class="button-icon">import_export</mat-icon>
            <span>{{ "lawyer.dashboard.clients.export" | translate }}</span>
          </button> -->
        </div>
        <app-table [config]="externalTableConfig" *ngIf="externalTableConfig" #tableRef></app-table>
      </div>
      <div class="view-contents" *ngIf="activeView === 'lawyers'" [@fadeAnimation]>
        <div class="sketch-actions">
          <button mat-button (click)="addLawyer()">
            <mat-icon class="button-icon">add</mat-icon>
            <span>{{
              "lawyer.dashboard.clients.newInternal" | translate
              }}</span>
          </button>
        </div>
        <app-table [config]="internalTableConfig" *ngIf="internalTableConfig" #tableRef></app-table>
      </div>
      <div class="view-contents" *ngIf="activeView === 'groups'" [@fadeAnimation]>
        <div class="sketch-actions">
          <button mat-button (click)="addGroup()">
            <mat-icon class="button-icon">add</mat-icon>
            <span>{{ "lawyer.dashboard.clients.newGroup" | translate }}</span>
          </button>
        </div>
        <app-table [config]="groupTableConfig" *ngIf="groupTableConfig" #tableRef></app-table>
      </div>
    </div>
  </ng-scrollbar>
</ng-container>
<ng-template #detailView>
  <app-profile-edit [user]="detailUser"></app-profile-edit>
</ng-template>