/**
 * Silberfluss API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.10
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Role } from './role';
import { PermissionRequest } from './permissionRequest';
import { Variable } from './variable';


export interface LawyerRequest { 
    additional_info?: object;
    auth_token?: string;
    first_name?: string;
    full_name?: string;
    last_name?: string;
    mail?: string;
    permissions?: Array<PermissionRequest>;
    roles?: Array<Role>;
    variables?: Array<Variable>;
}

