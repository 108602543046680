import { Component, Inject, Optional } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ProcessTrigger } from 'src/api';

export interface TriggerSetupDialogData {
  trigger: Partial<ProcessTrigger>;
}

interface TriggerSetupForm {
  scope: FormControl<string>;
  name_pattern: FormControl<string>;
  value_pattern: FormControl<string>;
  repeatable: FormControl<boolean>;
  capture_existing: FormControl<boolean>;
}

@Component({
  selector: 'app-trigger-setup-dialog',
  templateUrl: './trigger-setup-dialog.component.html',
  styleUrls: ['./trigger-setup-dialog.component.scss'],
})
export class TriggerSetupDialogComponent {
  typeFormControl = new FormControl('variable.change');

  configForm = new FormGroup<TriggerSetupForm>({
    scope: new FormControl('client'),
    name_pattern: new FormControl(''),
    value_pattern: new FormControl(''),
    repeatable: new FormControl(false),
    capture_existing: new FormControl(false),
  });

  constructor(
    @Optional()
    public dialogRef: MatDialogRef<TriggerSetupDialogComponent, ProcessTrigger>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: TriggerSetupDialogData
  ) {
    this.typeFormControl.disable();
    this.configForm.get('scope').disable();

    if (this.data.trigger) {
      this.configForm.patchValue(this.data.trigger.parameters);
    }
  }

  save() {
    if (
      !this.data?.trigger?.type ||
      (!this.configForm.get('name_pattern')?.value?.length &&
        !this.configForm.get('value_pattern')?.value?.length)
    ) {
      this.dialogRef.close();
      return;
    }
    const val: Partial<ProcessTrigger> = {
      type: this.data.trigger.type,
      parameters: this.configForm.getRawValue(),
    };
    if (this.data.trigger?.id) {
      val.id = this.data?.trigger?.id;
    }
    this.dialogRef.close(val);
  }
}
