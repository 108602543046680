import {
  HardCodedUser,
  ParsedAssignedUser,
  Thread,
  parseUserReference,
} from 'advoprocess';
import { AuthService } from 'src/app/auth/auth.service';
import { isParseAssignedUser } from 'src/app/common/helpers';
import { ProcessService } from './process.service';
import { TranslateService } from '@ngx-translate/core';
import { Thread as APIThread, ExecutionStateParticipant } from 'src/api';
import { Activity } from './process-path';
import * as _ from 'lodash';

export function createUserReference(auth: AuthService): string {
  let usertype: 'USER' | 'MAGIC';
  let usrsrc: string;
  let info: { [key: string]: any };
  if (auth.loggedIn) {
    usertype = 'USER';
    usrsrc = auth.isClient ? 'client' : 'lawyer';
    info = {
      uuid: auth.userId,
      mail: auth.userMail,
      name: auth.userName,
    };
  } else {
    usertype = 'MAGIC';
    usrsrc = 'CLIENT'; // Discussion: What if we want to use the public forms for other things than CLIENT onboarding?
    info = {};
  }
  return `<<[${usertype}:${usrsrc}]::${JSON.stringify(info)}>>`;
}

export function assignedNames(
  thread: Thread,
  service: ProcessService,
  translator: TranslateService,
  defaultString?: string
): string {
  const apiThread = service.scopesAndThreads.threads.find(
    (t) => t.id === thread.id
  );

  return parseFromAssigned(apiThread?.assigned, translator, defaultString, [
    'answer',
  ]);
}

export function parseFromAssigned(
  assigned: string[],
  translator: TranslateService,
  defaultString?: string,
  filterPermission = ['answer', 'read', 'write', null, undefined]
): string {
  const all =
    assigned
      ?.map((part) => (part === 'bot' ? 'bot' : parseUserReference(part)))
      .filter(
        (n) => !!n && (n === 'bot' || filterPermission.includes(n.permission))
      )
      .map((parsed) => {
        if (parsed === 'bot') return translator.instant('common.assigned.bot');
        if (isParseAssignedUser(parsed)) {
          if (parsed.type === 'role') {
            return `${translator.instant('common.assigned.theGroup')} "${
              parsed?.name ?? parsed.mail
            }"`;
          } else {
            return parsed?.name ?? parsed.mail;
          }
        } else {
          return translator.instant('common.assigned.the' + parsed.target);
        }
      }) ?? [];
  return all.length > 1
    ? `${all.slice(0, -1)} ${translator.instant('common.label.and')} ${
        all[all.length - 1]
      }`
    : all[0] ??
        translator.instant(
          defaultString?.length ? defaultString : 'common.label.nobody'
        );
}

export interface InitialsDefinition {
  type: 'initials' | 'icon' | 'profile-pic';
  label: string;
}

export interface TooltipDefinition {
  name: string;
  id?: string;
  mail?: string;
  permission: 'read' | 'write' | 'answer';
  initials: InitialsDefinition;
  originalAssigned: string;
  parsedAssigned: HardCodedUser | ParsedAssignedUser;
}

function initialsFor(
  user: ParsedAssignedUser | HardCodedUser,
  currentlySelected: ExecutionStateParticipant | undefined
): InitialsDefinition {
  let type: InitialsDefinition['type'];
  let label: InitialsDefinition['label'];
  if (isParseAssignedUser(user)) {
    if (user.type === 'role') {
      type = 'icon';
      label = 'groups';
    } else {
      if (user.source === 'lawyer' && !!currentlySelected?.profile_picture) {
        type = 'profile-pic';
        label = currentlySelected?.profile_picture;
      } else {
        type = 'initials';
        label = (user.name + ` ${user.mail}`)
          .split(/\s+/gm)
          .map((s) => s?.[0]?.toUpperCase())
          .filter((n) => !!n)
          .join('')
          .slice(0, 2);
      }
    }
  } else {
    type = 'icon';
    if (user.source === 'client') {
      if (currentlySelected) {
        type = 'initials';
        label = [
          currentlySelected.first_name,
          currentlySelected.last_name,
          currentlySelected.mail,
        ]
          .map((s) => s?.[0]?.toUpperCase())
          .filter((n) => !!n)
          .join('')
          .slice(0, 2);
      } else {
        label = 'person';
      }
    } else {
      label = 'supervisor_account';
    }
  }
  return {
    type,
    label,
  };
}

export function usersTooltip(
  assigned: string[],
  translator: TranslateService,
  service: ProcessService
): (TooltipDefinition | '?')[] {
  return assigned.map((part) => {
    const parsed = parseUserReference(part);
    let currentlySelected: ExecutionStateParticipant | undefined;
    if (!parsed) return '?';
    let name, mail, id;
    if (isParseAssignedUser(parsed)) {
      name = parsed?.name ?? parsed.mail;
      mail = parsed?.mail;
      id = parsed.uuid;
      currentlySelected = service?.participants?.find((p) => p.id === id);
    } else {
      // if (this.service.participants)
      currentlySelected = service?.participants?.find(
        (p) => p.role === parsed.target
      );
      if (currentlySelected) {
        name = [currentlySelected.first_name, currentlySelected.last_name]
          .filter((n) => !!n)
          .join(' ');
        mail = currentlySelected.mail;
        id = currentlySelected.id;
      } else {
        name = translator.instant('common.assigned.' + parsed.target);
      }
    }
    return {
      id,
      name,
      mail,
      permission: parsed.permission,
      initials: initialsFor(parsed, currentlySelected),
      originalAssigned: part,
      parsedAssigned: parsed,
    };
  });
}

export function nameFromParticipants(
  thread: APIThread,
  translator: TranslateService,
  service: ProcessService
) {
  const nameFromParts = usersTooltip(thread.assigned, translator, service)
    .map((tooltip) => {
      if (typeof tooltip === 'string') {
        return tooltip;
      } else {
        return tooltip.name;
      }
    })
    .join(', ');
  if (!nameFromParts?.length) {
    return (
      service.executionState.name ??
      translator.instant('process.label.newThread')
    );
  }
  return nameFromParts;
}

export function ownStateRole(
  service: ProcessService,
  auth: AuthService
): string | undefined {
  if (!auth.loggedIn || service?.processDirectSource) {
    return 'CLIENT';
  }
  return service.participants?.find((p) => p.id === auth.userId)?.role;
}

export function allowCreateNewScope(
  child: Activity,
  service: ProcessService,
  auth: AuthService
): boolean {
  const myOwnStateRole = ownStateRole(service, auth);
  const scope = child?.scope;
  if (!scope) return false;
  const assigned = service?.scopesAndThreads?.scopes?.find(
    (sc) => sc.id === scope
  )?.assigned;
  if (!auth.isClient) return true;
  if (_.isNil(assigned)) return false;
  return assigned
    .map((a) => parseUserReference(a))
    .some((ref) => {
      if (isParseAssignedUser(ref)) {
        return (
          ref.uuid === auth.userId &&
          ref.source === (auth.isClient ? 'client' : 'lawyer')
        );
      } else {
        return myOwnStateRole.toLowerCase() === ref.target.toLowerCase();
      }
    });
}

export function canWriteThread(
  t: APIThread,
  auth: AuthService,
  service: ProcessService,
  targetPermission: 'answer' | 'write' = 'answer'
): boolean {
  if (!auth?.loggedIn) return false;
  if (!auth.isClient) return true;
  return (
    t?.assigned
      ?.map((a) => parseUserReference(a))
      ?.filter((ref) => isUserCurrent(ref, auth, service))
      ?.some((p) => p.permission === targetPermission) ?? false
  );
}

export function isUserCurrent(
  ass: ParsedAssignedUser | HardCodedUser,
  auth: AuthService,
  service: ProcessService
): boolean {
  if (isParseAssignedUser(ass)) {
    if (ass.type === 'role') {
      return auth.rawDecoded?.roles?.some((role) => role === ass.uuid);
    } else {
      return ass.uuid === auth.userId;
    }
  } else if (!ass) {
    return false;
  } else {
    if (ass.source === 'lawyer') return true;
    return ass.target === ownStateRole(service, auth);
  }
}
