<h1 mat-dialog-title translate>
  {{ data.title }}
</h1>

<mat-dialog-content>
  <div *ngIf="data?.content" translate>{{ data.content }}</div>
  <ng-container *ngIf="data.type === 'prompt' && !data.compInput">
    <mat-form-field appearance="fill" *ngIf="!data.allowRichText" (keydown.enter)="submit()"
      [class.mat-form-field-invalid]="data.validator && promptValidated !== true">
      <mat-label>{{ data.title | translate }}</mat-label>
      <input [placeholder]="data.placeholder" type="text" matInput class="main-text-input" [(ngModel)]="dataField"
        (keyup)="updateValidation()" />
    </mat-form-field>
    <mat-error *ngIf="data.validator && promptValidated !== true" [innerHTML]="(promptValidated ?
   promptValidated : (
    'common.error.genericInput' | translate )) | sanitizeHtml"></mat-error>
    <app-rich-text-editor *ngIf="data.allowRichText" [readonly]="false" [(text)]="dataField" [dataSource]="data.dataRef"
      [ownNode]="null" [buttons]="
        'font,fontsize,brush,|,bold,italic,underline,strikethrough,|,ul,ol,|,undo,redo'
      " [stringInput]="false">
    </app-rich-text-editor>
  </ng-container>
  <app-comparison-input *ngIf="data.type === 'prompt' && data.compInput" [dataSource]="data.dataRef"
    [condition]="data.value" (editNode)="editNode()"></app-comparison-input>
  <form class="edit-form" *ngIf="data.type === 'edit'">
    <ng-container *ngFor="let key of objectKeys(data.editData)">
      <ng-container *ngIf="
          !data.editData[key].hideIf ||
          !data.editData[key].hideIf(data.editData)
        ">
        <button mat-raised-button *ngIf="data.editData[key].type === 'button'" (click)="data.editData[key].handler()"
          [matTooltip]="data.editData[key].tooltip ? (data.editData[key].tooltip | translate) : undefined">
          {{ data.editData[key].name | translate }}
        </button>
        <mat-form-field appearance="fill" *ngIf="
            data.editData[key].type !== 'button' &&
            data.editData[key].type !== 'info' &&
            data.editData[key].type !== 'rich_text' &&
            data.editData[key].type !== 'icon' &&
            data.editData[key].type !== 'boolean' &&
            data.editData[key].type !== 'join'
          " [matTooltip]="data.editData[key].tooltip ? (data.editData[key].tooltip | translate) : undefined">
          <mat-label>{{ data.editData[key].name | translate }}</mat-label>
          <input matInput [disabled]="data.editData[key].disabled || false" [name]="key"
            [(ngModel)]="data.editData[key].value" [type]="data.editData[key].type"
            [required]="isRequired(data.editData[key])" *ngIf="data.editData[key].type !== 'dropdown'" [class.error]="
              data.editData[key].validator
                ? data.editData[key].validator(
                    data.editData[key].value || '',
                    data.editData
                  )
                  ? true
                  : false
                : false
            " />
          <mat-select *ngIf="data.editData[key].type === 'dropdown'" [required]="data.editData[key].required || false"
            [name]="key" [(ngModel)]="data.editData[key].value" [disabled]="data.editData[key].disabled || false">
            <mat-option *ngFor="let option of data.editData[key].options" [value]="option.value">
              {{ option.name | translate }}
            </mat-option>
          </mat-select>
          <span class="custom-dialog-error" *ngIf="
              data.editData[key].validator
                ? data.editData[key].validator(
                    data.editData[key].value || '',
                    data.editData
                  )
                  ? true
                  : false
                : false
            ">{{
            data.editData[key].validator(
            data.editData[key].value || "",
            data.editData
            )
            }}</span>
        </mat-form-field>
        <mat-slide-toggle [(ngModel)]="data.editData[key].value" [ngModelOptions]="{standalone: true}"
          *ngIf="data.editData[key].type === 'boolean'"
          [matTooltip]="data.editData[key].tooltip ? (data.editData[key].tooltip | translate) : undefined">{{
          data.editData[key].name | translate
          }}</mat-slide-toggle>
        <p *ngIf="data.editData[key].type === 'info'">
          {{ data.editData[key].name | translate }}
        </p>
        <div class="icon-input" *ngIf="data.editData[key].type === 'icon'">
          <app-icon-picker [label]="data.editData[key].name | translate"
            [(value)]="data.editData[key].value"></app-icon-picker>
        </div>
        <div class="join-options" *ngIf="data.editData[key].type === 'join' && data.editData[key].queryEntities">
          <span class="join-label"
            [matTooltip]="data.editData[key].tooltip ? (data.editData[key].tooltip | translate) : undefined"
            [matTooltipPosition]="'above'">
            {{ data.editData[key].name | translate }}<mat-icon *ngIf="data.editData[key].tooltip"
              class="tooltip-icon">help_outline</mat-icon></span>
          <div class="join-list" *ngIf="!data.editData[key].singleChoice">
            <div class="join-entry" *ngFor="let entry of data.editData[key].value">
              <span class="join-entry-name">
                {{ entry.name | translate }}
              </span>
              <button mat-icon-button (click)="removeJoinEntry(data.editData[key].value, entry)">
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </div>
          <app-search-menu [entries]="queryEntitiesWrapper(key)"
            (entrySelected)="!!data.editData[key].singleChoice ? ($event.value ? data.editData[key].value.splice(0, data.editData[key].value.length, $event) : data.editData[key].value.splice(0, data.editData[key].value.length)) : data.editData[key].value.push($event)">
            <button mat-button class="new-join-button" *ngIf="!data.editData[key].singleChoice">
              <mat-icon>add</mat-icon>
              {{ 'common.button.addEntry' | translate }}
            </button>
            <button mat-button class="single-choice-join-button" *ngIf="data.editData[key].singleChoice">
              <span class="join-entry-name" *ngIf="data.editData[key].value?.[0]?.name">{{
                data.editData[key].value?.[0]?.name | translate }}</span>
              <span class="join-entry-name-placeholder" *ngIf="!data.editData[key].value?.[0]?.name">{{
                'common.label.select' |
                translate }}</span>
              <mat-icon>arrow_drop_down</mat-icon>
            </button>
          </app-search-menu>
        </div>
      </ng-container>
    </ng-container>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <ng-container *ngFor="let button of data.actions">
    <button *ngIf="!button.defaultSelect" mat-button (click)="closeDialog(button)"
      [class.mat-raised-button]="button.raised" [disabled]="button.disableIf || (disableSubmit && button.accept)">
      <mat-icon *ngIf="button.icon">
        {{ button.icon }}
      </mat-icon>
      {{ button.text | translate }}
    </button>
    <button *ngIf="button.defaultSelect" mat-button (click)="closeDialog(button)"
      [class.mat-raised-button]="button.raised" [disabled]="button.disableIf || (disableSubmit && button.accept)"
      cdkFocusRegionStart>
      <mat-icon *ngIf="button.icon">
        {{ button.icon }}
      </mat-icon>
      {{ button.text | translate }}
    </button>
  </ng-container>
</mat-dialog-actions>