import { animate, style, transition, trigger } from '@angular/animations';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-keycloak-overlay',
  templateUrl: './keycloak-overlay.component.html',
  styleUrls: ['./keycloak-overlay.component.scss'],
  animations: [
    trigger('fadeAnimation', [
      transition(':enter', [
        style({ opacity: 0, transform: 'scale(0.9)' }),
        animate('150ms', style({ opacity: 1, transform: 'scale(1)' })),
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'scale(1)' }),
        animate('150ms', style({ opacity: 0, transform: 'scale(0.9)' })),
      ]),
    ]),
  ],
})
export class KeycloakOverlayComponent implements OnChanges {
  @Input() mode: 'login' | 'register' = undefined;
  keycloakLoginUrl: SafeResourceUrl;

  @Input() userMail: string = '';
  @Input() userFirstName: string = '';
  @Input() userLastName: string = '';

  @Output() login = new EventEmitter<void>();
  @Output() close = new EventEmitter<void>();

  constructor(
    private auth: AuthService,
    private activeRoute: ActivatedRoute,
    private sanitize: DomSanitizer
  ) {}

  get realm(): string {
    return this.activeRoute.snapshot.paramMap.get('realm');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.mode) {
      if (this.mode === 'login') {
        const url = this.auth.keycloak.createLoginUrl({
          locale: 'de',
          redirectUri: `${window.location.origin}/login?realm=${this.realm}`,
        });
        this.keycloakLoginUrl =
          this.sanitize.bypassSecurityTrustResourceUrl(url);
      } else if (this.mode === 'register') {
        let url = this.auth.keycloak.createRegisterUrl({
          locale: 'de',
          redirectUri: `${window.location.origin}/login?realm=${this.realm}`,
        });
        if (this.userMail?.length) {
          url += `&mail=${encodeURIComponent(this.userMail)}`;
        }
        if (this.userFirstName?.length) {
          url += `&first_name=${encodeURIComponent(this.userFirstName)}`;
        }
        if (this.userLastName?.length) {
          url += `&last_name=${encodeURIComponent(this.userLastName)}`;
        }
        this.keycloakLoginUrl =
          this.sanitize.bypassSecurityTrustResourceUrl(url);
      } else {
      }
    }
  }

  async iframeLoad() {
    const authenticated = await this.auth.initKeycloak(
      this.activeRoute.snapshot.paramMap.get('realm')
    );
    if (authenticated) {
      // await new Promise<void>((resolve) => {
      //   this.clientAPI
      //     .getUserById({
      //       userid: this.auth.userId,
      //     })
      //     .subscribe(async (user: User) => {
      //       this.clientService.userInfo = user;
      //       resolve();
      //     });
      // });
      this.login.emit();
    }
  }
}
