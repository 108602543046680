/**
 * Silberfluss API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.10
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type NotificationType = 'NEW_MESSAGE' | 'NEW_MENTION';

export const NotificationType = {
    Message: 'NEW_MESSAGE' as NotificationType,
    Mention: 'NEW_MENTION' as NotificationType
};

