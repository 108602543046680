<app-header [isEmbed]="isEmbed"></app-header>
<content class="main-content">
  <router-outlet></router-outlet>
</content>
<button mat-button class="feedback-button" *ngIf="auth.loggedIn && !auth.isClient && !showFeedback"
  (click)="toggleFeedback()">
  <mat-icon>thumbs_up_down</mat-icon>
  <span class="hvr">{{ 'common.button.feedback' | translate }}</span>
</button>
<div class="feedback-popup" *ngIf="showFeedback" (click)="showFeedback = false">
  <div class="feedback-content" (click)="$event.stopPropagation()">
    <div class="header">
      <strong>{{ 'common.button.feedback' | translate }}</strong>
      <button mat-icon-button (click)="showFeedback=false">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <iframe [src]="feedbackFormLink"></iframe>
  </div>
</div>
<app-footer [isEmbed]="isEmbed" [justOverlay]="auth.loggedIn && !auth.isClient"></app-footer>