import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import {
  ChangeDataRecord,
  DataRenderDialogComponent,
  DataRenderDialogConfig,
} from './data-render-dialog.component';
import {
  DialogAction,
  DialogComponent,
  EditDataType,
} from './dialog.component';
import { FileDialogComponent } from './file-dialog.component';
import {
  FileAttachment,
  FormattedDataAnswer,
  GeneratedDocument,
} from 'advoprocess';
import {
  CreateDocumentDialog,
  CreateDocumentDialogData,
} from './create-document-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  constructor(private matDialog: MatDialog) {}

  private save: () => void;

  saveFile(save: () => void) {
    this.save = save;
  }

  prompt(
    text: string,
    config?: {
      value?: any;
      mandatory?: boolean;
      compInput?: boolean;
      dataRef?: any;
      allowRichText?: boolean;
      validator?: (value: string) => boolean | string;
    }
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const dialogRef = this.matDialog.open(DialogComponent, {
        width: '650px',
        disableClose: config?.mandatory || false,
        data: {
          title: text,
          value: config?.value || null,
          compInput: config?.compInput || false,
          dataRef: config?.dataRef || null,
          allowRichText: config?.allowRichText || false,
          validator: config?.validator || null,
          type: 'prompt',
          actions: [
            {
              text: 'common.button.abort',
              disableIf: config?.mandatory || false,
            },
            {
              text: 'common.button.ok',
              raised: true,
              accept: true,
            },
          ],
        },
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result === null || result === undefined) {
          reject(result);
          return;
        }
        resolve(result);
      });
    });
  }

  promptFile(
    text: string,
    config?: {
      stateId?: string;
      folder?: string;
      isPublic?: boolean;
    }
  ): Promise<FileAttachment> {
    return new Promise((resolve, reject) => {
      const dialogRef = this.matDialog.open<FileDialogComponent>(
        FileDialogComponent,
        {
          width: '650px',
          data: {
            title: text,
            stateId: config?.stateId || null,
            folder: config?.folder || false,
            isPublic: config?.isPublic || false,
          },
        }
      );
      dialogRef.afterClosed().subscribe((result) => {
        if (result === null || result === undefined) {
          reject(result);
          return;
        }
        resolve(result);
      });
    });
  }

  promptDocument(
    options?: CreateDocumentDialogData
  ): Promise<GeneratedDocument> {
    return new Promise((resolve, reject) => {
      const dialogRef = this.matDialog.open<CreateDocumentDialog>(
        CreateDocumentDialog,
        {
          width: '650px',
          data: options ?? {},
        }
      );
      dialogRef.afterClosed().subscribe((result) => {
        resolve(result);
      });
    });
  }

  confirm({
    text,
    title,
    addtionalButtons = [],
    buttons,
    width,
  }: {
    text: string;
    title?: string;
    addtionalButtons?: DialogAction[];
    buttons?: DialogAction[];
    width?: string;
  }): Promise<boolean | any> {
    return new Promise((resolve) => {
      const dialogRef = this.matDialog.open(DialogComponent, {
        width: width ?? '450px',
        disableClose: true,
        data: {
          title: title ?? text,
          content: title ? text : '',
          type: 'confirm',
          actions: buttons ?? [
            {
              text: 'common.label.no',
            },
            ...addtionalButtons,
            {
              text: 'common.label.yes',
              raised: true,
              accept: true,
            },
          ],
        },
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result === null || result === undefined) {
          resolve(false);
          return;
        }
        resolve(result);
      });
    });
  }

  edit<T>(title: string, data: EditDataType): Promise<EditDataType> {
    return new Promise<EditDataType>((resolve, reject) => {
      const dialogRef = this.matDialog.open(DialogComponent, {
        width: '450px',
        disableClose: true,
        data: {
          title,
          type: 'edit',
          editData: data,
          actions: [
            {
              text: 'common.button.abort',
            },
            {
              text: 'common.button.save',
              raised: true,
              accept: true,
            },
          ],
        },
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result === null || result === undefined) {
          reject();
          return;
        }
        resolve(result);
      });
    });
  }

  editVariables(
    data: {
      name: string;
      type: string;
      value: any;
      id: string;
    }[],
    validator?: (values: FormattedDataAnswer[]) => boolean | string,
    getOptions?: (
      variable: FormattedDataAnswer,
      all: FormattedDataAnswer[]
    ) => any[] | '*',
    label?: string
  ): Promise<ChangeDataRecord> {
    return new Promise<ChangeDataRecord>((resolve) => {
      const dialogRef = this.matDialog.open<
        DataRenderDialogComponent,
        DataRenderDialogConfig
      >(DataRenderDialogComponent, {
        width: '1550px',
        disableClose: true,
        data: {
          data: data,
          validator,
          getOptions,
          label,
        },
      });
      dialogRef.afterClosed().subscribe((changes: ChangeDataRecord) => {
        resolve(changes);
      });
    });
  }
}
