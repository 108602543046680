import { Component, OnInit, ViewChild } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { map, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import {
  ExecutionService,
  ExecutionState,
  FilterCriterium,
  FilterViewPagination,
  LegalProcess,
  LegalProcessService,
} from 'src/api';
import { executionStateFilters } from 'src/app/views/process/filters/execution-state-filters';
import { DialogService } from 'src/app/widgets/dialog/dialog.service';
import {
  TableComponent,
  TableConfig,
} from 'src/app/widgets/table/table.component';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject, timer } from 'rxjs';
import { MenuEntry } from 'advoprocess/lib/types/menu';
import { SketchParser } from 'advoprocess';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import {
  NewDossierDialogComponent,
  NewDossierDialogData,
} from '../../../common/new-dossier-dialog/new-dossier-dialog.component';
import { filterTerm, isFilterCriterium } from 'src/app/common/helpers';
import { PermissionsService } from 'src/app/auth/permissions.service';

@Component({
  selector: 'app-dossiers',
  templateUrl: './dossiers.component.html',
  styleUrls: ['./dossiers.component.scss'],
})
export class DossiersComponent {
  @ViewChild('tableRef') tableRef: TableComponent;

  tableConfig: TableConfig<ExecutionState> = getDossierTableConfig(
    this.service,
    this.router,
    this.route,
    this.dialog,
    this.snackBar,
    this.translator
  );

  constructor(
    private service: ExecutionService,
    private router: Router,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private dialog: DialogService,
    private matDialog: MatDialog,
    private translator: TranslateService,
    private processes: LegalProcessService,
    public permissions: PermissionsService
  ) {}

  async newDossier(initialData?: NewDossierDialogData) {
    this.matDialog
      .open<NewDossierDialogComponent, NewDossierDialogData>(
        NewDossierDialogComponent,
        {
          data: initialData ?? {},
        }
      )
      .afterClosed()
      .subscribe((stateId?: string) => {
        if (!stateId) return;
        this.tableRef.dataSourceObj.update();
        this.router.navigate([
          this.route.snapshot.paramMap.get('realm'),
          'intern',
          's',
          stateId,
          'messages',
        ]);
      });
  }

  addFromProcess(entry: MenuEntry<any>) {
    this.newDossier({
      initialName: entry.value.name ?? '',
      initialProcess: {
        id: entry.value.id,
        name: entry.value.name,
      },
    });
  }

  abortQuery$ = new Subject<void>();
  lastProcessesResult: MenuEntry<LegalProcess>[] = [];

  queryProcesses(searchTerm: string): Observable<MenuEntry<LegalProcess>[]> {
    this.abortQuery$.next();
    const processStates$ = timer(1000).pipe(
      switchMap(() =>
        this.processes.listProcesses({
          filterViewPagination: {
            filter: [
              {
                operand: 'node_template.info.name',
                operator: 'contains',
                value: searchTerm,
              },
            ],
            pagination: {
              page: 1,
              rows_per_page: 10,
            },
            view: {
              displayed_columns: [
                { display_name: 'id', internal_name: 'id' },
                {
                  display_name: 'name',
                  internal_name: 'node_template.info.name',
                },
              ],
            },
          },
        })
      ),
      map((resp) =>
        resp.legal_processes.map(
          (p: any) =>
            ({
              name: p.name,
              value: p,
            } as MenuEntry<LegalProcess>)
        )
      ),
      tap((x) => (this.lastProcessesResult = x)),
      startWith(this.lastProcessesResult),
      takeUntil(this.abortQuery$)
    );
    return processStates$.pipe(
      map((results) => {
        return _.flatten(results).filter((e) =>
          filterTerm(searchTerm, e, this.translator)
        );
      })
    );
  }
}

export function getDossierTableConfig(
  service: ExecutionService,
  router: Router,
  route: ActivatedRoute,
  dialog: DialogService,
  snackBar: MatSnackBar,
  translator: TranslateService,
  extraFilters?: () => FilterCriterium[]
): TableConfig<ExecutionState> {
  return {
    id: 'internal-dossiers',
    fetch: (params: FilterViewPagination) => {
      if (extraFilters) {
        params.filter = _.cloneDeep(params.filter);
        extraFilters().forEach((crit) => {
          if (
            params.filter.some(
              (c2) =>
                isFilterCriterium(c2) &&
                c2.operand === crit.operand &&
                c2.operator === crit.operator &&
                c2.value === crit.value
            )
          )
            return;
          params.filter.push(crit);
        });
      }
      return service
        .queryExecutionStates({
          filterViewPagination: params,
        })
        .pipe(
          map((resp) => {
            return {
              view: resp.view,
              total_entries: resp.total_entries,
              data: resp.list,
            };
          })
        );
    },
    view: {
      displayed_columns: [
        {
          display_name: 'process.filter.label.file_name',
          internal_name: 'file_name',
        },
        {
          display_name: 'process.filter.label.created_at',
          internal_name: 'created_at',
        },
      ],
      hidden_columns: [
        {
          display_name: 'id',
          internal_name: 'id',
        },
        {
          display_name: 'closed',
          internal_name: 'closed',
        },
      ],
      sort_by: {
        by: 'created_at',
        direction: 'desc',
      },
    },
    availableFilters: executionStateFilters,
    onRowClick: (element, column) => {
      router.navigate([
        route.snapshot.paramMap.get('realm'),
        'intern',
        's',
        element.id,
        'messages',
      ]);
    },
    actions: [
      {
        id: 'archive',
        icon: 'archive',
        hideIf: (element: ExecutionState) => {
          return element.closed;
        },
        name: 'common.button.archive',
        handler: async (element: ExecutionState, dataSource) => {
          if (
            await dialog.confirm({
              text: 'lawyer.dashboard.dossiers.confirmArchive',
            })
          ) {
            service
              .updateExecutionState({
                executionStateRequest: {
                  closed: true,
                },
                stateid: element.id,
              })
              .subscribe(() => {
                snackBar.open(
                  translator.instant('lawyer.dashboard.dossiers.archived')
                );
                dataSource.update();
              });
          }
        },
      },
      {
        id: 'unarchive',
        icon: 'unarchive',
        hideIf: (element: ExecutionState) => {
          return !element.closed;
        },
        name: 'common.button.unarchive',
        handler: async (element: ExecutionState, dataSource) => {
          service
            .updateExecutionState({
              executionStateRequest: {
                closed: false,
              },
              stateid: element.id,
            })
            .subscribe(() => {
              snackBar.open(
                translator.instant('lawyer.dashboard.dossiers.unarchived')
              );
              dataSource.update();
            });
        },
      },
      {
        id: 'delete',
        icon: 'delete',
        name: 'common.button.delete',
        handler: async (element, dataSource) => {
          if (
            await dialog.confirm({
              text: 'lawyer.dashboard.dossiers.confirmDelete',
            })
          ) {
            dataSource.setLoading(true);
            service
              .removeExecutionState({
                stateid: element.id,
              })
              .subscribe(
                () => {
                  dataSource.update();
                },
                () => {
                  dataSource.setLoading(false);
                  snackBar.open(
                    'Beim Löschen dieses Prozesses ist ein Problem aufgetreten',
                    '',
                    { duration: 3000 }
                  );
                }
              );
          }
        },
      },
    ],
  };
}
