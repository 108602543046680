import {
  AfterViewInit,
  Component,
  HostBinding,
  HostListener,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AuthService } from './auth/auth.service';
import { MetricsService } from './services/metrics.service';
import { CustomCIService } from './services/customCI.service';
import { TranslateService } from '@ngx-translate/core';
import {
  DomSanitizer,
  SafeResourceUrl,
  Title,
} from '@angular/platform-browser';
import { delay, distinctUntilChanged, filter, map, tap } from 'rxjs/operators';
import { SettingsService } from './common/settings.service';
import { PermissionsService } from './auth/permissions.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewInit {
  title = 'avg-frontend';
  auth: AuthService;

  languages = ['de', 'en', 'fr'];

  isEmbed = false;

  showFeedback = false;
  feedbackFormLink: SafeResourceUrl = undefined;

  constructor(
    private router: Router,
    auth: AuthService,
    permissions: PermissionsService,
    settings: SettingsService,
    private metricsService: MetricsService,
    private ci: CustomCIService,
    public translator: TranslateService,
    private titleService: Title,
    private activatedRoute: ActivatedRoute,
    private sanitzer: DomSanitizer
  ) {
    this.metricsService.startSession();
    this.auth = auth;
    if (
      window.location.pathname.includes('embed') ||
      window.location.search.includes('embed=true')
    ) {
      this.isEmbed = true;
    }

    this.auth.jwtToken$
      .pipe(
        map((t) => Boolean(t)),
        delay(50),
        distinctUntilChanged()
      )
      .subscribe((tk) => {
        if (tk) {
          settings.initializeUserSettings();
        } else {
          settings.resetSettings();
        }
      });

    this.handleRouteChanges();
  }

  get realm(): string {
    return this.activatedRoute?.snapshot?.firstChild?.paramMap?.get('realm');
  }

  ngAfterViewInit(): void {
    window.setTimeout(
      () =>
        document.querySelector('#main-splash-screen').classList.add('loaded'),
      1000
    );
  }

  toggleFeedback() {
    const mail = this.auth.userMail;
    const fullName = this.auth.userName;
    this.feedbackFormLink = this.sanitzer.bypassSecurityTrustResourceUrl(
      `https://docs.google.com/forms/d/e/1FAIpQLSdSrNt7iU--NVIMRlPafonkGhMFP-FVrOI1xaT_8If0liDHGw/viewform?usp=pp_url&entry.2124710130=${encodeURIComponent(
        mail
      )}&entry.1202623708=${encodeURIComponent(
        fullName
      )}&entry.644709821=Feedback+zum+Funktionsumfang+/+Featurewunsch`
    );
    this.showFeedback = true;
  }

  private handleRouteChanges() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          let child = this.activatedRoute.firstChild;
          while (child) {
            if (child.firstChild) {
              child = child.firstChild;
            } else if (child.snapshot.data && child.snapshot.data['title']) {
              return child.snapshot.data['title'];
            } else {
              return null;
            }
          }
          return null;
        })
      )
      .subscribe((data) => {
        if (data) {
          this.titleService.setTitle(
            `${this.translator.instant(data)} | Silberfluss`
          );
        }
      });
  }
}
