/**
 * Silberfluss API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.10
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TenantUser } from './tenantUser';


/**
 * A tenant with a name and a slug
 */
export interface Tenant { 
    default_user?: TenantUser;
    name: string;
    slug: string;
}

