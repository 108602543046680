<h1 mat-dialog-title>
  {{ "triggers.editTrigger" | translate }}
</h1>
<mat-dialog-content>
  <mat-form-field appearance="fill">
    <mat-select [formControl]="typeFormControl">
      <mat-option [value]="'variable.change'">
        {{ "triggers.variableChange.title" | translate }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <form [formGroup]="configForm">
    <mat-form-field appearance="fill">
      <mat-label>Scope</mat-label>
      <input matInput type="text" formControlName="scope" />
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Name Pattern</mat-label>
      <input matInput type="text" formControlName="name_pattern" />
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Value Pattern</mat-label>
      <input matInput type="text" formControlName="value_pattern" />
    </mat-form-field>
    <mat-checkbox formControlName="repeatable"> Repeatable </mat-checkbox>
    <mat-checkbox formControlName="capture_existing">
      Capture Existing
    </mat-checkbox>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button mat-dialog-close>
    {{ "common.button.abort" | translate }}
  </button>
  <button mat-raised-button (click)="save()" color="primary">
    {{ "common.button.accept" | translate }}
  </button>
</mat-dialog-actions>
