import {
  AbstractNode,
  HardCodedUser,
  ParsedAssignedUser,
  allNodes,
} from 'advoprocess';
import { Replacements } from 'advoprocess/lib/types/replacements';
import { AuthService } from '../auth/auth.service';
import { ActivatedRouteSnapshot } from '@angular/router';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import {
  BooleanOperator,
  FilesService,
  FilterCriterium,
  Template,
} from 'src/api';
import { MenuEntry } from 'advoprocess/lib/types/menu';
import { AvailableFilter } from 'advoprocess/lib/types/filter';

// Source: https://stackoverflow.com/questions/11867545/change-text-color-based-on-brightness-of-the-covered-background-area
export function getContrastYIQ(hexcolor): number {
  hexcolor = hexcolor.replace('#', '');
  const r = parseInt(hexcolor.substr(0, 2), 16);
  const g = parseInt(hexcolor.substr(2, 2), 16);
  const b = parseInt(hexcolor.substr(4, 2), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq;
}

export function applyTemplate(block: AbstractNode, auth: AuthService): void {
  const config = block.config;
  if (!config) return;
  Object.values(config).forEach((value) => {
    if (value.template) {
      switch (value.template) {
        case Replacements.auth.EMAIL:
          value.value = auth.userMail;
          break;
        default:
          break;
      }
    }
  });
}

export function getRecursiveRootPath(
  activeRoute: ActivatedRouteSnapshot
): string {
  const selfUrl = activeRoute.url.map((u) => u.path).join('/');
  return activeRoute.parent
    ? [getRecursiveRootPath(activeRoute.parent), selfUrl].join('/')
    : selfUrl;
}

export function isParseAssignedUser(
  ass: ParsedAssignedUser | HardCodedUser
): ass is ParsedAssignedUser {
  return Boolean((ass as any)?.uuid);
}

export function mergeAvailableFilters(...filters: AvailableFilter[][]) {
  const flat = _.flatMap(filters);
  const all: { [key: string]: number } = flat.reduce((p, c) => {
    if (typeof c.internal_name !== 'string') return p;
    if (!p[c.internal_name]) {
      p[c.internal_name] = 0;
    }
    p[c.internal_name]++;
    return p;
  }, {});
  return Object.entries(all)
    .filter(([_, count]) => count === filters.length)
    .map(([internalName, _]) => {
      return flat.find(
        (f) =>
          typeof f.internal_name === 'string' &&
          f.internal_name === internalName
      );
    })
    .filter((u) => !!u);
}

export function filterTerm(
  searchTerm: string,
  e: {
    name: string;
    filteredChildren?: MenuEntry<any>[];
    children?: MenuEntry<any>[];
  },
  translator: TranslateService
): boolean {
  if (!searchTerm.length) {
    return true;
  }
  let filteredChildren: MenuEntry<any>[] = [];
  if (e.children) {
    filteredChildren = e.children.filter((c) =>
      filterTerm(searchTerm, c, translator)
    );
  }
  const ownMatch =
    translator
      .instant(e.name)
      .toLowerCase()
      .includes(searchTerm.toLowerCase()) ||
    searchTerm.toLowerCase().includes(translator.instant(e.name).toLowerCase());
  if (filteredChildren.length) {
    e.filteredChildren = ownMatch ? e.children : filteredChildren;
    return true;
  }
  return ownMatch;
}

export function isFilterCriterium(
  f: FilterCriterium | BooleanOperator
): f is FilterCriterium {
  return !(f as any).filters;
}

export function isBooleanOperator(
  f: FilterCriterium | BooleanOperator | AvailableFilter
): f is BooleanOperator {
  return Boolean((f as any)?.filters);
}

export function buildErrorString(
  translator: TranslateService,
  reason: FilterCriterium | BooleanOperator | boolean,
  knownFilters?: AvailableFilter[]
) {
  if (typeof reason === 'boolean') {
    return translator.instant('common.error.explanations.forbidden');
  } else {
    if (isBooleanOperator(reason)) {
      return reason.filters
        .map((f) => buildErrorString(translator, f, knownFilters))
        .join(` ${translator.instant('common.label.' + reason.operator)} `);
    }
    const fromKnownFilter = knownFilters?.find(
      (f) => f.internal_name === reason.operand || f.id === reason.filterId
    )?.label;
    let operand: string = reason.label ?? reason.operand;
    if (fromKnownFilter) {
      if (!_.isFunction(fromKnownFilter)) {
        operand = translator.instant(fromKnownFilter);
      }
    }
    let condition: string;
    const value = reason.value;
    switch (reason.operator) {
      case 'ne':
      case 'eq':
        condition = translator.instant(
          'common.error.explanations.conditions.eq',
          { value }
        );
        break;
      case 'contains':
      case 'containsNot':
        condition = translator.instant(
          'common.error.explanations.conditions.contains',
          { value }
        );
        break;
      case 'includedIn':
      case 'notIncludedIn':
        condition = translator.instant(
          'common.error.explanations.conditions.includedIn',
          { value }
        );
        break;
      case 'startsWith':
        condition = translator.instant(
          'common.error.explanations.conditions.startsWith',
          { value }
        );
      case 'greaterThan':
        condition = translator.instant(
          'common.error.explanations.conditions.greaterThan',
          { value }
        );
      case 'lessThan':
        condition = translator.instant(
          'common.error.explanations.conditions.lessThan',
          { value }
        );
    }
    switch (reason.operator) {
      case 'ne':
      case 'containsNot':
      case 'notIncludedIn':
        return translator.instant('common.error.explanations.filterNot', {
          operand,
          condition,
        });
      default:
        return translator.instant('common.error.explanations.filter', {
          operand,
          condition,
        });
    }
  }
}

export function iconForTemplate(template: Template) {
  if (template.type === 'node' && !!template.nodeType) {
    const cls = allNodes()?.[template.nodeType];
    if (cls) {
      return new cls()?.icon ?? 'question_mark';
    } else {
      return 'question_mark';
    }
  }
  const lookup = {
    process: 'account_tree',
    text: 'text_fields',
    variable: 'code',
  };
  return lookup[template.type] ?? 'question_mark';
}

// Adapted from: https://stackoverflow.com/questions/44277435/css-zoom-property-not-working-with-boundingclientrectangle
export const getZoomLevel = (el: HTMLElement | Range): number[] => {
  const zooms = [];
  const getZoom = (el: HTMLElement | Range) => {
    const zoom = (el as HTMLElement)?.style
      ? window.getComputedStyle(el as HTMLElement).getPropertyValue('zoom')
      : '1';
    const rzoom = zoom ? parseFloat(zoom) : 1;
    if (rzoom !== 1) zooms.push(rzoom);
    if ((el as HTMLElement).parentElement?.parentElement)
      getZoom((el as HTMLElement).parentElement);
    if ((el as Range).startContainer?.parentElement) {
      getZoom((el as Range).startContainer as HTMLElement);
    }
  };
  getZoom(el);
  zooms.reverse();
  return zooms;
};

export const getRectWithZoom = (el: HTMLElement | Range): Partial<DOMRect> => {
  if (!el)
    return {
      x: 0,
      y: 0,
      width: 0,
      height: 0,
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    };
  let rect = el?.getBoundingClientRect();
  const zooms = getZoomLevel(el);
  const rectWithZoom = {
    bottom: zooms.reduce((a, b) => a * b, rect.bottom),
    height: zooms.reduce((a, b) => a * b, rect.height),
    left: zooms.reduce((a, b) => a * b, rect.left),
    right: zooms.reduce((a, b) => a * b, rect.right),
    top: zooms.reduce((a, b) => a * b, rect.top),
    width: zooms.reduce((a, b) => a * b, rect.width),
    x: zooms.reduce((a, b) => a * b, rect.x),
    y: zooms.reduce((a, b) => a * b, rect.y),
  };
  return rectWithZoom;
};

export function openWordFileUsingWebDAV(
  fileid: string,
  files: FilesService,
  filename?: string
): Promise<void> {
  return new Promise<void>((resolve) =>
    files
      .getFileWebdavLink({
        fileid,
        filename,
      })
      .subscribe((resp) => {
        if (resp.link) {
          const link = `ms-word:ofe|u|${resp.link}`;
          window.open(link, '_blank');
        }
        resolve();
      })
  );
}
