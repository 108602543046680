import { FileInfo } from 'src/api';

export function iconFor(file: FileInfo): {
  icon: string;
  color: string;
} {
  const mime = file.mime;

  if (!mime) return { icon: 'insert_drive_file', color: '#5a5a5a' };

  if (mime.match(/^image\/(.*)$/)) {
    return { icon: 'image', color: '#72b3dd' };
  }

  switch (mime) {
    case 'application/pdf':
      return { icon: 'picture_as_pdf', color: '#d0505f' };
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return { icon: '/assets/MS-Word-Logo.svg', color: '#5a5a5a' };
    default:
      return { icon: 'insert_drive_file', color: '#5a5a5a' };
  }
}

export function fileExtension(name: string): string {
  return name.match(/^(.*)\.(.*)$/m)[2];
}

export function fileWithoutExtension(name: string): string {
  return name.match(/^(.*)\.(.*)$/m)[1];
}
