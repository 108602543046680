import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { ChatMessage } from 'advoprocess';
import _ from 'lodash';

@Component({
  selector: 'app-text-input',
  template: `
    <div
      class="input-area"
      [class.longText]="inline && message?.title?.length > 100"
    >
      <div class="answer-title" *ngIf="inline && message?.title?.length > 100">
        <mat-icon>info</mat-icon>
        <p [innerHTML]="message.title | sanitizeHtml"></p>
      </div>
      <mat-form-field
        appearance="fill"
        *ngIf="!message.responseRequest?.params?.autocomplete"
        [class.required]="control.hasValidator(requiredValidator)"
      >
        <mat-label *ngIf="inline && message?.title?.length > 100">{{
          'common.label.answer' | translate
        }}</mat-label>
        <mat-label
          *ngIf="!inline || message?.title?.length <= 100"
          [innerHTML]="message.title | translate | sanitizeHtml"
        ></mat-label>
        <input
          [placeholder]="message.responseRequest.placeholder || null"
          [type]="mapQuestionType(message.responseRequest.type)"
          matInput
          [formControl]="control"
          *ngIf="
            control &&
            message.responseRequest.type !== 'number' &&
            !message.responseRequest?.params?.allowMultiline?.value
          "
          (keydown.enter)="
            value && !control.errors ? answered.emit(value) : null
          "
          #inputRef
        />
        <textarea
          [placeholder]="message.responseRequest.placeholder || null"
          matInput
          rows="5"
          wrap="hard"
          [formControl]="control"
          *ngIf="
            mapQuestionType(message.responseRequest.type) === 'text' &&
            message.responseRequest?.params?.allowMultiline?.value
          "
          #inputRef
        ></textarea>
        <input
          [placeholder]="message.responseRequest.placeholder || null"
          matInput
          type="number"
          [formControl]="control"
          *ngIf="control && message.responseRequest.type === 'number'"
          (keydown.enter)="
            value && !control.errors ? answered.emit(value) : null
          "
          #inputRef
        />
      </mat-form-field>
      <mat-form-field
        appearance="fill"
        *ngIf="message.responseRequest?.params?.autocomplete"
      >
        <mat-label
          *ngIf="!inline || message?.title?.length <= 100"
          [innerHTML]="message.title | sanitizeHtml"
        ></mat-label>
        <mat-label *ngIf="inline && message?.title?.length > 100">{{
          'common.label.answer' | translate
        }}</mat-label>
        <input
          matInput
          [formControl]="control"
          [matAutocomplete]="auto"
          #inputRef
        />
        <mat-autocomplete #auto="matAutocomplete">
          <mat-option
            *ngFor="
              let option of message.responseRequest?.params?.possibleValues
            "
            [value]="option"
          >
            {{ option }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <span
        class="error-message"
        *ngIf="control.errors && control.value?.length > 0"
        >{{ control.errors.message | translate }}</span
      >
    </div>
    <button
      mat-raised-button
      color="primary"
      *ngIf="
        !inline &&
        (!message.responseRequest?.params?.options ||
          message.responseRequest.params?.options?.length === 0)
      "
      (click)="answered.emit(value)"
      [disabled]="emptyValue || control.errors"
    >
      {{ 'common.button.continue' | translate }}
    </button>
  `,
})
export class TextInputComponent implements OnInit {
  @ViewChild('inputRef') inputRef: ElementRef<HTMLInputElement>;

  @Input() inline?: boolean;
  @Input() message: ChatMessage;
  @Input() control: UntypedFormControl;

  @Input() value: any;
  @Input() canBeEmpty: boolean;
  @Output() valueChanged = new EventEmitter<any>();

  @Output() answered = new EventEmitter<any>();
  @Output() changed = new EventEmitter<void>();

  @Output() hasError = new EventEmitter<boolean>();

  requiredValidator = Validators.required;

  get emptyValue(): boolean {
    return typeof this.value === 'number' ? _.isNil(this.value) : !this.value;
  }

  ngOnInit(): void {
    window.setTimeout(() => {
      if (this.inputRef?.nativeElement) {
        if (this.inline) {
          this.control.valueChanges.subscribe((value) => {
            if (
              this.message.responseRequest.type === 'number' &&
              typeof value === 'string'
            ) {
              value = parseFloat(value.replace(/[^\d,\.]/g, ''));
            }
            this.value = value;
            this.answered.emit(value);
            this.emitError();
          });
        } else {
          this.control.valueChanges.subscribe((value) => {
            if (
              this.message.responseRequest.type === 'number' &&
              typeof value === 'string'
            ) {
              value = parseFloat(value.replace(/[^\d,\.]/g, ''));
            }
            this.changed.emit(value);
          });
          this.inputRef.nativeElement.focus();
        }
      }
    }, 0);
    this.emitError();
  }

  private emitError() {
    this.hasError.emit(
      (!this.canBeEmpty && this.emptyValue) ||
        (this.value?.length && Boolean(this.control.errors))
    );
  }

  mapQuestionType(type: string): string {
    switch (type) {
      case 'number':
        return 'number';
      default:
        break;
    }
    return 'text';
  }
}
