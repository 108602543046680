import { Component, HostBinding, Input } from '@angular/core';
import { CustomCIService } from '../../services/customCI.service';
import { TranslateService } from '@ngx-translate/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  @Input() isEmbed: boolean;

  @Input()
  justOverlay: boolean = false;

  constructor(
    public translator: TranslateService,
    private ci: CustomCIService
  ) {}

  languages = ['de', 'en'];

  selectLanguage(language: string) {
    this.translator.use(language);
    localStorage.setItem(`preferred-lang`, language);
  }

  get customLogo(): SafeResourceUrl {
    return this.ci.customLogo;
  }

  get dseLink(): string {
    return (
      this.ci.customIdentity?.dse_link ??
      'https://silberfluss.io/datenschutzerklaerungsaas'
    );
  }

  get imprintLink(): string {
    return (
      this.ci.customIdentity?.imprint_link ?? 'https://silberfluss.io/impressum'
    );
  }

  get lawFirmName(): string {
    return this.ci.customIdentity?.law_firm ?? 'Silberfluss';
  }
}
