import {
  DiffRawThread,
  GeneratedDocument,
  RawThread,
  __pendingFileUploadBuffer,
  removePendingFile,
  uploadFile,
} from 'advoprocess';
import { AuthService } from 'src/app/auth/auth.service';
import { HistoryEntries, ProcessService, SaveState } from './process.service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import {
  ExecutionService,
  ExecutionState,
  FilesService,
  ThreadRequest,
} from 'src/api';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subject, of } from 'rxjs';
import dayjs from 'dayjs';
import { captureException } from '@sentry/angular-ivy';

interface SaveProgressDetail {
  title: string;
  progress: number;
}

export class ProcessSaveManager {
  bufferedSaves: RawThread[] = [];

  onSubmit = new Subject<ExecutionState>();

  public saveProgressPercent$ = new BehaviorSubject<'done' | number>('done');
  public saveProgressDetail$ = new BehaviorSubject<
    SaveProgressDetail | undefined
  >(undefined);

  constructor(
    private auth: AuthService,
    private service: ProcessService,
    private snackBar: MatSnackBar,
    private stateAPI: ExecutionService,
    private translator: TranslateService,
    private filesAPI: FilesService
  ) {}

  public save(thread: DiffRawThread): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (
        (!this.service?.executionState?.id && this.service.noUserInteraction) ||
        this.service.isPreview
      ) {
        resolve();
        return;
      }
      this.service.saveState = SaveState.NOT_SAVED;
      if (!this.auth.loggedIn) {
        if (this.service.stateId || !this.service.processDirectSource) {
          this.snackBar.open('Not in a valid state!');
          console.error(
            'StateID or no process direct source given when user is not logged in!'
          );
        } else {
          const currentRawThread = this.service.parser.getRawThread(
            this.service.parser.thread.methodKey,
            this.service.parser.thread.methodParams
          );
          this.bufferLocalChanges(currentRawThread);
        }
        reject();
        return;
      } else {
        if (!this.service.stateId) {
          if (this.service.processDirectSource) {
            this.addNewState(resolve, reject);
            return;
          } else {
            this.snackBar.open('Not in an active state!');
            console.error('StateID not given');
            reject();
            return;
          }
        }
      }
      this.service.saveState = SaveState.SAVING;
      this.stateAPI
        .updateThread({
          stateid: this.service.stateId,
          threadid: thread.id,
          threadRequest: thread,
        })
        .pipe(
          catchError((err) => {
            // this.snackBar.open(
            //   err.error.error ??
            //     this.translator.instant('common.label.unknownSaveError')
            // );
            reject(err);
            return of(null);
          })
        )
        .subscribe((val) => {
          if (!val) {
            return;
          }
          this.service.saveState = SaveState.SAVED;
          resolve();
        });
    });
  }

  private get bufferKey(): string {
    return `answer-buffer-${this.service.processDirectSource.raw.id}`;
  }

  private bufferLocalChanges(thread: RawThread) {
    const existingIndx = this.bufferedSaves.findIndex(
      (b) => b.id === thread.id
    );
    if (existingIndx >= 0) {
      this.bufferedSaves[existingIndx] = thread;
    } else {
      this.bufferedSaves.push(thread);
    }
    try {
      localStorage.setItem(
        this.bufferKey,
        JSON.stringify({
          threads: this.bufferedSaves,
          undoHistory: this.service.undoHistory,
          redoHistory: this.service.redoHistory,
        })
      );
    } catch (err) {
      console.warn(
        'Tried to store state in local storage. Failed with error',
        err
      );
    }
  }

  private addNewState(resolve: () => void, reject: () => void) {
    this.saveProgressPercent$.next(0);
    this.saveProgressDetail$.next(undefined);
    const threads: ThreadRequest[] = this.bufferedSaves.map((buf) => {
      const oldState = this.getInitialTempThread();
      return this.service.parser.buildDiffState(oldState, buf);
    });

    this.stateAPI
      .addNewState({
        executionStateRequest: {
          fromProcess: this.service.processDirectSource.raw.id,
        },
      })
      .pipe(
        tap(() => this.saveProgressPercent$.next(20)),
        switchMap((state) =>
          this.stateAPI
            .patchThreads({
              stateid: state.id,
              threadPatchRequest: {
                threads,
              },
            })
            .pipe(
              tap(() => this.saveProgressPercent$.next(50)),
              switchMap((resp) =>
                this.uploadDeferredFiles(state.id, resp.patched_threads)
              )
            )
            .pipe(map(() => state))
        )
      )
      .subscribe((state) => {
        this.bufferedSaves = [];
        this.service.saveState = SaveState.SAVED;
        this.saveProgressPercent$.next('done');
        this.saveProgressDetail$.next(undefined);
        try {
          localStorage.removeItem(this.bufferKey);
        } catch {}
        this.service.processDirectSource = undefined;
        this.onSubmit.next(state);
        resolve();
      });
  }

  async uploadDeferredFiles(stateid: string, threadIds: string[]) {
    // Upload deferred files
    const lookupThread = (bufferId: string): string => {
      const bufferIndex = this.bufferedSaves.findIndex(
        (t) => t.id === bufferId
      );
      return threadIds?.[bufferIndex] ?? undefined;
    };
    const percentPerFile = 50 / __pendingFileUploadBuffer.length;
    let done = 0;
    
    for (let i = 0; i < __pendingFileUploadBuffer.length; i++) {
      const file = __pendingFileUploadBuffer[i];
      if (!file) {
        done += percentPerFile;
        this.updateFileUploadProgress(done, 0, percentPerFile);
        continue;
      }
      await new Promise<void>((resolve) => {
        if (file.file instanceof File) {
          uploadFile(
            file.file as File,
            stateid,
            {
              path: file.path,
              nodeid: file.nodeId,
              threadid: lookupThread(file.thread),
              patchId: i.toString(),
              patchLink: file.url,
            },
            this.filesAPI,
            [],
            false,
            (progress: number) => {
              this.updateFileUploadProgress(
                done,
                progress,
                percentPerFile,
                file.file.name
              );
            }
          )
            .then(() => {
              this.service.parser.executionStatus.next(
                this.service.parser.prevExecStatus
              );
              removePendingFile(i);
              done += percentPerFile;
              this.updateFileUploadProgress(
                done,
                0,
                percentPerFile,
                file.file?.name
              );
              resolve();
            })
            .catch((error) => {
              captureException(error);
              this.service.parser.executionStatus.next(
                this.service.parser.prevExecStatus
              );
              removePendingFile(i);
              done += percentPerFile;
              this.updateFileUploadProgress(
                done,
                0,
                percentPerFile,
                file.file?.name
              );
              resolve();
            });
        } else {
          this.filesAPI
            .createDocument({
              documentCreateHandle: {
                content: { ...(file.file as GeneratedDocument) },
                name: file.file.name,
                stateid,
                threadid: lookupThread(file.thread),
                assigned: file.file.assigned,
                path: file.file.path,
                patch_id: i.toString(),
                patch_link: file.url,
              },
            })
            .subscribe({
              next: () => {
                this.service.parser.executionStatus.next(
                  this.service.parser.prevExecStatus
                );
                done += percentPerFile;
                this.updateFileUploadProgress(
                  done,
                  0,
                  percentPerFile,
                  file.file?.name
                );
                removePendingFile(i);
                resolve();
              },
              error: (error) => {
                captureException(error);
                this.service.parser.executionStatus.next(
                  this.service.parser.prevExecStatus
                );
                removePendingFile(i);
                done += percentPerFile;
                this.updateFileUploadProgress(
                  done,
                  0,
                  percentPerFile,
                  file.file?.name
                );
                resolve();
              },
            });
        }
      });
    }
  }

  private updateFileUploadProgress(
    total: number,
    partial: number,
    percentPerFile: number,
    fileName?: string
  ) {
    this.saveProgressDetail$.next({
      progress: partial * 100,
      title:
        this.translator.instant('common.message.uploading') +
        ' ' +
        (fileName ?? '') +
        `(${Math.round(partial * 100)}%)`,
    });
    this.saveProgressPercent$.next(50 + total + partial * percentPerFile);
  }

  public clearSavedState() {
    localStorage.removeItem(this.bufferKey);
  }

  private getInitialTempThread(): RawThread {
    return {
      chat: [],
      dataStore: {},
      name: this?.service?.processDirectSource?.info?.name ?? '',
      nodeTemplate: this.service.processDirectSource.raw?.data as any,
      createdAt: dayjs().toISOString(),
      meta: {},
      widgets: [],
      status: 'OPEN',
      currentNode: undefined,
      id: `BUFFER0`,
    };
  }

  public createTempThread() {
    let existingBuffer:
      | {
          threads: RawThread[];
          undoHistory: { [threadid: string]: HistoryEntries };
          redoHistory: { [threadid: string]: HistoryEntries };
        }
      | undefined = undefined;
    try {
      existingBuffer = JSON.parse(
        localStorage.getItem(this.bufferKey) ?? 'null'
      );
    } catch (err) {
      console.warn(
        'Could not read previous thread data from local store.',
        err
      );
    }
    let threads: RawThread[] = [];
    if (existingBuffer?.threads && !this.service.isPreview) {
      threads = existingBuffer.threads;

      for (const thread of threads) {
        if (JSON.stringify(thread).includes('<<<PENDING')) {
          if (existingBuffer?.undoHistory?.[thread.id]) {
            // ToDo: Make clean diff until the point where no file is uploaded
            threads = [this.getInitialTempThread()];
          } else {
            threads = [this.getInitialTempThread()];
          }
        }
      }
    } else {
      // No previous state, begin from the first node
      threads.push(this.getInitialTempThread());
    }
    this.bufferedSaves = threads;
    // ToDo: Create scopes and threads here
    for (const save of threads) {
      this.service.scopesAndThreads = {
        scopes: [
          {
            id: 'TEMPSCOPE0',
            createdAt: dayjs().toISOString(),
          },
        ],
        threads: [
          {
            ...save,
            path: 'TEMPSCOPE0',
            assigned: ['<<[MAGIC:CLIENT]::{"permission": "answer"}>>'],
          },
        ],
      };
    }
    this.service.startProcess(threads[0]);
    if (
      existingBuffer?.threads &&
      !this.service.isPreview &&
      existingBuffer?.undoHistory
    ) {
      this.service.undoHistory = existingBuffer.undoHistory;
      this.service.redoHistory = existingBuffer.redoHistory;
    }
  }
}
